import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.864}
      height={24.239}
      viewBox="0 0 22.864 24.239"
      {...props}
    >
      <g data-name="Grupo 22144">
        <path data-name="Caminho 388" d="M0 0h22.864v22.864H0z" fill="none" />
        <path
          data-name="Caminho 389"
          d="M13.338 1.905H5.716a1.9 1.9 0 00-1.9 1.905l-.005 15.243a1.9 1.9 0 001.9 1.905h11.437a1.911 1.911 0 001.905-1.905V7.621zm1.905 15.243H7.622v-1.906h7.621zm0-3.811H7.622v-1.905h7.621zm-2.858-4.763v-5.24l5.24 5.24z"
          fill="#757575"
        />
        <g data-name="Grupo 119" transform="translate(-197.029 -990.652)">
          <circle
            data-name="Elipse 44"
            cx={2.914}
            cy={2.914}
            r={2.914}
            transform="translate(211.166 1006.114)"
            fill="#fff"
          />
          <path
            data-name="Fill 10"
            d="M218.375 1014.742a.211.211 0 01-.142-.055l-2.363-2.364a3.692 3.692 0 01-2.056.615 3.79 3.79 0 01-1.462-.28 3.814 3.814 0 01-1.233-.839 3.815 3.815 0 01-.839-1.233 3.791 3.791 0 01-.28-1.462 3.79 3.79 0 01.28-1.461 3.817 3.817 0 01.839-1.233 3.814 3.814 0 011.233-.839 3.791 3.791 0 011.461-.28 3.791 3.791 0 011.462.28 3.818 3.818 0 011.233.839 3.819 3.819 0 01.839 1.233 3.79 3.79 0 01.28 1.461 3.691 3.691 0 01-.612 2.053h0l2.361 2.367a.211.211 0 010 .284l-.859.859a.21.21 0 01-.142.055zm-4.562-8.218a2.523 2.523 0 00-1.844.764 2.514 2.514 0 00-.764 1.836 2.515 2.515 0 00.765 1.836 2.523 2.523 0 001.844.764 2.517 2.517 0 001.84-.76 2.518 2.518 0 00.76-1.84 2.517 2.517 0 00-.76-1.84 2.518 2.518 0 00-1.841-.76z"
            fill="#757575"
            stroke="#757575"
            strokeMiterlimit={10}
            strokeWidth={0.3}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
