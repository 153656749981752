import api from '../../../services/api'
import { checkStatusCode } from '../../../utils/checkStatusCode'

const sendNotificationsFn = async (id: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/file/sendNotifications/${id}`)
      .then((res) => resolve(res.data))
      .catch((error) => reject(checkStatusCode(error)))
  })
}

export { sendNotificationsFn }
