import api from '../../../services/api'

const deleteDoc = async (id: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/file/delete/${id}`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => reject(err))
  })
}

export default deleteDoc
