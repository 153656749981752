import React, { useMemo } from 'react'
import fisrtAndLastName from '../../utils/firstAndLastName'
import Avatar from '../Avatar'
import { ListItem } from './StyledComponents'
import CheckBox from '../StyledCheckBox'

// import { Container } from './styles';

interface IProps {
  item: any
  handleClick: any
  array: any[]
}

const CheckUser: React.FC<IProps> = ({ item, handleClick, array }) => {
  const userArr = useMemo(() => {
    return array && array.length > 0 ? array.map((i) => i.name) : []
  }, [array])

  const check = useMemo(() => {
    const i = userArr.indexOf(item.name)
    return i > -1
  }, [userArr, item.name])

  return (
    <ListItem tabIndex={0} role="button" onClick={handleClick}>
      <CheckBox checked={check} handleClick={handleClick} />
      <Avatar data={{ name: item.name, avatar: item.avatar }} />
      <span>{fisrtAndLastName(item.name)}</span>
    </ListItem>
  )
}

export default CheckUser
