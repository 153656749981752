const orderArray = (key: any, data: object[]): any[] =>
  data.sort((a: any, b: any) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })

export default orderArray
