import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Login from '../pages/Login'
import Redirect from '../components/Redirect'

const SignRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Login} />
      <Route path="*" component={Redirect} />
    </BrowserRouter>
  )
}

export default SignRoutes
