import {
  MdTrendingUp,
  MdFolder,
  MdAssessment,
  MdPerson,
  // MdMoreHoriz,
  MdMonetizationOn,
  MdFeedback
} from 'react-icons/md'

import Doc from '../../../../assets/icons/Doc'
// import DocActive from '../../assets/icons/DocActive'
import DocDark from '../../../../assets/icons/DocDark'
// import DocDarkActive from '../../assets/icons/DocDarkActive'
// import Apartment from '../../../../assets/icons/Apartment'

type MenuProps = {
  darkmode: boolean
}
const menuBranding = ({ darkmode }: MenuProps): any[] => {
  // const MoreIcon = (): any => {
  //   return (
  //     <MoreContainer>
  //       <MdMoreHoriz size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
  //     </MoreContainer>
  //   )
  // }

  const DocIcon = (): any => {
    let Icon = darkmode ? DocDark : Doc
    return (
      <div style={{ padding: '1px' }}>
        <Icon />
      </div>
    )
  }

  //   const ApIcon = (): any => {
  //     return (
  //       <div style={{ padding: '3px' }}>
  //         <Apartment />
  //       </div>
  //     )
  //   }

  return [
    {
      label: 'Painel',
      Icon: () => (
        <MdTrendingUp size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
      ),
      path: '/',
      id: 'panel'
    },
    {
      label: 'Gamefication',
      Icon: () => (
        <MdMonetizationOn size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
      ),
      path: '/',
      id: 'gamefication'
    },
    {
      label: 'Colaboradores',
      Icon: () => <MdPerson size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/',
      id: 'colabs'
    },
    {
      label: 'Feedbacks',
      Icon: () => (
        <MdFeedback size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
      ),
      path: '/',
      id: 'feedbacks'
    },
    {
      label: 'Relatórios',
      Icon: () => (
        <MdAssessment size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
      ),
      path: '/',
      id: 'reports'
    },
    {
      label: 'Arquivos',
      Icon: () => <MdFolder size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/',
      id: 'files'
    },
    { label: 'Formulários', Icon: () => <DocIcon />, path: '/', id: 'forms' }
  ]
}

export { menuBranding }
