const handleClear = (
  setHook: any,
  array: any[],
  filters: any[],
  setFilters: any
) => {
  let arr = [...filters]
  for (let i = 0; i <= array.length; i += 1) {
    arr = arr.filter((item) => {
      return item !== array[i]
    })
    setFilters(arr)
  }
  setHook([])
}

export { handleClear }
