import React from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import AboutCopany from '../pages/AboutCompany'
import Colabs from '../pages/Colabs'
import BalanceUser from '../pages/BalanceUsers'
import Files from '../pages/Files'
import Docs from '../pages/Docs'
import Requests from '../pages/Requests'
import Reports from '../pages/Reports'
import Header from '../components/Header'

const OtherRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Header darkmode={false} />
      <>
        <Route path="/about" component={AboutCopany} />
        <Route path="/" exact component={BalanceUser} />
        <Route path="/colabs" component={Colabs} />
        <Route path="/files" component={Files} />
        <Route path="/forms" component={Docs} />
        <Route path="/requests" component={Requests} />
        <Route path="/reports" component={Reports} />
      </>
    </BrowserRouter>
  )
}
export default OtherRoutes
