import React from 'react'
import { Avatar } from 'antd'
import { returnAvatar } from '../../services/getAvatarApi'
import { AvatarProps } from '../../interfaces/avatar_interface'
import { getInCache } from './functions/getInCache'
import { saveInCache } from './functions/saveInCache'

const AvatarFC: React.FC<AvatarProps> = ({
  data,
  styles = { w: 32, h: 32 },
  background = 'f6f6f6',
  color = '00b7ef'
}) => {
  const [url, setUrl] = React.useState('')
  const height = styles.h
  const width = styles.w

  React.useEffect(() => {
    getInCache(data)
      .then((cache) => {
        setUrl(cache.url)
      })
      .catch((
        _ // @ts-ignore
      ) =>
        returnAvatar(data, background, color).then((res) => {
          saveInCache(res, data)
          setUrl(res)
        })
      )
    return () => {
      setUrl('') // This worked for me
    }
  }, [data, background, color])

  return (
    <>
      <Avatar src={url} style={{ width, height }} className="avatar-item" />
    </>
  )
}

export default AvatarFC
