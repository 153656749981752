import React from 'react'

import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import Template from '../../components/Template'

import About from './About'

import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

const AboutCompany: React.FC = () => {
  return (
    <Template active="about">
      <Container>
        <PageTitle>Documentos</PageTitle>
        <Section>
          <Tabs>
            <Tab title="Empresa">
              <About />
            </Tab>
            <></>
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default AboutCompany
