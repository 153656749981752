import React, { useState } from 'react'
import Picture from '../../../../../../assets/icons/Picture'
import getFile from './utils/download'
import { Infos, ItemInfos, Buttons } from './StyledComponents'
import Download from '../../../../../../assets/icons/Download'

interface IProps {
  item: any
  label: string
}

const DocList: React.FC<IProps> = ({ item, label }) => {
  const [progress, setProgress] = useState(0)

  const downloadItem = async () => {
    await getFile(item, setProgress)
    console.log(progress)
  }

  return (
    <li>
      <ItemInfos>
        <Picture />
        <Infos>
          <span>{label}</span>
          <span>{item}</span>
        </Infos>
      </ItemInfos>

      <Buttons>
        <button type="button" onClick={downloadItem}>
          <Download />
        </button>
      </Buttons>
    </li>
  )
}

export default DocList
