import React, { useEffect, useState } from 'react'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

import { IconContainer } from './StyledComponents'

interface IProps {
  clickTop: any
  clickBottom: any
  value: string | undefined
  sortBy: string | undefined
}

const FilterHeader: React.FC<IProps> = ({
  clickBottom,
  clickTop,
  value,
  sortBy
}) => {
  const [topActive, setTopActive] = useState(false)
  const [botActive, setBotActive] = useState(false)

  const disable = () => {
    setTopActive(false)
    setBotActive(false)
  }

  useEffect(() => {
    if (sortBy !== value) {
      disable()
    }
  }, [sortBy, value])

  const handleClickTop = () => {
    topActive ? setTopActive(false) : setTopActive(true)
    setBotActive(false)
    clickTop()
  }

  const handleClickBot = () => {
    botActive ? setBotActive(false) : setBotActive(true)
    setTopActive(false)
    clickBottom()
  }

  return (
    <IconContainer>
      <button onClick={handleClickTop}>
        <MdArrowDropUp size={20} className={topActive ? 'clicked' : ''} />
      </button>
      <button onClick={handleClickBot}>
        <MdArrowDropDown size={20} className={botActive ? 'clicked' : ''} />
      </button>
    </IconContainer>
  )
}

export default FilterHeader
