import React, { useEffect, useState } from 'react'
import { Label, Container, SelectContainer, Options } from './StyledComponents'
import { MdAdd } from 'react-icons/md'
import { IProps } from './types'
import { ClickAwayListener } from '@material-ui/core'
import { dates } from '../../pages/BalanceUsers/utils/getDates'

const SelectComponent: React.FC<IProps> = ({
  label,
  options,
  width = '100%',
  cointainButton = false,
  buttonLabel = '',
  buttonClick,
  disabled = false,
  value,
  setChange,
  defaultValue
}) => {
  const [open, setOpen] = useState(false)

  const [labelRender, setLabelRender] = useState('')

  useEffect(() => {
    if (disabled) {
      if (typeof value === 'string') {
        setChange('')
      }
    }
  }, [disabled, value, setChange])

  useEffect(() => {
    const returnDefaultValue = () => {
      if (defaultValue !== undefined) {
        const item = dates.filter((i) => i.value === defaultValue)
        setLabelRender(item[0].label)
      } else {
        setLabelRender(value)
      }
    }
    returnDefaultValue()
  }, [defaultValue, value])

  const handleChange = (value: any, label?: string) => {
    setChange(value)
    label ? setLabelRender(label) : setLabelRender(value)
    setOpen(false)
  }

  const handleClickButton = () => {
    buttonClick()
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Label>
        {label}
        <Container style={{ width }}>
          <SelectContainer
            onClick={disabled ? () => {} : handleOpen}
            className={open ? 'open' : `${disabled ? 'disabled' : ''}`}
          >
            {labelRender ? labelRender : 'Selecione'}
          </SelectContainer>
          {open ? (
            <Options>
              {options.map((i) => (
                <li
                  onClick={() =>
                    handleChange(i.value || i.label || i.name, i.label)
                  }
                  className="options"
                >
                  {i.label || i.value || i.name}
                </li>
              ))}
              {cointainButton ? (
                <li onClick={handleClickButton} className="li-button">
                  <MdAdd size={21} />
                  {buttonLabel}
                </li>
              ) : null}
            </Options>
          ) : null}
        </Container>
      </Label>
    </ClickAwayListener>
  )
}

export default SelectComponent
