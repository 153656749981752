import React, { useEffect, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import Input from '../../../../components/Input'
import InputFile from '../../../../components/InputFile'
import Button from '../../../../components/Button'

import {
  ContainerDrawer,
  Title,
  BtnGroup
} from '../../../../GlobalStyledComponents'

import {
  AddFileContainer,
  ProgressDownload,
  ProgressTop,
  ProgressBar,
  Progress,
  ItemContainer,
  OptionsSection
} from '../../StyledComponents'
import formatBytes from '../../../../utils/formatBytes'
import { upload } from '../../helpers/upload'
import RadioInputs from '../../../../components/RadioInputs'
import BoxChecks from '../../../../components/BoxChecks'
import Select from '../../../../components/Select'
import tags from '../../constants/tags'
import useDisabled from '../../../../hooks/useDisabled'
import setData from '../../utils/setData'
import uploadFile from '../../controller/uploadDoc'
import { IProps } from './types'
import { toast } from 'react-toastify'
import useApi from '../../../../hooks/useApi'

const styles = {
  div: {
    display: 'flex',
    width: '45%'
  }
}

const AddFile: React.FC<IProps> = ({ actionClose, setChange }) => {
  const [progress, setProgress] = useState(0)
  const [completed, setCompleted] = useState(0)
  const [size, setSize] = useState(0)
  const [title, setTitle] = useState('')
  const [file, setFile] = useState({})
  const [notifyUsers, setNotifyUsers] = useState('')
  const [reqReadConfirmation, setReqReadConfirmation] = useState('')
  const [tag, setTag] = useState([])
  const [loading, setLoading] = useState(false)
  const [typeFile, setTypeFile] = useState('Público')
  const [folder, setFolder] = useState('')

  const resetStates = () => {
    setProgress(0)
    setCompleted(0)
    setSize(0)
    setTitle('')
    setFile({})
    setNotifyUsers('')
    setReqReadConfirmation('')
    setTag([])
    setLoading(false)
    setTypeFile('')
    setFolder('')
  }

  useEffect(() => {
    return () => {
      resetStates()
    }
  }, [])

  const [result] = useApi(
    `/folder?filter=&fields=name&page=1&limit=5&sort=name&order=1`,
    'get'
  )

  const [disabled] = useDisabled([title, notifyUsers, reqReadConfirmation])

  const handleOnUpload = async (files: any) => {
    const data = await upload(files, setProgress, setSize, setCompleted)
    setFile(data)
  }

  const handleSend = async () => {
    setLoading(true)
    const form = setData({
      size,
      tag,
      title,
      folder,
      requiredViews: reqReadConfirmation,
      notifyUsers,
      public: typeFile === 'Público',
      ...file
    })
    await uploadFile(form).then((res) => {
      setLoading(false)
      actionClose()
      resetStates()
      toast.success('Arquivo enviado com sucesso.')
      if (setChange) {
        setChange(true)
      }
    })
  }

  return (
    <ContainerDrawer>
      <Title>Adicionar arquivo</Title>
      <AddFileContainer>
        <InputFile
          label="Fazer upload de arquivo"
          onChangeCapture={(e) => handleOnUpload(e.target)}
        />
        <ProgressDownload>
          {completed > 0 && (
            <>
              <ProgressTop>{`Fazendo upload:${formatBytes(
                completed
              )} de ${formatBytes(size)}`}</ProgressTop>
              <ProgressBar>
                <Progress width={`${progress}%`} />
              </ProgressBar>
            </>
          )}
        </ProgressDownload>
        <ItemContainer>
          <Input
            label="Nome do arquivo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div style={styles.div}>
            {/* <RadioInputs
              label="Tipo de arquivo"
              options={['Público', 'Privado']}
              display="column"
              value={typeFile}
              setValue={setTypeFile}
            /> */}
            {typeFile === 'Privado' && (
              <Select
                style={{ width: '190px' }}
                options={result}
                label="Inserir na pasta"
                value={folder}
                setChange={setFolder}
              />
            )}
          </div>
          {typeFile === 'Público' && (
            <BoxChecks
              label="Etiquetas"
              options={tags}
              array={tag}
              setHook={setTag}
            />
          )}
        </ItemContainer>
        <OptionsSection>
          <RadioInputs
            label="Notificar usuários"
            options={['sim', 'não']}
            display="row"
            value={notifyUsers}
            setValue={setNotifyUsers}
          />
          <RadioInputs
            label="Habilitar confirmação de leitura"
            options={['sim', 'não']}
            display="row"
            value={reqReadConfirmation}
            setValue={setReqReadConfirmation}
          />
        </OptionsSection>
      </AddFileContainer>
      <BtnGroup>
        <Button className="secondary" label="Cancelar" onClick={actionClose} />
        <Button
          className="primary"
          label="Adicionar"
          onClick={handleSend}
          iconEnd={<MdChevronRight size={21} />}
          disabled={disabled}
          loading={loading}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default AddFile
