import axios from 'axios'

const MAX_REQUESTS_COUNT = 10
const INTERVAL_MS = 3000
let PENDING_REQUESTS = 0

const api_url = process.env.REACT_APP_API_URL

const api_max = axios.create({
  baseURL: api_url,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
})

/**
 * Axios Request Interceptor
 */
api_max.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
api_max.interceptors.response.use(
  (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  }
)

export default api_max
