import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 10px 0;
`

export const GridDouble = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: auto;
  row-gap: 1em;
  column-gap: 15px;
  margin-bottom: 1em;
`

export const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #bfbfbf;
  width: 100%;
`
