import React, { useMemo, useState } from 'react'
import Input from '../../components/Input'
import Select from '../../components/Select'
import SearchUser from '../../components/SearchUser'
import Button from '../../components/Button'
import BoxChecks from '../../components/BoxChecks'
import {
  Container,
  ButtonContainer,
  SectionTop,
  SectionBottom
} from './StyledComponents'

import { optionsList, timeList, InfosList, formats } from './contants/lists'
import searchUser from '../../helpers/searchUser'
import UsersSelected from '../../components/UsersSelected'
import create from '../../helpers/create'
import setData from './utils/setDate'
import useDisabled from '../../hooks/useDisabled'

const Reports: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [period, setPeriod] = useState('')
  const [users, setUsers] = useState([])
  const [result, setResult] = useState([])
  const [format, setFormat] = useState('pdf')
  const [infos, setInfos] = useState<string[]>([])
  const [now, setNow] = useState('')
  const [past, setPast] = useState('')

  const requiredFields = useMemo(() => {
    const requiredFields =
      type === 'Colaboradores'
        ? [type, format]
        : [type, format, period, now, past]
    return requiredFields
  }, [type, format, period, now, past])

  const [disabled] = useDisabled(requiredFields)

  const optionsInput = {
    width: '190px',
    disabled: period !== 'Personalizar',
    mask: '99/99/9999'
  }

  const handleSearch = async (value: string) => {
    setName(value)
    await searchUser(value, setResult)
  }

  const handleSubmit = async () => {
    const data = {
      type,
      format,
      infos,
      users
    }
    await create(
      data,
      '/reports',
      'Relatório gerado com sucesso! Pode ser acessado no histórico.',
      setLoading
    )
  }

  const handleChangePeriod = (value: string) => {
    setPeriod(value)
    setNow('')
    setPast('')
    if (value !== 'Personalizar') {
      setData(value, setPast, setNow)
    }
  }

  return (
    <>
      <Container>
        <SectionTop>
          <Select
            label="Tipo de relatorio"
            options={optionsList}
            value={type}
            setChange={setType}
          />
          {type !== '' && (
            <>
              {type !== 'Colaboradores' ? (
                <>
                  <Select
                    label="Período"
                    options={timeList}
                    value={period}
                    setChange={handleChangePeriod}
                  />
                  <Input
                    label="De"
                    className="max-width"
                    {...optionsInput}
                    value={past}
                    onChange={(e) => setPast(e.target.value)}
                  />
                  <Input
                    label="Até"
                    className="max-width"
                    {...optionsInput}
                    value={now}
                    onChange={(e) => setNow(e.target.value)}
                  />
                </>
              ) : (
                <BoxChecks
                  label="Informações que deseja incluir no relatório"
                  options={InfosList}
                  array={infos}
                  setHook={setInfos}
                  collumns="four"
                />
              )}
            </>
          )}
        </SectionTop>

        <SectionBottom>
          {type !== '' && (
            <>
              <SearchUser
                data={result}
                arraySelected={users}
                setData={setUsers}
                value={name}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Select
                label="Formato"
                options={formats}
                value={format}
                setChange={setFormat}
              />
            </>
          )}

          {users.length > 0 && (
            <UsersSelected users={users} setState={setUsers} />
          )}
        </SectionBottom>
      </Container>
      <ButtonContainer>
        <Button
          className="primary"
          label="Gerar relatório"
          onClick={handleSubmit}
          loading={loading}
          disabled={disabled}
        />
      </ButtonContainer>
    </>
  )
}

export default Reports
