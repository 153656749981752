import styled from 'styled-components'

export const Menu = styled.ul`
  margin-top: 2rem;
`

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0px 30px;
  height: 45px;

  &&.minimized {
    justify-content: center;
  }

  a {
    align-items: center;
    color: ${(props) => props.theme.basicText};
    display: flex;
    &&.active {
      color: ${(props) => props.theme.primary_blue};
    }
  }

  &.active {
    background: #f5f7f9;
  }
`

export const ButtonLink = styled.button``
export const TextBtn = styled.span`
  margin-left: 1rem;

  &.animated {
    animation-name: FEEDBACKANIMATION;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  width: 150px;

  &.active {
    color: ${(props) => props.theme.primary_blue};
  }

  &.minimized {
    display: none;
  }

  @keyframes FEEDBACKANIMATION {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      display: block;
    }
  }
`

export const MoreContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImgContainer = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
`

export const AnimatedBar = styled.div`
  width: 8px;
  height: 44px;
  background: ${(props) => props.theme.primary_blue};
  position: absolute;
  left: 0;
  display: none;

  &&.active {
    display: block;
  }
`
