import { toast } from 'react-toastify'
import { checkStatusCode } from '../utils/checkStatusCode'
import api from '../services/api'

const deleteRegister = async (id: string, url: string, message: string) => {
  await api
    .get(`${url}/delete/${id}`)
    .then((res) => {
      toast.success(message)
    })
    .catch((err) => {
      checkStatusCode(err)
    })
}

export default deleteRegister
