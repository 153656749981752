import api from '../../../services/api'
import { checkStatusCode } from '../../../utils/checkStatusCode'
// @ts-ignore
let time = null
const searchHelper = async (value: string, setResult: any) => {
  if (value.length > 0 && value !== '') {
    // @ts-ignore
    clearTimeout(time)
    time = setTimeout(async () => {
      await api
        .get(`/userTerms?search=${value}`)
        .then((res) => {
          setResult(res.data.docs)
        })
        .catch((reject) => checkStatusCode(reject))
    }, 1000)
  } else {
    setResult([])
  }
}

export default searchHelper
