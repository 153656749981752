import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import usePercent from '../../../../hooks/usePercent'
import { Bar, Progress } from './StyledComponents'
import useMath from '../../../../hooks/useMath'

type RequiredView = {
  confirmedView: boolean
}

interface IProps {
  data: RequiredView[]
}

const ProgressBar: React.FC<IProps> = ({ data }) => {
  const loaded = useMemo(() => {
    return data.filter((i) => i.confirmedView)
  }, [data])

  const newTotal = useMemo(() => {
    return data.length
  }, [data])

  const newLoaded = useMemo(() => {
    return loaded.length
  }, [loaded])

  const [percent] = usePercent(newLoaded, newTotal)
  const [math] = useMath([newTotal, newLoaded], 'subtraction')

  return (
    <Tooltip placement="top" title={`${newLoaded} lidos / ${math} não lidos`}>
      <Progress>
        <Bar width={`${percent.toString()}%`} />
      </Progress>
    </Tooltip>
  )
}

export default ProgressBar
