import moment from 'moment'

const setDays = (days: number) => {
  const now = moment().format('L')
  const past = moment().subtract(days, 'days').calendar()
  return {
    now,
    past
  }
}

export default setDays
