import React, { useCallback } from 'react'

import { TabLi, Tabbutton } from '../StyledComponents'

type Props = {
  title: string
  index: number
  setSelectedTab: (index: number) => void
  selectedTab: number
}

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  selectedTab
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <TabLi className={selectedTab === index ? 'active' : ''}>
      <Tabbutton
        className={selectedTab === index ? 'active' : ''}
        onClick={onClick}
      >
        {title}
      </Tabbutton>
    </TabLi>
  )
}

export default TabTitle
