import React from 'react'
import SearchInput from '../../components/SearchInput'
import { TopRigthSearch } from '../../GlobalStyledComponents'

import { Section } from './StyledComponents'

import List from './components/List'

import {
  filterCorrections,
  reasonsFilter,
  vocationsFilter
} from './constants/filters'

const Requests: React.FC = () => {
  const mock = [{ name: 'Paulo Oliveira', createdAt: new Date() }]
  const mock3 = [
    { name: 'Paulo Oliveira', createdAt: new Date(), type: 'Férias' },
    { name: 'Paulo Oliveira', createdAt: new Date(), type: 'Folga' }
  ]

  const mock4 = [
    { name: 'Paulo Oliveira', createdAt: new Date(), type: 'Falta' },
    { name: 'Paulo Oliveira', createdAt: new Date(), type: 'Abono' }
  ]

  return (
    <>
      <TopRigthSearch>
        <SearchInput placeholder="Pesquisar" />
      </TopRigthSearch>
      <Section>
        <List title="Ajuste de ponto" titles={filterCorrections} data={mock} />
        <div>
          <List
            title="Férias/folga"
            titles={vocationsFilter}
            data={mock3}
            className="trio"
          />
          <List
            title="Faltas justificadas/abonos"
            titles={reasonsFilter}
            data={mock4}
            className="trio"
          />
        </div>
      </Section>
    </>
  )
}

export default Requests
