const tags = [
  { value: 'geral', label: 'Geral' },
  { value: 'administrativa', label: 'Administrativo' },
  { value: 'android', label: 'Android' },
  { value: 'backend', label: 'Back-end' },
  { value: 'bemestar', label: 'Bem-estar' },
  { value: 'branding', label: 'Branding' },
  { value: 'design', label: 'Design' },
  { value: 'flutter', label: 'Flutter' },
  { value: 'frontend', label: 'Front-end' },
  { value: 'fulstack', label: 'FulStack' },
  { value: 'ios', label: 'iOS' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'projetos', label: 'Ptojetos' },
  { value: 'qualidado', label: 'Qualidade' },
  { value: 'rh', label: 'R.H' }
]

export default tags
