import React, { memo, useState } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import Context from '../../context/Global'
import OptionsSide from '../OptionsSide'

import { Darkmode } from '../../interfaces/main_interfaces'

import { Container, MinimizeBtn } from './styles'

interface SideProps extends Darkmode {
  active: string
}

const SideMenu: React.FC<SideProps> = memo(({ darkmode, active }) => {
  const { state, setState: setGlobalState } = React.useContext(Context)
  const { sideMinimized: minimized } = state
  const [animated, setAnimated] = useState(false)

  const handleMinimized = () => {
    const mini = minimized ? false : true
    setAnimated(true)
    setGlobalState((prevState) => ({ ...prevState, sideMinimized: mini }))
    setTimeout(() => {
      setAnimated(false)
    }, 2000)
  }

  return (
    <Container className={minimized ? 'minimized' : ''}>
      <OptionsSide
        darkmode={darkmode}
        minimized={minimized}
        active={active}
        animated={animated}
      />
      <div>
        <MinimizeBtn
          onClick={handleMinimized}
          className={minimized ? 'minimized' : ''}
        >
          {minimized ? (
            <MdChevronRight
              size={30}
              color={darkmode ? '#bfbfbf' : '#383838'}
            />
          ) : (
            <MdChevronLeft size={30} color={darkmode ? '#bfbfbf' : '#383838'} />
          )}
        </MinimizeBtn>
      </div>
    </Container>
  )
})

export default SideMenu
