import React from 'react'
import { Layout } from 'antd'
import SideMenu from '../../components/SideMenu'

import { Container, ContentSection } from './StyledComponents'

const Main: React.FC<{ darkmode?: boolean; active: string }> = ({
  darkmode = false,
  active,
  children
}) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Container>
        <SideMenu darkmode={darkmode} active={active} />
        <ContentSection>{children}</ContentSection>
      </Container>
    </Layout>
  )
}

export default Main
