import styled from 'styled-components'

export const Container = styled.div``

export const Infos = styled.section`
  padding: 1em 0;
  div {
    display: flex;
    align-items: center;
  }
  div span:first-child {
    font-weight: 600;
  }
  div span:last-child {
    margin-left: 0.5em;
  }
`
