import React, { useEffect, useMemo, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import RadioInput from '../../../../components/RadioInputs'
import Select from '../../../../components/Select'

import {
  GroupOne,
  Group2,
  FormContainer,
  Group3,
  Group4,
  DivColumn
} from '../AddUser/AddUserStyles'
import { BtnGroup, Title, ContainerDrawer } from '../../StyledComponents'
import * as radioOptions from '../../constants/radios'
import useDisabled from '../../../../hooks/useDisabled'
import editUser from '../../controllers/editUser'
import getGroup from './constants/group'
import setDatas from './utils/setDatas'
import { IProps } from './types'
import searchHelper from '../../../../helpers/searchUser'
import SearchUser from '../../../../components/SearchUser'
import formatUserData from '../AddUser/utils/formatUserData'
import Drawer from '../../../../components/Drawer'
import AddDepartment from '../AddDepartment'
import AddOffice from '../AddOffice'

type SearchHelper = {
  value: string
  setValue: any
  setData: any
}

const EditUser: React.FC<IProps> = ({
  actionClose,
  user,
  filterItems,
  setChange,
  reloadOptions
}) => {
  const [name, setName] = useState('')
  const [registration, setRegistration] = useState('')
  const [email, setEmail] = useState('')
  const [admissionDate, setAdmissionDate] = useState('')
  const [department, setDepartment] = useState('')
  const [office, setOffice] = useState('')
  const [contractingModel, setContractingModel] = useState('')
  const [workModel, setWorkModel] = useState('')
  const [hoursPerweek, setHoursPerWeek] = useState('')

  const [hierarchy, setHierarchy] = useState('')
  const [manager, setManager] = useState<any[]>([])
  const [managerName, setManagerName] = useState('')
  const [result, setResult] = useState([])

  const [technicalLevel, setTechnicalLevel] = useState('')
  const [technicalReference, setTechnicalReference] = useState<any[]>([])
  const [referenceName, setReferenceName] = useState('')
  const [result2, setResult2] = useState([])
  const [canAddDp, setCanAddDp] = useState(false)
  const [canAddOffice, setCanAddOffice] = useState(false)

  const [disabled] = useDisabled([
    name,
    registration,
    email,
    admissionDate,
    department,
    office,
    contractingModel,
    hoursPerweek,
    hierarchy,
    technicalLevel,
    workModel
  ])

  const datas = useMemo(() => {
    return [
      { setHook: setName, key: 'name' },
      { setHook: setRegistration, key: 'acessCode' },
      { setHook: setEmail, key: 'email' },
      { setHook: setAdmissionDate, key: 'admissionDate' },
      { setHook: setDepartment, key: 'department' },
      { setHook: setOffice, key: 'office' },
      { setHook: setContractingModel, key: 'contractingModel' },
      { setHook: setWorkModel, key: 'workingModel' },
      { setHook: setHoursPerWeek, key: 'hoursPerDay' },
      { setHook: setHierarchy, key: 'hierarchy' },
      { setHook: setTechnicalLevel, key: 'technicalLevel' },
      { setHook: setManager, key: 'manager' },
      { setHook: setTechnicalReference, key: 'technicalReference' }
    ]
  }, [])

  useEffect(() => {
    setDatas(datas, user)
  }, [user, datas])

  useEffect(() => {
    if (user.manager) {
      if (user.manager.length > 0) {
        setManagerName(user.manager[0].name)
      }
    }

    if (user.technicalReference) {
      if (user.technicalReference.length > 0) {
        setReferenceName(user.technicalReference[0].name)
      }
    }

    return () => {}
  }, [user])

  const group1 = getGroup(
    name,
    setName,
    registration,
    setRegistration,
    email,
    setEmail,
    admissionDate,
    setAdmissionDate
  )

  const onSubmit = async () => {
    const data = {
      name,
      email,
      admissionDate,
      department,
      office,
      memberId: user.memberId,
      contractingModel,
      hoursPerDay: parseInt(hoursPerweek) / 5,
      role: hierarchy === 'Gestor' ? 'manager' : user.role,
      hierarchy,
      technicalLevel,
      workingModel: workModel,
      intern: contractingModel === 'Estágio',
      requestPJ: contractingModel === 'Pj',
      requestAcademic: contractingModel === 'Estágio',
      manager: formatUserData(manager),
      technicalReference: formatUserData(technicalReference)
    }
    await editUser(data, user._id, 'Colaborador editado com sucesso')
      .then((_) => setChange(true))
      .finally(() => actionClose())
  }

  const searchUsers = async ({ value, setData, setValue }: SearchHelper) => {
    setValue(value)
    await searchHelper(value, setData)
  }

  return (
    <ContainerDrawer>
      <Title>Editar cadastro de colaborador</Title>
      <Drawer visible={canAddDp} onClose={() => setCanAddDp(false)} width={742}>
        <AddDepartment
          setChange={reloadOptions}
          actionClose={() => setCanAddDp(false)}
        />
      </Drawer>
      <Drawer
        visible={canAddOffice}
        onClose={() => setCanAddOffice(false)}
        width={742}
      >
        <AddOffice
          setChange={reloadOptions}
          actionClose={() => setCanAddOffice(false)}
        />
      </Drawer>
      <FormContainer>
        <GroupOne>
          {group1.map((i) => (
            <>
              <Input
                label={i.label}
                iconEnd={i.iconEnd && i.iconEnd}
                name={i.name}
                value={i.value}
                onChange={(e) => i.setValue(e.target.value)}
                key={i.name}
                mask={i.mask && i.mask}
              />
            </>
          ))}
        </GroupOne>
        <Group2>
          <Select
            label="Departamento"
            value={department}
            setChange={setDepartment}
            options={filterItems.departments}
            cointainButton
            buttonLabel="Cadastrar departamento"
            buttonClick={() => setCanAddDp(true)}
          />
          <Select
            label="Cargo"
            value={office}
            setChange={setOffice}
            options={filterItems.offices}
            cointainButton
            buttonLabel="Cadastrar cargo"
            buttonClick={() => setCanAddOffice(true)}
          />
        </Group2>
        <Group3>
          <RadioInput
            display="column"
            label="Modelo de contratação"
            options={radioOptions.contractingModels}
            value={contractingModel}
            setValue={setContractingModel}
          />
          <RadioInput
            display="column"
            label="Modelo de trabalho"
            options={radioOptions.workModel}
            value={workModel}
            setValue={setWorkModel}
          />
          <RadioInput
            display="column"
            label="Horas semanais"
            options={radioOptions.hoursPerWeek}
            value={hoursPerweek}
            setValue={setHoursPerWeek}
          />
        </Group3>
        <Group4>
          <DivColumn>
            <RadioInput
              display="column"
              label="Nível hierárquico"
              options={radioOptions.hierarchy}
              value={hierarchy}
              setValue={setHierarchy}
            />
            {hierarchy === 'Gerido' && (
              <SearchUser
                data={result}
                arraySelected={manager}
                setData={setManager}
                value={managerName}
                onChange={(e) =>
                  searchUsers({
                    value: e.target.value,
                    setValue: setManagerName,
                    setData: setResult
                  })
                }
                label="Selecionar gestor"
                oneUser
                setOneName={setManagerName}
              />
            )}
          </DivColumn>
          <DivColumn>
            <RadioInput
              display="column"
              label="Nível técnico"
              options={radioOptions.technicalLevel}
              value={technicalLevel}
              setValue={setTechnicalLevel}
            />
            {technicalLevel === 'Membro da equipe' && (
              <SearchUser
                data={result2}
                arraySelected={technicalReference}
                setData={setTechnicalReference}
                value={referenceName}
                onChange={(e) =>
                  searchUsers({
                    value: e.target.value,
                    setValue: setReferenceName,
                    setData: setResult2
                  })
                }
                label="Selecionar referência técnica"
                oneUser
                setOneName={setReferenceName}
              />
            )}
          </DivColumn>
        </Group4>

        <BtnGroup>
          <Button
            onClick={actionClose}
            className="secondary"
            label="Cancelar"
            type="button"
          />
          <Button
            onClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}
            className="primary"
            label="Salvar"
            disabled={disabled}
            type="button"
            iconEnd={<MdCheck size={24} />}
          />
        </BtnGroup>
      </FormContainer>
    </ContainerDrawer>
  )
}

export default EditUser
