const optionsList = [
  { value: 'Espelho de ponto', label: 'Espelho de ponto' },
  { value: 'Intrajornada', label: 'Intrajornada' },
  { value: 'Interjornada', label: 'Interjornada' },
  { value: 'Horas Extras', label: 'Horas Extras' },
  { value: 'Absenteísmo', label: 'Absenteísmo' },
  { value: 'Ajustes de ponto', label: 'Ajustes de ponto' },
  { value: 'Colaboradores', label: 'Colaboradores' }
]

const timeList = [
  { value: 'Últimos 7 dias', label: 'Últimos 7 dias' },
  { value: 'Últimos 15 dias', label: 'Últimos 15 dias' },
  { value: 'Esse mês', label: 'Esse mês' },
  { value: 'Mês anterior', label: 'Mês anterior' },
  { value: 'Personalizar', label: 'Personalizar' }
]

const InfosList = [
  {
    value:
      'office department acessCode admissionDate workingModel contractingModel hoursPerDay dailyMarked hierarchy technicalLevel',
    label: 'Dados corporativos'
  },
  { value: 'email typeBlood cpf idRg', label: 'Dados pessoais' },
  { value: 'phone cep uf city address district', label: 'Dados de contato' },
  {
    value:
      'sizeShoes sizeShirt typeShirt foodRestriction dominantHand usernameInsta',
    label: 'Informações adicionais'
  },
  {
    value: 'course yearOfEntry half instituition graduate graduateYear',
    label: 'Dados acadêmicos'
  },
  { value: 'bank agency account', label: 'Dados bancários' },
  {
    value:
      'corporateName cnpj maritalStatus atuationArea cpf idRg cep uf city address district complement',
    label: 'Dados jurídicos'
  }
]

const formats = [{ value: 'pdf', label: 'PDF' }]

export { optionsList, timeList, InfosList, formats }
