import styled from 'styled-components'

interface ButtonProps {
  width: string
  heigth: string
}

export const ButtonContainer = styled.button<ButtonProps>`
  height: ${(props) => props.heigth};
  width: ${(props) => props.width};
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  transition: all 0.2s;

  &.primary {
    background: ${(props) => props.theme.primary_blue};
    color: ${(props) => props.theme.dropDowns};
  }

  &.primary:hover {
    background: ${(props) => props.theme.hoverButton};
  }

  &.primary:active {
    background: ${(props) => props.theme.activeButton};
  }

  &.secondary {
    background: transparent;
    border: 1px solid ${(props) => props.theme.primary_blue};
    color: ${(props) => props.theme.primary_blue};
  }

  &.secondary:hover {
    border-color: ${(props) => props.theme.hoverButton};
    color: ${(props) => props.theme.hoverButton};
  }

  &.secondary:active {
    border-color: ${(props) => props.theme.activeButton};
    color: ${(props) => props.theme.activeButton};
  }

  &.only-text {
    background: none;
    border: none;
    color: ${(props) => props.theme.primary_blue};
    width: initial;
    justify-content: flex-start;
  }

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`
