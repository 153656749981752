import React, { useEffect, useState } from 'react'
import { MdDelete, MdNotificationsActive } from 'react-icons/md'
import { Tooltip } from 'antd'

import ListHeader from '../../../../components/ListHeader'
import FilterHeader from '../../../../components/FilterHeader'

import filtersHeader from '../../constants/filtersHeader'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import List from './AccordionItem'

import EmptyWindow from '../../../../components/EmptyWindow'
import {
  Actions,
  ButtonAction,
  DataSection,
  DataUser
} from '../../../../GlobalStyledComponents'
import formatData from '../../../../utils/formatData'

import ProgressBar from './ProgressBar'
import deleteDoc from '../../controller/deleteDoc'
import fisrtAndLastName from '../../../../utils/firstAndLastName'
import { sendNotificationsFn } from '../../controller/sendNotifications'

interface ListProps {
  data: any[]
  handleSetOrder(value: string, order: string): void
  sort: string
}

const FilesList: React.FC<ListProps> = ({ data, handleSetOrder, sort }) => {
  const [docs, setDocs] = useState<any[]>([])

  useEffect(() => {
    setDocs(data)
  }, [data])

  const handleDelete = async (id: string, index: number) => {
    await deleteDoc(id)
  }

  const sendNotifications = async (id: string) => {
    await sendNotificationsFn(id)
  }

  return (
    <>
      <ListHeader>
        {filtersHeader.map((i) => (
          <FilterHeader
            label={i.label}
            key={i.value}
            clickable={!!i.value}
            clickTop={() => handleSetOrder(i.value, '-1')}
            clickBotttom={() => handleSetOrder(i.value, '1')}
            value={i.value}
            sortBy={sort}
          />
        ))}
      </ListHeader>
      {docs.length > 0 ? (
        <>
          {docs.map((i, index) => (
            <Accordion>
              <AccordionSummary style={{ height: '65px' }}>
                <DataSection>
                  <DataUser>{i.title}</DataUser>
                  {i.view}
                  <DataUser>
                    {!i.requiredViews || i.requiredViews.length === 0 ? (
                      <div>Não se aplica</div>
                    ) : (
                      <ProgressBar data={i.requiredViews} />
                    )}
                  </DataUser>
                  <DataUser>{formatData(i.createdAt)}</DataUser>
                  <DataUser>{i.by ? fisrtAndLastName(i.by) : 'Admin'}</DataUser>
                  <Actions>
                    <Tooltip placement="bottom" title="Notificar usuários">
                      <ButtonAction onClick={() => sendNotifications(i._id)}>
                        <MdNotificationsActive size={24} color="#757575" />
                      </ButtonAction>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Excluir">
                      <ButtonAction onClick={() => handleDelete(i._id, index)}>
                        <MdDelete size={24} className="delete" />
                      </ButtonAction>
                    </Tooltip>
                  </Actions>
                </DataSection>
              </AccordionSummary>
              <AccordionDetails>
                {!i.requiredViews || i.requiredViews.length === 0 ? (
                  <div>
                    Não foram solicitadas confirmação de leitura para este
                    arquivo
                  </div>
                ) : (
                  <List requiredData={i.requiredViews} />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : (
        <EmptyWindow />
      )}
    </>
  )
}

export default FilesList
