import React from 'react'

import {
  Label,
  RadioInput,
  ValueTitle,
  BoxInput,
  Title,
  Checked
} from './StyledComponents'
import { RadioProps } from './types'

type Object = {
  value: any
  label: string
}

const RadioInputs: React.FC<RadioProps> = ({
  label,
  options,
  display = 'row',
  value,
  setValue
}) => {
  const verifyCheck = (i: string | Object): boolean => i === value

  return (
    <Label>
      {label}
      <BoxInput display={display} className={display}>
        {options.map((i: string | Object) => (
          <ValueTitle>
            <RadioInput
              type="button"
              onClick={() => setValue(typeof i !== 'string' ? i.value : i)}
            >
              {verifyCheck(typeof i !== 'string' ? i.value : i) ? (
                <Checked />
              ) : null}
            </RadioInput>
            <Title>{typeof i !== 'string' ? i.label : i}</Title>
          </ValueTitle>
        ))}
      </BoxInput>
    </Label>
  )
}

export default RadioInputs
