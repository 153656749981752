import React, { useState } from 'react'
import { Tooltip } from 'antd'
import RadioInputs from '../../../../RadioInputs'
import DownloadIcon from '../../../../../assets/icons/Download'
import ViewDoc from '../../../../../assets/icons/DocView'

import { ListItem, Data, ViewAndDownload, Actions } from '../StyledComponents'

interface IProps {
  data: any
  containResult?: boolean
}

const ListItemFC: React.FC<IProps> = ({ data, containResult }) => {
  const [approved, setApproved] = useState('')
  return (
    <ListItem>
      <Data>
        <span>{data.expectedJourney}</span>
        <span>{data.journey}</span>
        <span>{data.reason}</span>
      </Data>
      {!containResult ? (
        <Actions>
          {data.attachment && (
            <ViewAndDownload>
              <Tooltip placement="bottom" title="Visualizar">
                <button>
                  <ViewDoc />
                </button>
              </Tooltip>
              <Tooltip placement="bottom" title="Download">
                <button>
                  <DownloadIcon />
                </button>
              </Tooltip>
            </ViewAndDownload>
          )}

          <RadioInputs
            options={['Aprovar', 'Reprovar']}
            value={approved}
            setValue={setApproved}
          />
        </Actions>
      ) : (
        <div>
          <span
            className={data.status === 'Aprovado' ? 'approved' : 'reproved'}
          >
            {data.status}
          </span>
        </div>
      )}
    </ListItem>
  )
}

export default ListItemFC
