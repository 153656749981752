import React, { InputHTMLAttributes } from 'react'

import { Label, Input } from './StyledComponents'
import AddDoc from './icons/AddDoc'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

const InputFile: React.FC<IProps> = ({ label, ...rest }) => {
  return (
    <Label>
      <AddDoc />
      {label}
      <Input type="file" {...rest} />
    </Label>
  )
}

export default InputFile
