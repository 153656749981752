import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SectionInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 329px);
  row-gap: 10px;
  column-gap: 1em;
  margin-top: 10px;
`
