import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Inputcontainer = styled.div`
  max-width: 396px;
  width: 100%;
  padding-right: 10px;
  display: flex;
  align-items: center;
  height: 56px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }
`

export const List = styled.ul`
  max-width: 396px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #bfbfbf;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  background: ${(props) => props.theme.dropDowns};
  z-index: 100;
`

export const ListItem = styled.li`
  display: flex;
  padding: 10px;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 10px;
  }

  &:hover {
    background: ${(props) => props.theme.primary_blue};
  }
`
