import styled from 'styled-components'

export const Container = styled.div`
  width: 300px;
  height: 100vh;
  background: ${(props) => props.theme.backgroundSecondary};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  transition: width 1s;
  padding: 80px 0 15px 0;

  &&.minimized {
    width: 104px;
  }
`

export const Menu = styled.ul`
  margin-top: 2rem;
`

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0px 30px;
  height: 40px;

  &&:last-child {
    border-top: 1px solid ${(props) => props.theme.borderColor_1};
    border-bottom: 1px solid ${(props) => props.theme.borderColor_1};
    padding-top: 22px;
    padding-bottom: 22px;
    margin-left: -1px;
  }

  &&.minimized {
    justify-content: center;
  }
`

export const ButtonLink = styled.button`
  align-items: center;
  color: ${(props) => props.theme.basicText};
  display: flex;
  &&.active {
    color: ${(props) => props.theme.primary_blue};
  }
`
export const TextBtn = styled.span`
  margin-left: 1rem;
  animation-name: FEEDBACKANIMATION;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  width: 150px;

  &&minimized {
    display: none;
  }

  @keyframes FEEDBACKANIMATION {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      display: block;
    }
  }
`

export const MoreContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImgContainer = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
`

export const AnimatedBar = styled.div`
  width: 8px;
  height: 44px;
  background: ${(props) => props.theme.primary_blue};
  position: absolute;
  left: 0;
  display: none;

  &&.active {
    display: block;
  }
`

export const MinimizeBtn = styled.button`
  margin-bottom: 0px;
  width: 100%;
  height: 60px;
  background: ${(props) => props.theme.grey2};
  bottom: 10%;
`
