import React, { useCallback, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import debounce from 'lodash.debounce'

import SearchInput from '../../components/SearchInput'
import SelectFilter from '../../components/SelectFilter'
import CheckItem from '../../components/CheckItem'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import Pagination from '../../components/Pagination'

import AddUser from './components/AddUser'
import ActiveList from './components/ActiveList'
import ViewMore from './components/ViewMore'

import {
  Filter,
  FilterAndButton,
  FiltersSection,
  TopRigthSearch
} from './StyledComponents'

import SearchInfos from '../../components/SearchInfo'
import useApi from '../../hooks/useApi'
import { UserProps } from '../../interfaces/user'
import userPropsObj from '../../constants/userProps'
import { InfoSearch } from '../../GlobalStyledComponents'

import { PagesProps } from './types'

const Colabs: React.FC<PagesProps> = ({ isManager }) => {
  const [search, setSearch] = useState('')
  const [visible, setVisible] = useState(false)
  const [viewMore, setViewMore] = useState(false)
  const [page, setPage] = useState(1)
  const [userViewMore, setUserViewMore] = useState<UserProps>({
    ...userPropsObj
  })
  const [change, setChange] = useState(false)

  const [contractingModels, setContractingModels] = useState<any[]>([])
  const [departments, setDeparments] = useState<any[]>([])
  const [hierarchies, setHierarchies] = useState<any[]>([])
  const [hoursPerDay, setHoursPerday] = useState<any[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const [sort, setSort] = useState('createdAt')
  const [order, setOrder] = useState('-1')
  const [filters, setFilters] = useState<any[]>([])
  const [endSearch, setEndSearch] = useState('')

  const [reloadOptions, setRealoadOptions] = useState(false)

  const [result, extra] = useApi(
    `/users?search=${endSearch}&contractingModel=${contractingModels}
    &department=${departments}&hierarchy=${hierarchies}
    &hoursPerDay=${hoursPerDay}&group=${groups}&page=${page}&limit=15&sort=${sort}&order=${order}`,
    'get',
    change,
    setChange
  )

  // eslint-disable-next-line
  const verify = useCallback(
    debounce((username) => setEndSearch(username), 500),
    []
  )

  const [filterItems] = useApi(
    '/departmentAndOffice',
    'get',
    reloadOptions,
    setRealoadOptions
  )

  const handleCloseAdd = () => {
    setVisible(false)
    setChange(true)
  }

  const clear = () => {
    setSearch('')
    setEndSearch('')
  }

  const filtersList = [
    {
      title: 'Contratação',
      options: [
        { value: 'CLT', label: 'CLT' },
        { value: 'Estágio', label: 'Estágio' },
        { value: 'PJ', label: 'PJ' }
      ],
      value: contractingModels,
      setValue: setContractingModels
    },
    {
      title: 'Departamento',
      options: filterItems.departments,
      value: departments,
      setValue: setDeparments
    },
    {
      title: 'Hierarquia',
      options: [
        {
          value: 'Gestor',
          label: 'Gestor'
        },
        { value: 'Gerido', label: 'Gerido' }
      ],
      value: hierarchies,
      setValue: setHierarchies
    },
    {
      title: 'Jornada',
      options: [
        { value: '8', label: '40' },
        { value: '6', label: '30' },
        { value: '4', label: '20' }
      ],
      value: hoursPerDay,
      setValue: setHoursPerday
    },
    {
      title: 'Grupo',
      options: filterItems.groups,
      value: groups,
      setValue: setGroups
    }
  ]

  const handleSearch = (value: string) => {
    setSearch(value)
    verify(value)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <>
      <Drawer width={920} visible={visible} onClose={() => setVisible(false)}>
        <AddUser
          actionClose={handleCloseAdd}
          open={visible}
          setChange={setChange}
          filterItems={filterItems}
          reloadOptions={setRealoadOptions}
        />
      </Drawer>
      <Drawer width="85%" visible={viewMore} onClose={() => setViewMore(false)}>
        <ViewMore data={userViewMore} actionClose={() => setViewMore(false)} />
      </Drawer>
      <TopRigthSearch>
        {!isManager ? (
          <Button
            onClick={() => setVisible(true)}
            className="primary"
            iconStart={<MdAdd />}
            label="Adicionar colaborador"
            width="243px"
          />
        ) : (
          <div />
        )}
        <SearchInput
          placeholder="Pesquisar por nome, CPF ou matrícula"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </TopRigthSearch>
      <FilterAndButton>
        <FiltersSection>
          <span>Filtar:</span>
          {filtersList.map((i) => (
            <Filter key={i.title}>
              <SelectFilter label={i.title}>
                <CheckItem
                  array={i.value}
                  setHook={i.setValue}
                  options={i.options}
                  filters={filters}
                  setFilters={setFilters}
                />
              </SelectFilter>
            </Filter>
          ))}
        </FiltersSection>
      </FilterAndButton>
      <InfoSearch>
        <SearchInfos
          data={filters}
          text={endSearch}
          result={result.length}
          objectHooks={[
            { hook: departments, setHook: setDeparments },
            { hook: contractingModels, setHook: setContractingModels },
            { hook: hierarchies, setHook: setHierarchies },
            { hook: hoursPerDay, setHook: setHoursPerday },
            { hook: groups, setHook: setGroups }
          ]}
          clearSearch={clear}
          setFilters={setFilters}
        />
      </InfoSearch>
      <ActiveList
        result={result}
        setViewMore={setViewMore}
        setUserViewMore={setUserViewMore}
        setChange={setChange}
        setSort={setSort}
        setOrder={setOrder}
        sortBy={sort}
        filterItems={filterItems}
        realoadOptions={setRealoadOptions}
        isManager={isManager}
      />
      {extra.totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Colabs
