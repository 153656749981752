const contractingModels = ['Estágio', 'CLT', 'PJ']
const hoursPerWeek = ['20', '30', '40']
const dailyMarked = ['2', '4']
const hierarchy = ['Gestor', 'Gerido']
const technicalLevel = ['Especialista', 'Membro da equipe']
const workModel = ['Presencial', 'Remoto']

export {
  contractingModels,
  hoursPerWeek,
  dailyMarked,
  hierarchy,
  technicalLevel,
  workModel
}
