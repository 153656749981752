import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        data-name="Subtra\xE7\xE3o 7"
        d="M27 30H3a3 3 0 01-3-3V3a3 3 0 013-3h24a3 3 0 013 3v24a3 3 0 01-3 3zM6 19.5v3h10.5v-3zm0-6v3h18v-3zm0-6v3h18v-3z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgComponent
