import React, { useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import UsersSelected from '../../../../components/UsersSelected'
import { BtnGroup, ContainerDrawer, Title } from '../../StyledComponents'
import useDisabled from '../../../../hooks/useDisabled'
import editUser from '../../controllers/editUser'
import { IProps } from './types'

const Promote: React.FC<IProps> = ({ user, actionClose, filterItems }) => {
  const [managedUsers, setManagedUsers] = useState<any[]>([])
  const [department, setDepartment] = useState('')
  const [disabled] = useDisabled([department])

  const onSubmit = async () => {
    const data = Object.assign(user, { hierarchy: 'Gestor', department })
    await editUser(
      data,
      user._id,
      'Colaborador promovido com sucesso'
    ).finally(() => actionClose())
  }

  return (
    <ContainerDrawer>
      <Title>Tornar gestor</Title>
      <Input label="Nome do colaborador" value={user.name} disabled />
      <div style={{ marginTop: '10px' }}>
        <Select
          label="Departamento"
          options={filterItems.departments}
          className="max-width"
          width="293px"
          value={department}
          setChange={setDepartment}
        />
      </div>

      {managedUsers.length > 0 && (
        <UsersSelected
          users={managedUsers}
          setState={setManagedUsers}
          label="Colaboradores geridos"
        />
      )}
      <BtnGroup>
        <Button className="secondary" label="Cancelar" onClick={actionClose} />
        <Button
          className="primary"
          label="Avançar"
          onClick={onSubmit}
          iconEnd={<MdChevronRight size={24} />}
          disabled={disabled}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default Promote
