import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const InputsGp = styled.div`
  display: grid;
  grid-template-columns: 396px auto;
  column-gap: 1em;
  row-gap: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`
