import styled from 'styled-components'

export const Container = styled.div``

export const List = styled.ul`
  width: 100%;
`

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 1px solid #757575;
  align-items: center;

  &.header-list {
    background: #f5f7f9;
    color: #383838;
  }

  span.approved {
    color: ${(props) => props.theme.green};
  }

  span.reproved {
    color: ${(props) => props.theme.red};
  }
`

export const Data = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 150px);
`

export const ReasonReproved = styled.div`
  span {
    font-weight: 600;
  }
`
