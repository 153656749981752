import React, { useMemo } from 'react'
import { MdEdit, MdDelete, MdPhoto } from 'react-icons/md'
import { Tooltip } from 'antd'
import AvatarWithGroup from '../../../../components/AvatarWithGroup'
import { List, ListItem, Top } from './StyledComponents'

import PdfIcon from '../../../../assets/icons/PDF'
import Video from '../../../../assets/icons/Video'
import useApi from '../../../../hooks/useApi'

interface IProps {
  data: any
  actionReturn: any
}

const Folder: React.FC<IProps> = ({ data, actionReturn }) => {
  const { name, members, admins } = data

  const [result] = useApi(`/file?folder=${name}`, 'get')

  const Users = useMemo(() => {
    return [...admins, ...members]
  }, [admins, members])

  const icons = {
    pdf: <PdfIcon />,
    video: <Video />,
    image: <MdPhoto size={35} color="#757575" />
  }

  //@ts-ignore
  const Icon = (type: string) => icons[type]

  return (
    <>
      <Top>
        <span>
          <button className="span-btn" onClick={actionReturn}>
            {'Pastas >'}
          </button>
          {name}
        </span>
        <div className="members">
          <span>Membros:</span>
          <AvatarWithGroup users={Users} />
        </div>
      </Top>
      <List>
        <ListItem className="header">
          <span>Nome</span>
          <span>Ações</span>
        </ListItem>
        {result.map((i: any) => (
          <ListItem key={i.title}>
            <div className="items-info">
              {Icon(i.type)}
              <span>{i.title}</span>
            </div>
            <div className="actions">
              <Tooltip placement="bottom" title="Editar">
                <button>
                  <MdEdit className="tools" size={24} />
                </button>
              </Tooltip>
              <Tooltip placement="bottom" title="Apagar">
                <button>
                  <MdDelete className="delete" size={24} />
                </button>
              </Tooltip>
            </div>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default Folder
