import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30.001}
      height={30.001}
      viewBox="0 0 30.001 30.001"
      {...props}
    >
      <path
        data-name="Interse\xE7\xE3o 3"
        d="M3.333 29.999a3.343 3.343 0 01-3.334-3.332V3.333A3.342 3.342 0 013.333-.001h23.334a3.343 3.343 0 013.332 3.334v23.334a3.344 3.344 0 01-3.332 3.332zm0-5h23.334l-7.5-10-5.833 7.517-4.168-5.017z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgComponent
