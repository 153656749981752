import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={45.794}
      height={49}
      viewBox="0 0 45.794 49"
      {...props}
    >
      <path d="M1.219 4.424h44.576V49H1.219z" fill="none" />
      <path
        d="M27.221 8.139H12.363a3.71 3.71 0 00-3.7 3.715l-.015 29.717a3.71 3.71 0 003.7 3.715h22.3a3.726 3.726 0 003.715-3.715V19.283zm3.715 29.717H16.077v-3.717h14.859zm0-7.429H16.077v-3.715h14.859zm-5.572-9.288V10.925l10.215 10.214z"
        fill="#757575"
      />
      <g fill="#757575" stroke="#fff" strokeWidth={1.5}>
        <path stroke="none" d="M1.5 8h18v4h-18z" />
        <path fill="none" d="M.75 7.25h19.5v5.5H.75z" />
        <g>
          <path stroke="none" d="M12.49 1.5v17.547H8.591V1.5z" />
          <path fill="none" d="M13.24.75v19.047H7.841V.75z" />
        </g>
      </g>
      <g fill="#757575" stroke="#757575">
        <path stroke="none" d="M.5 8h18v4H.5z" />
        <path fill="none" d="M1 8.5h17v3H1z" />
        <g>
          <path stroke="none" d="M11.49 1.5v17.547H7.591V1.5z" />
          <path fill="none" d="M10.99 2v16.547H8.091V2z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
