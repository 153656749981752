import api from '../../../services/api'

const uploadFile = async (data: any) => {
  return new Promise((resolve, reject) => {
    api
      .post('/file/uploadDocument', data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export default uploadFile
