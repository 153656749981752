import styled from 'styled-components'

export const List = styled.ul`
  width: 100%;
  margin-top: 1.5em;
`

export const ItemList = styled.li`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px 15px 10px 10px;
  gap: 15%;
  width: 100%;
  align-items: center;
  &.header {
    background: #f5f7f9;
  }
  &.list {
    border-bottom: 1px solid #757575;
    padding: 25px 10px;
  }

  &.header div:last-child {
    justify-content: flex-end;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .actions .delete {
    margin-left: 15px;
  }
`
