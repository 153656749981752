import styled from 'styled-components'

export const List = styled.ul``

export const Item = styled.li`
  display: grid;
  grid-template-columns: 200px 300px;
  padding: 5px 0;
  max-width: 500px;
  span:last-child {
    font-weight: 600;
  }
  div.value {
    display: flex;
    align-items: flex-start;
  }
`
