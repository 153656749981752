import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { UserProps } from '../interfaces/user'
import { returnAvatar } from '../services/getAvatarApi'
import { login } from '../services/login'
import setToken from '../utils/setToken'

interface Context {
  token: string
  signed: boolean
  profile: UserProps
  avatar_url: string
  rehydrated: boolean
  sideMinimized: boolean
}

interface PropsContext {
  state: Context
  setState: React.Dispatch<React.SetStateAction<Context>>
  Login(acessCode: string, password: string): Promise<void>
  Logout(): void
}

const DEFAULT_VALUE = {
  state: {
    rehydrated: false,
    token: '',
    signed: false,
    sideMinimized: false,
    profile: {} as UserProps,
    avatar_url: ''
  },
  setState: () => {}
}

const GlobalContext = React.createContext<PropsContext>({} as PropsContext)

const ContextProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState(DEFAULT_VALUE.state)

  const Login = async (acessCode: string, password: string) => {
    await login(acessCode, password).then(async ({ user, token }) => {
      const avatar = await returnAvatar(user, 'f6f6f6', '00b7ef')
      setState((prevState) => ({
        ...prevState,
        profile: user,
        token,
        signed: true,
        avatar_url: avatar
      }))
    })
  }

  const Logout = () => {
    setState(DEFAULT_VALUE.state)
    sessionStorage.removeItem('store')
    return <Redirect to="/" />
  }

  const rehydrate = async () => {
    const data = await sessionStorage.getItem('store')
    if (data) {
      const dataParsed = data && JSON.parse(data)
      const { token } = dataParsed
      setToken(token)
    }
    setState((prev) => ({
      ...prev,
      ...(data && JSON.parse(data)),
      rehydrated: true
    }))
  }

  useEffect(() => {
    rehydrate()
  }, [])

  useEffect(() => {
    sessionStorage.setItem('store', JSON.stringify(state))
  }, [state])

  return (
    <GlobalContext.Provider value={{ state, setState, Login, Logout }}>
      {children}
    </GlobalContext.Provider>
  )
}

export { ContextProvider }
export default GlobalContext
