import CSS from 'csstype'
import { NativeButtonProps } from 'antd/lib/button/button'

type Style = CSS.Properties

const ButtonStyles: Style = {
  borderRadius: '30px',
  paddingLeft: '20px',
  paddingRight: '20px'
}
const OkButtonStyle: NativeButtonProps = {
  style: {
    background: '#00B7EF',
    color: '#fffff',
    ...ButtonStyles
  }
}

const CancelButtonStyle: NativeButtonProps = {
  style: {
    borderColor: '#00B7EF',
    color: '#00B7EF',
    ...ButtonStyles
  }
}

export { OkButtonStyle, CancelButtonStyle }
