const users = [
  {
    name: 'Paulo',
    balance: '32h 12min',
    negative: false,
    period: '+32h e 10min',
    previst: '60h e 12min',
    completed: '30h e 12min'
  },
  {
    name: 'Ana',
    balance: '32h 12min',
    negative: true,
    period: '+32h e 10min',
    previst: '60h e 12min',
    completed: '30h e 12min'
  }
]
const itens = [
  { label: 'Nome', clickable: true, key: 'name' },
  { label: 'Jornada realizada', clickable: false },
  { label: 'Saldo do período', clickable: false },
  { label: 'Saldo total atual', clickable: false }
]

const itens2 = [
  { label: 'Nome' },
  { label: 'Saldo de horas atual' },
  { label: 'Ajustes pendentes' },
  { label: 'Jornada' }
]

export { users, itens, itens2 }
