import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(0, 183, 239, 1) 0%,
    rgba(21, 139, 191, 1) 98%
  );
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const Form = styled.form`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  max-width: 500px;
  padding: 2% 3%;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 10px;
  }
`

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #757575;
  border-bottom: 3px solid #00b7ef;
  width: 175px;
  margin-bottom: 1em;
`

export const Footer = styled.footer`
  align-self: center;
  position: fixed;
  bottom: 5%;
  color: #fff;

  a {
    color: #fff;
  }

  a:hover {
    text-decoration: underline;
  }
`
