import styled from 'styled-components'

export const Container = styled.div`
  width: 190px;
  height: 190px;

  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
`
