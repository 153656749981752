const key = 'memorian01042020-usemobile'

const encryptKey = (pass: string): string => {
  const encryptor = require('simple-encryptor')(key)
  const newCode = encryptor.encrypt(pass)
  return newCode
}

const decryptKey = (pass: string): string => {
  const encryptor = require('simple-encryptor')(key)
  const newCode = encryptor.decrypt(pass)
  return newCode
}

export { encryptKey, decryptKey }
