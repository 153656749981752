import moment from 'moment'

type Time =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'years'

const diff = (date: Date, time: Time): number => {
  const before = moment(date)
  const after = moment(new Date())
  return after.diff(before, time)
}

export { diff }
