import styled from 'styled-components'

export const InputContainer = styled.div`
  max-width: 600px;
  width: 100%;
  height: 56px;
  background: #f1f1f1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  padding: 0 10px;
`
