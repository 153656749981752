import { useState, useEffect } from 'react'
import api from '../services/api'
import errorFc from '../components/Errors'

type Methods = 'get' | 'post'

const useApi = (
  url: string,
  method: Methods,
  change: boolean = false,
  setChange?: any
) => {
  const [response, setResponse] = useState([])
  const [extraData, setExtraData] = useState<any>({})

  useEffect(() => {
    getData(method, url)
    if (change === true) {
      setChange(false)
    }
    return () => {
      setResponse([]) // This worked for me
      setExtraData([])
    }
  }, [url, method, change, setChange])

  const getData = (methodString: Methods, urlString: string) => {
    api({
      method: methodString,
      url: urlString
    })
      .then(({ data }) => {
        if (data.docs) {
          const { docs, totalPages, totalDocs, limit, page } = data
          setExtraData({ totalDocs, totalPages, limit, page })
          setResponse(docs)
        } else {
          setResponse(data)
        }
      })
      .catch((_) => {
        errorFc(422)
      })
  }

  const retorno = extraData !== {} ? [response, extraData] : [response]
  return retorno
}

export default useApi
