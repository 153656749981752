import React from 'react'
import verifyMarked from '../../utils/verifyMarked'

import CheckBox from '../StyledCheckBox'
import { handleSelect } from './helpers/handleSelect'
import { Container, Label, Item, ContentSection } from './StyledComponents'

type Options = { value: string; label: string }

interface IProps {
  array: any[]
  setHook: any
  options: Options[]
  label: string
  collumns?: 'double' | 'tree' | 'four'
}

const BoxChecks: React.FC<IProps> = ({
  options,
  setHook,
  label,
  array,
  collumns
}) => {
  const handleOnChange = (value: string) => {
    handleSelect(array, setHook, value)
  }

  return (
    <Container>
      {label}
      <ContentSection className={collumns}>
        {options.map((i) => (
          <Item>
            <CheckBox
              checked={verifyMarked(i.value, array)}
              handleClick={() => handleOnChange(i.value)}
            />
            <Label>{i.label}</Label>
          </Item>
        ))}
      </ContentSection>
    </Container>
  )
}

export default BoxChecks
