import { toast } from 'react-toastify'
import { UserProps } from '../../../interfaces/user'
import api from '../../../services/api'

const getUser = (id: string): Promise<UserProps> => {
  return new Promise((resolve, reject) => {
    api
      .get(`/users/${id}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
        toast.error('Ops, encontramos um problema por aqui.')
      })
  })
}

export { getUser }
