import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Button from '../Button'
import { IProps } from './types'
import { Container, Box, Result } from './StyledComnponents'

const SearchInfo: React.FC<IProps> = ({
  text,
  result,
  data = [],
  clearSearch,
  objectHooks,
  setFilters
}) => {
  const [items, setItems] = useState<any[]>([])

  const formatNumber = (value: string): string => {
    return value === '4' || value === '6' || value === '8'
      ? (Number(value) * 5).toString()
      : value
  }

  useEffect(() => {
    setItems([...data])
  }, [data])

  const handleClear = () => {
    if (clearSearch) {
      clearSearch()
    }
    if (setFilters) {
      setFilters([])
    }
    if (objectHooks) {
      objectHooks.map((i) => i.setHook(''))
    }
  }

  const handleRemove = (value: string) => {
    if (objectHooks) {
      for (let i = 0; i <= objectHooks?.length; i += 1) {
        const index = objectHooks[i].hook.indexOf(value)
        if (index >= 0) {
          const arr = [...objectHooks[i].hook]
          arr.splice(index, 1)
          objectHooks[i].setHook(arr)
          const filter = [...items]
          filter.splice(items.indexOf(value), 1)
          setFilters(filter)
          break
        }
      }
    }
  }

  return (
    <>
      {(text !== '' || items.length > 0) && (
        <Container>
          {text !== '' && (
            <>
              Você pesquisou por:<span>{`"${text}"`}</span>
            </>
          )}
          {typeof data !== undefined && (
            <>
              {items.length > 0 && (
                <>
                  em:
                  {items.map((i) => (
                    <Box>
                      {formatNumber(i)}
                      <button onClick={() => handleRemove(i)}>
                        <MdClose />
                      </button>
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
          {result > 0 && <Result>{`(${result} resultados)`}</Result>}
          {text && (
            <Button
              className="only-text"
              label="Limpar"
              onClick={handleClear}
            />
          )}
        </Container>
      )}
    </>
  )
}

export default SearchInfo
