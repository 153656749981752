import React from 'react'

import { Container, Item } from './StyledComponents'
import StyledCheckBox from '../../../../components/StyledCheckBox'

import terms from '../../constants/terms'
import validateArray from '../../../../utils/validateArray'
import verifyMarked from '../../../../utils/verifyMarked'

interface IProps {
  selectedItems: string[]
  setArray: any
}
const SelectDocs: React.FC<IProps> = ({ selectedItems, setArray }) => {
  const handleSelect = (value: string) => {
    const array = validateArray(selectedItems, value)
    setArray([...array])
  }
  return (
    <Container>
      {terms.map((term) => (
        <Item key={term.id}>
          <StyledCheckBox
            checked={verifyMarked(term.value, selectedItems)}
            handleClick={() => handleSelect(term.value)}
          />
          <span>{term.label}</span>
        </Item>
      ))}
    </Container>
  )
}

export default SelectDocs
