import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 11.421 14.278"
      {...props}
    >
      <path
        data-name="Exclus\xE3o 3"
        d="M9.992 14.278H1.419A1.425 1.425 0 010 12.849V1.428A1.426 1.426 0 011.421 0h5.712l4.283 4.283v8.566a1.43 1.43 0 01-1.424 1.429zM2.84 10.899a.54.54 0 00-.539.539.548.548 0 00.539.541.549.549 0 00.542-.541.541.541 0 00-.542-.538zm1.081.179v.72h5.036v-.72zM2.84 8.741a.54.54 0 00-.539.539.541.541 0 00.539.541.542.542 0 00.542-.541.541.541 0 00-.542-.54zm1.081.181v.718h5.036v-.718zM2.84 6.583a.54.54 0 00-.539.539.541.541 0 00.539.541.542.542 0 00.542-.541.541.541 0 00-.542-.54zm1.081.181v.718h5.036v-.718zm2.5-5.693V5h3.925L6.421 1.075z"
        fill="#383838"
      />
    </svg>
  )
}

export default SvgComponent
