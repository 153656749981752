import { toast } from 'react-toastify'

export const checkStatusCode = (reject: { response: { status: any } }) => {
  if (reject.response) {
    const { status } = reject.response
    if (status === 401 || status === 403) {
      toast.error('Sessão expirada, faça login novamente')
    } else {
      toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
    }
  } else {
    toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
  }
}
