import api from '../../../../../../../services/api'

const finalizeDownload = (downloadUrl: string, fileName: string) => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  // @ts-ignore
  a.style = 'display: none'
  a.href = downloadUrl
  a.download = `memorian_${fileName}`
  a.click()
  URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

const getFile = async (file: string, setProgress: any): Promise<any> => {
  const config = {
    onDownloadProgress(progressEvent: { loaded: number; total: number }) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
    }
  }
  await api.get(`/file/${file}`, config).then((res) => {
    finalizeDownload(res.data, file)
  })
}

export default getFile
