import React, { useEffect, useMemo, useState } from 'react'
import { MdEvent, MdChevronRight } from 'react-icons/md'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import RadioInput from '../../../../components/RadioInputs'
import createUser from './utils/addUser'

import {
  GroupOne,
  Group2,
  FormContainer,
  Group3,
  Group4,
  DivColumn
} from './AddUserStyles'
import { BtnGroup, Title, ContainerDrawer } from '../../StyledComponents'
import * as radioOptions from '../../constants/radios'
import useDisabled from '../../../../hooks/useDisabled'
import Select from '../../../../components/Select'
import searchHelper from '../../../../helpers/searchUser'
import SearchUser from '../../../../components/SearchUser'
import formatUserData from './utils/formatUserData'
import Drawer from '../../../../components/Drawer'
import AddDepartment from '../AddDepartment'
import AddOffice from '../AddOffice'

interface IProps {
  actionClose: any
  open: boolean
  setChange: any
  filterItems: any
  reloadOptions: any
}

type SearchHelper = {
  value: string
  setValue: any
  setData: any
}

const AddUser: React.FC<IProps> = ({
  actionClose,
  open,
  setChange,
  filterItems,
  reloadOptions
}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [admissionDate, setAdmissionDate] = useState('')
  const [department, setDepartment] = useState('')
  const [office, setOffice] = useState('')
  const [contractingModel, setContractingModel] = useState('')
  const [workModel, setWorkModel] = useState('')
  const [hoursPerweek, setHoursPerWeek] = useState('')

  const [hierarchy, setHierarchy] = useState('')
  const [manager, setManager] = useState<any[]>([])
  const [managerName, setManagerName] = useState('')
  const [result, setResult] = useState([])

  const [technicalLevel, setTechnicalLevel] = useState('')
  const [technicalReference, setTechnicalReference] = useState<any[]>([])
  const [referenceName, setReferenceName] = useState('')
  const [result2, setResult2] = useState([])

  const [loading, setLoading] = useState(false)
  const [canAddDp, setCanAddDp] = useState(false)
  const [canAddOffice, setCanAddOffice] = useState(false)

  const [disabled] = useDisabled([
    name,
    email,
    admissionDate,
    department,
    office,
    contractingModel,
    hoursPerweek,
    hierarchy,
    technicalLevel,
    workModel
  ])

  const setStates = useMemo(() => {
    return [
      setName,
      setEmail,
      setAdmissionDate,
      setAdmissionDate,
      setDepartment,
      setOffice,
      setContractingModel,
      setHoursPerWeek,
      setHierarchy,
      setTechnicalLevel,
      setWorkModel
    ]
  }, [])

  useEffect(() => {
    if (!open) {
      setStates.map((setState) => setState(''))
    }
  }, [setStates, open])

  const group1 = [
    {
      label: 'Nome completo',
      name: 'name',
      value: name,
      setValue: setName
    },
    {
      label: 'Data de admissão',
      iconEnd: <MdEvent size={20} />,
      name: 'admissionDate',
      value: admissionDate,
      setValue: setAdmissionDate,
      mask: '99/99/9999'
    },
    {
      label: 'Email',
      name: 'email',
      value: email,
      setValue: setEmail
    }
  ]

  const onSubmit = async () => {
    setLoading(true)
    const data = {
      name,
      email,
      admissionDate,
      department,
      office,
      contractingModel,
      hoursPerDay: parseInt(hoursPerweek) / 5,
      hierarchy,
      technicalLevel,
      workModel,
      intern: contractingModel === 'Estágio',
      requestPJ: contractingModel === 'Pj',
      requestAcademic: contractingModel === 'Estágio',
      salary: '0000',
      company: 'Usemobile',
      password: ' 1234',
      manager: formatUserData(manager),
      technicalReference: formatUserData(technicalReference)
    }
    setLoading(true)
    await createUser(data)
      .then((_) => setChange(false))
      .finally(() => {
        setLoading(false)
        actionClose()
      })
  }

  const searchUsers = async ({ value, setData, setValue }: SearchHelper) => {
    setValue(value)
    await searchHelper(value, setData)
  }

  return (
    <ContainerDrawer>
      <Title>Adicionar colaborador</Title>
      <Drawer visible={canAddDp} onClose={() => setCanAddDp(false)} width={742}>
        <AddDepartment
          setChange={reloadOptions}
          actionClose={() => setCanAddDp(false)}
        />
      </Drawer>
      <Drawer
        visible={canAddOffice}
        onClose={() => setCanAddOffice(false)}
        width={742}
      >
        <AddOffice
          setChange={reloadOptions}
          actionClose={() => setCanAddOffice(false)}
        />
      </Drawer>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <GroupOne>
          {group1.map((i) => (
            <>
              <Input
                label={i.label}
                iconEnd={i.iconEnd && i.iconEnd}
                name={i.name}
                value={i.value}
                onChange={(e) => i.setValue(e.target.value)}
                key={i.name}
                mask={i.mask && i.mask}
              />
            </>
          ))}
        </GroupOne>
        <Group2>
          <Select
            label="Departamento"
            value={department}
            setChange={setDepartment}
            options={filterItems.departments}
            cointainButton
            buttonLabel="Cadastrar departamento"
            buttonClick={() => setCanAddDp(true)}
          />
          <Select
            label="Cargo"
            value={office}
            setChange={setOffice}
            options={filterItems.offices}
            cointainButton
            buttonLabel="Cadastrar cargo"
            buttonClick={() => setCanAddOffice(true)}
          />
        </Group2>
        <Group3>
          <RadioInput
            display="column"
            label="Modelo de contratação"
            options={radioOptions.contractingModels}
            value={contractingModel}
            setValue={setContractingModel}
          />
          <RadioInput
            display="column"
            label="Modelo de trabalho"
            options={radioOptions.workModel}
            value={workModel}
            setValue={setWorkModel}
          />
          <RadioInput
            display="column"
            label="Horas semanais"
            options={radioOptions.hoursPerWeek}
            value={hoursPerweek}
            setValue={setHoursPerWeek}
          />
        </Group3>
        <Group4>
          <DivColumn>
            <RadioInput
              display="column"
              label="Nível hierárquico"
              options={radioOptions.hierarchy}
              value={hierarchy}
              setValue={setHierarchy}
            />
            {hierarchy === 'Gerido' && (
              <SearchUser
                data={result}
                arraySelected={manager}
                setData={setManager}
                value={managerName}
                onChange={(e) =>
                  searchUsers({
                    value: e.target.value,
                    setValue: setManagerName,
                    setData: setResult
                  })
                }
                oneUser
                setOneName={setManagerName}
              />
            )}
          </DivColumn>
          <DivColumn>
            <RadioInput
              display="column"
              label="Nível técnico"
              options={radioOptions.technicalLevel}
              value={technicalLevel}
              setValue={setTechnicalLevel}
            />
            {technicalLevel === 'Membro da equipe' && (
              <SearchUser
                data={result2}
                arraySelected={technicalReference}
                setData={setTechnicalReference}
                value={referenceName}
                onChange={(e) =>
                  searchUsers({
                    value: e.target.value,
                    setValue: setReferenceName,
                    setData: setResult2
                  })
                }
                oneUser
                setOneName={setReferenceName}
              />
            )}
          </DivColumn>
        </Group4>

        <BtnGroup>
          <Button
            onClick={actionClose}
            className="secondary"
            label="Cancelar"
            type="button"
          />
          <Button
            onClick={() => {}}
            className="primary"
            label="Adicionar"
            disabled={disabled}
            type="submit"
            iconEnd={<MdChevronRight size={24} />}
            loading={loading}
          />
        </BtnGroup>
      </FormContainer>
    </ContainerDrawer>
  )
}

export default AddUser
