import { MdLock, MdPerson } from 'react-icons/md'

const returnInputs = (
  acessCode: string,
  setAcessCode: any,
  password: string,
  setPassword: any
) => {
  return [
    {
      value: acessCode,
      setValue: setAcessCode,
      iconStart: <MdPerson size={21} color="#757575" />,
      mask: '9999',
      placeholder: 'Código de acesso'
    },
    {
      value: password,
      setValue: setPassword,
      iconStart: <MdLock size={21} color="#757575" />,
      mask: '9999',
      placeholder: 'Senha',
      type: 'password',
      errorMessage:
        'Código de acesso e/ou senha inválidos. Verifique-os e tente novamente.'
    }
  ]
}

export default returnInputs
