import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { MdChevronRight } from 'react-icons/md'
import Button from '../../components/Button'
import SearchUser from '../../components/SearchUser'
import UserSelected from '../../components/UsersSelected'
import { BtnGroup } from '../Colabs/StyledComponents'
import SelectDocs from './components/SelectDocs'
import sendTerms from './helpers/sendTerms'
import searchHelper from './helpers/searchUsers'

import { DivTitle, Title, Section, SearchSection } from './StyledComponents'

const Docs: React.FC = () => {
  const [users, setUsers] = useState([])
  const [terms, setTerms] = useState([])
  const [userName, setUserName] = useState('')
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)

  const searchUser = async (value: string) => {
    setUserName(value)
    await searchHelper(value, setResult)
  }

  const handleSubmit = async () => {
    setLoading(true)
    await sendTerms(users, terms)
      .then(() => toast.success('Termos enviados com sucesso'))
      .catch((_) =>
        toast.error('Ops... Encontramos um problema por aqui, tente novamente!')
      )
      .finally(() => setLoading(false))
  }

  const handleCancel = () => {
    setUsers([])
    setTerms([])
    setUserName('')
  }

  return (
    <>
      <Section>
        <DivTitle>
          <Title>Gerar documentação</Title>
        </DivTitle>
        <SelectDocs selectedItems={terms} setArray={setTerms} />
      </Section>
      <Section>
        <DivTitle>
          <Title>Colaboradores</Title>
        </DivTitle>
        <SearchSection>
          <div style={{ marginTop: '10px' }}>
            <SearchUser
              data={result}
              value={userName}
              onChange={(e) => searchUser(e.target.value)}
              arraySelected={users}
              setData={setUsers}
            />
          </div>

          <UserSelected users={users} setState={setUsers} />
        </SearchSection>
        <BtnGroup className="btns">
          <Button
            label="Cancelar"
            className="secondary"
            onClick={handleCancel}
          />
          <Button
            label="Enviar"
            className="primary"
            onClick={handleSubmit}
            iconEnd={<MdChevronRight size={24} />}
            loading={loading}
          />
        </BtnGroup>
      </Section>
    </>
  )
}

export default Docs
