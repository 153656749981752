import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0;
  max-width: 100%;
`

export const Item = styled.div`
  display: flex;
  padding: 10px 0;
  span {
    margin-left: 10px;
  }

  @media (max-width: 1650px) {
    padding: 5px 0;
  }
`
