import styled from 'styled-components'

export const List = styled.ul``

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  div.items-info {
    display: flex;
    align-items: center;
  }

  svg.folder {
    color: ${(props) => props.theme.primary_blue};
  }

  div.items-info span {
    margin-left: 10px;
  }

  &.header {
    background: #f5f7f9;
    span {
      color: #757575;
    }
  }
`

export const Top = styled.span`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  button,
  span {
    font-size: 20px;
  }
  span {
    margin-left: 10px;
  }

  div.members {
    display: flex;
    align-items: center;
  }

  div.members span {
    font-size: 15px;
  }
`
