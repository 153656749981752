import React, { useState } from 'react'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { SelectContainer } from './StyledComponents'

//@ts-ignore
import useClickAway from 'react-use-click-away'

interface FilterProps {
  label: string
}

const SelectFilter: React.FC<FilterProps> = ({ label, children }) => {
  const [open, setOpen] = useState(false)
  const handleOnClick = () => {
    setOpen(!open)
  }

  useClickAway({
    open,
    setOpen,
    reactAppId: 'root',
    clickable: ['check-click']
  })

  return (
    <div className="check-click" style={{ position: 'relative' }}>
      <SelectContainer onClick={handleOnClick}>
        {label}
        {open ? <MdArrowDropUp size={24} /> : <MdArrowDropDown size={24} />}
      </SelectContainer>
      {open && children}
    </div>
  )
}

export default SelectFilter
