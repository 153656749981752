import React from 'react'
import { BtnGroup, ContainerDrawer, Title } from '../../GlobalStyledComponents'
import Button from '../Button'
import { Container, Infos } from './StyledComponents'
import List from './components/ListActions'
import formatData from '../../utils/formatData'

interface IProps {
  actionClose: any
  containResult?: boolean
}

const AcceptVocation: React.FC<IProps> = ({
  actionClose,
  containResult = false
}) => {
  const headers = [
    { label: 'Jornada prevista' },
    { label: 'Jornada trabalhada' },
    { label: 'Motivo' }
  ]

  const mock = [
    {
      expectedJourney: '+03h 52min',
      journey: '01h 05min',
      reason: 'Médico',
      attachment: true,
      status: 'Reprovado'
    }
  ]

  const buttons = [
    {
      label: 'Cancelar',
      className: 'secondary',
      width: '164px',
      onclick: () => actionClose()
    },
    {
      label: 'Salvar',
      className: 'primary',
      width: '164px',
      onclick: () => {}
    }
  ]

  return (
    <ContainerDrawer>
      <Title>Falta justificada</Title>
      <Container>
        <Infos>
          <div>
            <span>Solicitante:</span>
            <span>Paulo</span>
          </div>
          <div>
            <span>Solicitado em:</span>
            <span>{formatData(new Date())}</span>
          </div>
          {!containResult && (
            <div>
              <span>Data a ser ajustada</span>
              <span>{formatData(new Date())}</span>
            </div>
          )}
          {containResult && (
            <>
              <div>
                <span>Correção referente ao dia:</span>
                <span>{formatData(new Date())}</span>
              </div>
              <div>
                <span>Corrigido em:</span>
                <span>{formatData(new Date())}</span>
              </div>
            </>
          )}
        </Infos>
        <List titles={headers} data={mock} containResult={containResult} />
      </Container>
      {!containResult && (
        <BtnGroup>
          {buttons.map((i) => (
            <Button
              label={i.label}
              className={i.className === 'primary' ? 'primary' : 'secondary'}
              onClick={() => i.onclick()}
            />
          ))}
        </BtnGroup>
      )}
    </ContainerDrawer>
  )
}

export default AcceptVocation
