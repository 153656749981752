import React from 'react'

import { Container, Title, GridDouble } from './StyledComponents'
import Infos from './components/Infos'
import AvatarBox from '../../../../components/AvatarBox'
import DocList from './components/DocList'
import { UserProps } from '../../../../interfaces/user'

interface IProps {
  data: UserProps
  isManager: boolean
}

const MainData: React.FC<IProps> = ({ data, isManager }) => {
  const corporativeData = [
    { key: 'Cargo', value: data.office },
    { key: 'Departamento', value: data.department },
    { key: 'Matrícula', value: data.acessCode },
    { key: 'Data de admissão', value: data.admissionDate },
    { key: 'Modo de trabalho', value: data.workingModel }
  ]

  const corporativeData2 = [
    { key: 'Modelo de contratação', value: data.contractingModel },
    {
      key: 'Horas semanais',
      value: isNaN(data.hoursPerDay * 5)
        ? ''
        : (data.hoursPerDay * 5).toString()
    },
    { key: 'Nível hierárquico', value: data.hierarchy },
    { key: 'Nível técnico', value: data.technicalLevel }
  ]

  const personData = [
    { key: 'Nome completo', value: data.name },
    { key: 'E-mail corporativo', value: data.email },
    { key: 'Tipo sanguíneo', value: data.typeBlood },
    { key: 'CPF', value: data.cpf },
    { key: 'RG', value: data.idRg },
    { key: 'Data de nascimento', value: data.dateBirth }
  ]

  const contactData = [
    { key: 'DDD + celular', value: data.phone },
    { key: 'CEP', value: data.cep },
    { key: 'Estado', value: data.uf },
    { key: 'Cidade', value: data.city },
    {
      key: 'Endereço',
      value: `${`${data.address || ''},`}${data.district || ''}`
    }
  ]

  const academicData = [
    { key: 'Curso', value: data.course },
    { key: 'Ano de ingresso', value: data.yearOfEntry },
    { key: 'Semestre de ingresso', value: data.half },
    { key: 'Instituição', value: data.instituition },
    { key: 'Concluído', value: data.graduate ? 'Sim' : 'Não' }
  ]

  const juridicData = [
    { key: 'Razão social', value: data.corporateName },
    { key: 'CNPJ', value: data.cnpj }
  ]

  const pjData = [
    { key: 'Nome completo', value: data.name },
    { key: 'Estado civil', value: data.maritalStatus },
    { key: 'Área de atuação', value: data.atuationArea },
    { key: 'CPF', value: data.cpf },
    { key: 'RG', value: data.idRg }
  ]

  const contactDataPJ = [
    { key: 'CEP', value: data.cep },
    { key: 'Estado', value: data.ufJuridic },
    { key: 'Cidade', value: data.cityJuridic },
    { key: 'Endereço', value: data.addressJuridic || '' },
    { key: 'Bairro', value: data.districtJuridic || '' },
    { key: 'Complemento', value: data.complementJuridic }
  ]

  return (
    <Container>
      <>
        <Title>Dados corporativos</Title>
        <GridDouble>
          <div
            style={{
              display: 'flex'
            }}
          >
            <AvatarBox data={data} />
            <Infos
              data={corporativeData}
              style={{ width: '250px', marginLeft: '10px' }}
            />
          </div>
          <Infos data={corporativeData2} />
          <div>
            <Title>Dados pessoais</Title>
            <Infos data={personData} />
          </div>
          <div>
            <Title>Dados de contato</Title>
            <Infos data={contactData} />
          </div>
        </GridDouble>
        <>
          <Title>Dados acadêmicos</Title>
          <Infos data={academicData} />
        </>
        {data.contractingModel === 'PJ' && (
          <>
            <Title>Dados jurídicos de pessoa jurídica</Title>
            <Infos data={juridicData} />
          </>
        )}

        {data.contractingModel === 'PJ' && (
          <>
            <Title>Dados jurídicos do anuente</Title>
            <GridDouble>
              <Infos data={pjData} />
              <Infos data={contactDataPJ} />
            </GridDouble>
          </>
        )}
        {!isManager && (
          <>
            <Title>Documentos</Title>
            <DocList
              rgBack={data.documentRgBack}
              rgFront={data.documentRg}
              docCollege={data.documentCollege}
              cnpjDoc={data.documentCnpj}
            />
          </>
        )}
      </>
    </Container>
  )
}

export default MainData
