import React, { useEffect, useMemo, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { BtnGroup, Title, ContainerDrawer } from '../../StyledComponents'
import { SelectContainer, Check } from './StyledComponents'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import StyledCheckBox from '../../../../components/StyledCheckBox'
import useDisabled from '../../../../hooks/useDisabled'
import create from '../../../../helpers/create'
import useApi from '../../../../hooks/useApi'
import Select from '../../../../components/Select'
import Drawer from '../../../../components/Drawer'
import AddDepartment from '../AddDepartment'

interface IProps {
  actionClose: any
  setChange: any
  data: any
}

const EditOffice: React.FC<IProps> = ({ actionClose, setChange, data }) => {
  const [notBound, setNotBound] = useState(false)
  const [label, setLabel] = useState('')
  const [department, setDepartment] = useState('')
  const [loading, setLoading] = useState(false)
  const [canAddDp, setCanAddDp] = useState(false)
  const [reloadOptions, setRealoadOptions] = useState(false)

  useEffect(() => {
    setLabel(data.label)
    setDepartment(data.department)
  }, [data])

  const [result] = useApi(
    '/department',
    'get',
    reloadOptions,
    setRealoadOptions
  )

  const requiredFields = useMemo(() => {
    const required = notBound ? [label] : [label, department]
    return required
  }, [notBound, label, department])

  const handleAdd = async () => {
    setLoading(true)
    const item = notBound ? { label } : { label, department }
    await create(
      item,
      `/office/update/${data._id}`,
      'Cargo editado com sucesso.',
      setLoading
    )
      .then((_) => setChange(true))
      .finally(() => actionClose())
  }

  const handleClose = () => {
    setLabel('')
    setDepartment('')
    actionClose()
  }

  const [disabled] = useDisabled(requiredFields)
  return (
    <ContainerDrawer>
      <Drawer visible={canAddDp} onClose={() => setCanAddDp(false)} width={742}>
        <AddDepartment
          setChange={setRealoadOptions}
          actionClose={() => setCanAddDp(false)}
        />
      </Drawer>
      <Title>Editar cargo</Title>
      <Input
        label="Nome do cargo"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
      <SelectContainer>
        <Select
          label="Departamento"
          className="max-width"
          options={result}
          disabled={notBound}
          width="293px"
          value={department}
          setChange={setDepartment}
          cointainButton
          buttonLabel="Cadastrar departamento"
          buttonClick={() => setCanAddDp(true)}
        />
        <Check>
          <StyledCheckBox
            checked={notBound}
            handleClick={() => setNotBound(!notBound)}
          />
          <span>Não vincular a um departamento específico</span>
        </Check>
      </SelectContainer>

      <BtnGroup>
        <Button className="secondary" label="Cancelar" onClick={handleClose} />
        <Button
          className="primary"
          label="Salvar"
          onClick={handleAdd}
          iconEnd={<MdChevronRight size={21} />}
          disabled={disabled}
          loading={loading}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default EditOffice
