type GPData = {
  name: string
  number: string
  colabs: any[]
}

const getData = (data: GPData) => {
  const { name, number, colabs } = data
  return {
    name,
    number,
    colabs,
    numberOfMembers: colabs.length
  }
}

export default getData
