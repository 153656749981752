import { toast } from 'react-toastify'
import api from '../../../../../services/api'

const update = async (url: string, data: any, message: string) => {
  return new Promise((resolve, reject) => {
    api
      .post(url, data)
      .then((res) => {
        toast.success(message)
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default update
