import { toast } from 'react-toastify'

const codes = {
  141: 'Ops... Encontramos um problema aos buscar no banco de horas, tente novamente',
  422: 'Ops... Encontramos um problema por aqui, tente novamente!',
  401: 'Ops... Sua sessão expirou, faça login novamente.'
}

const ErrorFC = (code: number) => {
  let message: string
  switch (code) {
    case 141:
      message = codes[141]
      break
    case 401 || 403:
      message = codes[401]
      break
    default:
      message = codes[422]
      break
  }
  return toast.error(message)
}

export default ErrorFC
