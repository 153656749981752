import styled from 'styled-components'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

export const GroupOne = styled.div`
  display: grid;
  grid-template-columns: 499px 232px;
  gap: 10px;
`

export const Group2 = styled.div`
  display: grid;
  grid-template-columns: 293px 438px;
  gap: 10px;
  margin-top: 10px;
`

export const Group3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 240px);
  gap: 10px;
  margin-top: 3rem;
`

export const Group4 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 240px);
  gap: 10px;
  margin-top: 1rem;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
`
