import styled from 'styled-components'

export const SelectContainer = styled.button`
  align-items: center;
  background: #fff;
  border-color: #cecece;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  color: #757575;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: space-between;
  width: 190px;

  padding: 0 0 0 8px;
  position: relative;

  @media (max-width: 1650px) {
    width: 150px;
  }
`
