import React, { useState } from 'react'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Container, Form, Title, Content, Footer } from './StyledComponents'
import returnInputs from './utils/returnInputs'
import MemorianLogo from '../../assets/icons/MemorianLogoLarge'
import Context from '../../context/Global'
import { onlyNumbers } from '../../utils/onlyNumbers'

const Login: React.FC = () => {
  const [acessCode, setAcessCode] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorPassword, setErrPassword] = useState(false)
  const { Login } = React.useContext(Context)

  const inputs = returnInputs(acessCode, setAcessCode, password, setPassword)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    await Login(acessCode, password)
      .catch((err) => {
        setErrPassword(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Title>Dashboard Login</Title>
          {inputs.map((i) => (
            <Input
              placeholder={i.placeholder}
              value={i.value}
              iconStart={i.iconStart}
              onChange={(e) => i.setValue(onlyNumbers(e.target.value))}
              maxLength={4}
              type={i.type}
              styles={{ marginBottom: '2em' }}
              key={i.placeholder}
              error={errorPassword}
              errorMessage={i.errorMessage}
            />
          ))}
          <Button
            label="Entrar"
            className="primary"
            type="submit"
            loading={loading}
            styles={{ alignSelf: 'flex-end', marginTop: '2em' }}
            width="190px"
          />
        </Form>
        <MemorianLogo />
      </Content>
      <Footer>
        Desenvolvido por{' '}
        <a href="https://usemobile.com.br/" target="_blank" rel="noreferrer">
          Usemobile
        </a>
      </Footer>
    </Container>
  )
}

export default Login
