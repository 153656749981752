import { useState, useEffect } from 'react'

type Operations = 'sum' | 'subtraction' | 'multiplication' | 'division'

const useMath = (numbers: number[], op: Operations) => {
  const [result, setResult] = useState(0)

  useEffect(() => {
    const opFn = () => {
      switch (op) {
        case 'sum': {
          setResult(numbers.reduce((a, b) => a + b))
          break
        }
        case 'subtraction': {
          setResult(numbers.reduce((a, b) => a - b))
          break
        }
        case 'multiplication': {
          setResult(numbers.reduce((a, b) => a * b))
          break
        }
        case 'division': {
          setResult(numbers.reduce((a, b) => a / b))
          break
        }
        default: {
          throw new Error('Enter a valid operation')
        }
      }
    }
    opFn()
  }, [numbers, op])

  return [result]
}

export default useMath
