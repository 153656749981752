import React, { useState, useEffect } from 'react'
import { MdChevronRight } from 'react-icons/md'
import {
  BtnGroup,
  ContainerDrawer,
  Title
} from '../../../../GlobalStyledComponents'
import Input from '../../../../components/Input'
import SearUser from '../../../../components/SearchUser'
import UsersSelected from '../../../../components/UsersSelected'
import { Section, InputsGp } from './StyledComponents'
import searchHelper from '../../../../helpers/searchUser'
import Button from '../../../../components/Button'
import returnData from './utils/returnData'
import create from '../../../../helpers/create'

interface IProps {
  actionClose: any
  setChange: any
}

const AddFolder: React.FC<IProps> = ({ actionClose, setChange }) => {
  const [name, setName] = useState('')
  const [admins, setAdmins] = useState([])
  const [members, setMembers] = useState([])
  const [searchAdmin, setSearchAdmin] = useState('')
  const [searchMember, setSearchMember] = useState('')
  const [memberResult, setMemberResult] = useState([])
  const [admResult, setAdmResult] = useState([])
  const [loading, setLoading] = useState(false)

  const resetStates = () => {
    setName('')
    setAdmins([])
    setMembers([])
    setSearchAdmin('')
    setSearchMember('')
    setMemberResult([])
    setAdmResult([])
  }

  useEffect(() => {
    return () => {
      resetStates()
    }
  }, [])

  const searchUser = async (value: string, setHook: any, setResult: any) => {
    setHook(value)
    await searchHelper(value, setResult)
  }

  const handleSubmit = async () => {
    const data = returnData(name, admins, members)
    await create(
      data,
      '/folder',
      'Pasta adicionada com sucesso.',
      setLoading
    ).finally(() => {
      actionClose()
      resetStates()
      setChange(true)
    })
  }

  return (
    <ContainerDrawer>
      <Title>Adicionar Pasta</Title>
      <Section>
        <Input
          label="Nome da pasta"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputsGp>
          <SearUser
            label="Membros da pasta"
            data={memberResult}
            arraySelected={members}
            setData={setMembers}
            value={searchMember}
            onChange={(e) =>
              searchUser(e.target.value, setSearchMember, setMemberResult)
            }
          />
          <UsersSelected
            users={members}
            setState={setMembers}
            label="Membros adicionados"
            emptyMessage="Nenhum membro adicionado ainda."
          />
          <SearUser
            label="Administradores"
            data={admResult}
            arraySelected={admins}
            setData={setAdmins}
            value={searchAdmin}
            onChange={(e) =>
              searchUser(e.target.value, setSearchAdmin, setAdmResult)
            }
          />
          <UsersSelected
            users={admins}
            setState={setAdmins}
            label="Administradores selecionados"
            emptyMessage="Nenhum administrador selecionado ainda."
          />
        </InputsGp>
      </Section>
      <BtnGroup>
        <Button className="secondary" label="Cancelar" onClick={() => {}} />
        <Button
          className="primary"
          label="Adicionar"
          onClick={handleSubmit}
          iconEnd={<MdChevronRight size={24} />}
          loading={loading}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default AddFolder
