const user = {
  account: '',
  acessCode: '',
  active: false,
  actived: false,
  address: '',
  addressJuridic: '',
  admissionDate: '',
  agency: '',
  atuationArea: '',
  avatar: '',
  bank: '',
  cep: '',
  city: '',
  cityJuridic: '',
  cnpj: '',
  company: '',
  complement: '',
  complementJuridic: '',
  contractingModel: '',
  corporateName: '',
  country: '',
  course: '',
  cpf: '',
  cpfJuridic: '',
  createdAt: '',
  ctps: '',
  ctpsSerie: '',
  dateBirth: '',
  department: '',
  district: '',
  districtJuridic: '',
  documentCnpj: '',
  documentCollege: '',
  documentRg: '',
  documentRgBack: '',
  documentSchollar: '',
  dominantHand: '',
  email: '',
  finalized: false,
  foodRestriction: '',
  graduate: false,
  graduateYear: '',
  half: '',
  hoursPerDay: 0,
  idRg: '',
  instituition: '',
  intern: false,
  maritalStatus: '',
  memberId: '',
  name: '',
  nameJuridic: '',
  office: '',
  phone: '',
  pis: '',
  requestAcademic: false,
  requestPJ: false,
  rg: '',
  role: '',
  salary: '',
  sizeShirt: '',
  sizeShoes: '',
  typeBlood: '',
  typeShirt: '',
  uf: '',
  ufJuridic: '',
  updatedAt: '',
  usernameInsta: '',
  vcard: '',
  verification: '',
  verified: false,
  workInHome: false,
  workingInHome: false,
  workingModel: '',
  hierarchy: '',
  technicalLevel: '',
  manager: [],
  technicalReference: [],
  yearOfEntry: '',
  _id: ''
}

export default user
