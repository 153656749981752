import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 3.5em;
  flex-direction: column;
`

export const Text = styled.span`
  font-size: 20px;
  color: #bfbfbf;
  text-align: center;
`
