import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.2em;
    margin-right: 0.2em;
    font-weight: 600;
  }
`

export const Box = styled.div`
  width: auto;
  height: 25px;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  background: ${(props) => props.theme.box};
  color: ${(props) => props.theme.basicText};
  margin-left: 0.2em;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Result = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: #757575;
`
