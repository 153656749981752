import styled from 'styled-components'
import { BoxProps } from '../types'

export const Label = styled.label``

export const RadioInput = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Checked = styled.div`
  background: ${(props) => props.theme.primary_blue};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

export const ValueTitle = styled.div`
  display: flex;
  padding: 10px 0;
`
export const Title = styled.span`
  margin-left: 10px;
  text-transform: capitalize;
`

export const BoxInput = styled.div<BoxProps>`
  display: flex;
  flex-direction: ${(props) => props.display};

  &.row > div:last-child {
    margin-left: 1em;
  }
`
