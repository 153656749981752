import { toast } from 'react-toastify'
import api from '../../../services/api'
// import api_hours from '../../../services/api_hours'

// const _ApplicationId = process.env.REACT_APP_APLICATION_ID

// Falta endpoint
/* const editInHoursApi = async (data: any) => {
  await api_hours.patch('test', {
    data,
    _ApplicationId
  })
} */

const editUser = async (data: any, id: string, message: string) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/users/update/${id}`, data)
      .then((res) => {
        // editInHoursApi(data).then((_res) => {
        //   resolve({
        //     user: res.data,
        //     _res
        //   })

        // })
        resolve(res.data)
        toast.success(message)
      })
      .catch((error) => {
        reject(error)
        toast.error('Ops... Encontramos um problema por aqui, tente novamente.')
      })
  })
}

export default editUser
