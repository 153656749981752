const filterCorrections = [
  {
    value: 'name',
    label: 'Nome'
  },
  {
    value: 'createdAt',
    label: 'Solicitado em'
  }
]

const vocationsFilter = [
  {
    value: 'name',
    label: 'Nome'
  },
  {
    value: 'createdAt',
    label: 'Solicitado em'
  },
  {
    value: 'type',
    label: 'Tipo'
  }
]

const reasonsFilter = [
  {
    value: 'name',
    label: 'Nome'
  },
  {
    value: 'createdAt',
    label: 'Enviado em'
  },
  {
    value: 'type',
    label: 'Tipo'
  }
]

const StatusCorrection = [
  {
    value: 'name',
    label: 'Nome'
  },
  {
    value: 'status',
    label: 'Status'
  }
]

const StatusFilter = [
  {
    value: 'name',
    label: 'Nome'
  },
  {
    value: 'status',
    label: 'Status'
  },
  {
    value: 'type',
    label: 'Tipo'
  }
]

export {
  filterCorrections,
  vocationsFilter,
  reasonsFilter,
  StatusFilter,
  StatusCorrection
}
