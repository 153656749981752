import React, { useEffect, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { BtnGroup, Title, ContainerDrawer } from '../../StyledComponents'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import SearchUser from '../../../../components/SearchUser'
import UsersSelected from '../../../../components/UsersSelected'
import useDisabled from '../../../../hooks/useDisabled'
import create from '../../../../helpers/create'

import { ContainerTop, ContainerBottom } from './StyledComponents'
import searchHelper from '../../../../helpers/searchUser'
import getData from './utils/getData'
import { IProps } from './types'

const AddGroup: React.FC<IProps> = ({ actionClose, data, setChange }) => {
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [userName, setUserName] = useState('')
  const [result, setResult] = useState([])
  const [colabs, setColabs] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [group, setGroup] = useState<any>({})

  useEffect(() => {
    setGroup({ ...data })
    setName(data.name)
    setNumber(data.number)
    setColabs(data.colabs)
    return () => {
      setGroup({})
      setName('')
      setNumber('')
      setColabs([])
    }
  }, [data])

  const [disabled] = useDisabled([name])

  const handleAdd = async () => {
    const item = getData({ name, number, colabs })
    await create(
      item,
      `/group/update/${group._id}`,
      'Grupo cadastrado com sucesso!',
      setLoading
    )
      .then((_) => setChange(true))
      .finally(() => actionClose())
  }

  const searchUser = async (value: string) => {
    setUserName(value)
    await searchHelper(value, setResult)
  }

  return (
    <ContainerDrawer>
      <Title>Editar grupo</Title>
      <ContainerTop>
        <Input
          label="Nome do grupo"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          label="Número"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          disabled
        />
      </ContainerTop>

      <ContainerBottom>
        <SearchUser
          data={result}
          arraySelected={colabs}
          setData={setColabs}
          value={userName}
          onChange={(e) => searchUser(e.target.value)}
        />
        {colabs && <UsersSelected users={colabs} setState={setColabs} />}
      </ContainerBottom>

      <BtnGroup>
        <Button className="secondary" label="Cancelar" onClick={actionClose} />
        <Button
          className="primary"
          label="Adicionar"
          onClick={handleAdd}
          iconEnd={<MdChevronRight size={21} />}
          disabled={disabled}
          loading={loading}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default AddGroup
