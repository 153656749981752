import React, { useState, useEffect } from 'react'
import { Tooltip } from 'antd'
import RadioInputs from '../../../RadioInputs'

import { List, ListItem } from './StyledComponents'
import { CSSProperties } from '@material-ui/styles'

interface IProps {
  title?: string
  size?: string
  old: any[]
  setContainDontAccept: any
  editableData: any[]
  setEditableData: any
}

const ListFC: React.FC<IProps> = ({
  title,
  size,
  old,
  setContainDontAccept,
  editableData,
  setEditableData
}) => {
  const [oldData, setOldData] = useState<any[]>([])

  useEffect(() => {
    setOldData([...old])
  }, [old])

  const procurarIndice = (
    arraySearch: any[],
    atributo: string,
    valor: any
  ): any[] => {
    var cont = 0
    var indices = []
    for (var i in arraySearch) {
      var row = arraySearch[i]
      if (row[atributo] === valor) {
        indices.push(cont)
      }
      cont++
    }
    return indices
  }

  useEffect(() => {
    const acceptIn = procurarIndice(editableData, 'acceptChangeIn', false)
    const acceptOut = procurarIndice(editableData, 'acceptChangeOut', false)
    acceptIn.length > 0 || acceptOut.length > 0
      ? setContainDontAccept(true)
      : setContainDontAccept(false)
  }, [editableData, setContainDontAccept])

  const overlayInnerStyle: CSSProperties = {
    background: '#fff',
    color: '#000',
    padding: '10px'
  }

  const overlayStyle: CSSProperties = {
    background: '#fff'
  }

  const aprove = (item: any, value: any, key: string) => {
    setEditableData(
      editableData.map((a) => (a === item ? { ...a, [key]: value } : a))
    )
  }

  return (
    <List className={size}>
      {title && <label className="title-list">{title}</label>}
      <ListItem className="header">
        <span className="title">Tipo</span>
        <span className="title">Constava</span>
        <span className="title">Correção</span>
        <span className="title">Motivo</span>
        <span className="title">Ação</span>
      </ListItem>
      {editableData.map((i, index) => (
        <>
          {i.changedIn && (
            <ListItem>
              <span>Entrada</span>
              <span>{oldData[index] ? oldData[index].in : ''}</span>
              {i.in ? <span>{i.in}</span> : <div />}
              {i.reasonIn ? (
                <Tooltip
                  placement="bottom"
                  title={i.reasonIn}
                  overlayInnerStyle={overlayInnerStyle}
                  overlayStyle={overlayStyle}
                >
                  <span className="reason">{i.reasonIn}</span>
                </Tooltip>
              ) : (
                <div />
              )}
              <RadioInputs
                options={[
                  { value: true, label: 'Aprovar' },
                  { value: false, label: 'Negar' }
                ]}
                value={i.acceptChangeIn}
                setValue={(value: string) => aprove(i, value, 'acceptChangeIn')}
              />
            </ListItem>
          )}
          {i.changedOut && (
            <ListItem>
              <span>Saída</span>
              <span>{oldData[index] ? oldData[index].out : ''}</span>
              {i.out ? <span>{i.out}</span> : <div />}
              {i.reasonOut ? (
                <Tooltip
                  placement="bottom"
                  title={i.reasonOut}
                  overlayInnerStyle={overlayInnerStyle}
                  overlayStyle={overlayStyle}
                  autoAdjustOverflow
                >
                  <span className="reason">{i.reasonOut}</span>
                </Tooltip>
              ) : (
                <div />
              )}
              <div className="options">
                <RadioInputs
                  options={[
                    { value: true, label: 'Aprovar' },
                    { value: false, label: 'Negar' }
                  ]}
                  value={i.acceptChangeOut}
                  setValue={(value: string) =>
                    aprove(i, value, 'acceptChangeOut')
                  }
                />
              </div>
            </ListItem>
          )}
        </>
      ))}
    </List>
  )
}

export default ListFC
