import React from 'react'

import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import Template from '../../components/Template'

import Pending from './Pending'
import Executed from './Executed'

import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

const Requests: React.FC = () => {
  return (
    <Template active="requests">
      <Container>
        <PageTitle>Solicitações</PageTitle>
        <Section>
          <Tabs>
            <Tab title="Pendentes">
              <Pending />
            </Tab>
            <Tab title="Executadas">
              <Executed />
            </Tab>
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default Requests
