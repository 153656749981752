import styled from 'styled-components'

// import { IProps } from '../types'

export const Header = styled.div`
  height: 55px;
  width: 100%;
  background: #f5f7f9;
  display: grid;
  grid-template-columns: repeat(5, 150px);
  padding: 0 20px;
  align-items: center;
  gap: 10%;
`
