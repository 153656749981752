import React from 'react'
import { Avatar } from 'antd'
import AvatarFC from '../Avatar'
// import { Container } from './styles';
import './styles.css'

const { Group } = Avatar

interface IProps {
  users: any[]
}

const AvatarWithGroup: React.FC<IProps> = ({ users }) => {
  return (
    <>
      {users.length > 1 ? (
        <Group className="teste" maxCount={4}>
          {users.map((i) => (
            <AvatarFC
              data={{ name: i.name, avatar: i.avatar }}
              background="00b7ef"
              color="f6f6f6"
              key={i.avatar}
            />
          ))}
        </Group>
      ) : (
        users.map((i) => (
          <AvatarFC
            data={{ name: i.name, avatar: i.avatar }}
            background="00b7ef"
            color="f6f6f6"
            key={i.avatar}
          />
        ))
      )}
    </>
  )
}

export default AvatarWithGroup
