import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import ButtonHeader from '../../../../components/FilterHeader'
import { Actions, ButtonAction } from '../../../../GlobalStyledComponents'
import deleteFn from '../../../../helpers/delete'
import { Item, List } from '../../StyledComponents'
import { IProps } from './types'
import Drawer from '../../../../components/Drawer'
import Popconfirm from '../../../../components/PopConfirm'
import EditGroup from '../EditGroup'

const officesFilters = [
  { label: 'Nº', value: '' },
  { label: 'Nome do grupo', value: '' },
  { label: 'Número de membros', value: '' },
  { label: 'Ações', value: '' }
]

const GroupList: React.FC<IProps> = ({ result, setChange }) => {
  const [data, setData] = useState<any[]>([])
  const [canEdit, setCanEdit] = useState(false)
  const [dataEdit, setDataEdit] = useState({})

  useEffect(() => {
    setData(result)
  }, [result])

  const handleDelete = async (id: string, index: number) => {
    await deleteFn(id, '/group', 'Grupo deletado com sucesso.').then((_) => {
      const arr = [...data]
      arr.splice(index, 1)
      setData([...arr])
    })
  }

  const handleEdit = (item: any) => {
    setDataEdit(item)
    setCanEdit(true)
  }

  const close = () => {
    setDataEdit({})
    setCanEdit(false)
  }

  return (
    <List>
      <Drawer width={952} visible={canEdit} onClose={close}>
        <EditGroup data={dataEdit} actionClose={close} setChange={setChange} />
      </Drawer>
      <Item>
        {officesFilters.map((i) => (
          <ButtonHeader label={i.label} clickable={!!i.value} />
        ))}
      </Item>
      {data.map((i, index) => (
        <Item key={i.number}>
          <span>{i.number}</span>
          <span>{i.name}</span>
          <span>{i.numberOfMembers}</span>
          <Actions>
            <Tooltip placement="bottom" title="Editar">
              <ButtonAction onClick={() => handleEdit(i)}>
                <MdEdit size={21} className="tools" />
              </ButtonAction>
            </Tooltip>

            <Tooltip placement="bottom" title="Apagar">
              <Popconfirm
                placement="top"
                title="Deseja mesmo excluir este item?"
                onConfirm={() => handleDelete(i._id, index)}
                okText="Sim"
                cancelText="Não"
              >
                <ButtonAction type="button">
                  <MdDelete size={21} className="delete" />
                </ButtonAction>
              </Popconfirm>
            </Tooltip>
          </Actions>
        </Item>
      ))}
    </List>
  )
}

export default GroupList
