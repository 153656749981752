import React, { InputHTMLAttributes } from 'react'
import { MdSearch } from 'react-icons/md'

import { InputContainer, Input } from './StyledComponents'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string
}

const SearchInput: React.FC<IProps> = ({ placeholder, ...rest }) => {
  return (
    <InputContainer>
      <MdSearch size={25} />
      <Input placeholder={placeholder} {...rest} />
    </InputContainer>
  )
}

export default SearchInput
