import React from 'react'
import { Drawer } from 'antd'

// import { Container } from './styles';

interface DrawerProps {
  visible: boolean
  width: number | string
  onClose: any
}

const DrawerComponent: React.FC<DrawerProps> = ({
  children,
  visible,
  width,
  onClose
}) => {
  return (
    <Drawer visible={visible} width={width} onClose={onClose}>
      {children}
    </Drawer>
  )
}

export default DrawerComponent
