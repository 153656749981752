import React from 'react'

import { ColabData, Data, DataSpan } from './DisableUserStyles'
import { PropsUser } from '../../types/index'

const ColabDataComponent: React.FC<PropsUser> = ({ user }) => {
  return (
    <ColabData>
      <Data>
        <DataSpan>Colaborador:</DataSpan>
        <DataSpan>{user.name}</DataSpan>
      </Data>
      <Data>
        <DataSpan>Matrícula:</DataSpan>
        <DataSpan>{user.acessCode}</DataSpan>
      </Data>
      <Data>
        <DataSpan>Departamento:</DataSpan>
        <DataSpan>{user.department}</DataSpan>
      </Data>
      <Data>
        <DataSpan>Cargo:</DataSpan>
        <DataSpan>{user.office}</DataSpan>
      </Data>
    </ColabData>
  )
}

export default ColabDataComponent
