import { toast } from 'react-toastify'
import api from '../services/api'
import { checkStatusCode } from '../utils/checkStatusCode'

const checkLoading = (setHook: any, state: boolean) => {
  if (setHook) {
    setHook(state)
  }
}

const create = async (
  data: any,
  url: string,
  message?: string,
  setLoading?: any
) => {
  return new Promise((resolve, reject) => {
    checkLoading(setLoading, true)
    api
      .post(url, data)
      .then((res) => {
        if (message) {
          toast.success(message)
        }
        resolve(res.data)
      })
      .catch((err) => {
        checkStatusCode(err)
        reject(err)
      })
      .finally(() => checkLoading(setLoading, false))
  })
}

export default create
