import { UserProps } from '../../../../../interfaces/user'

type Return = {
  avatar: string
  name: string
  memberId: string
}
const formatUserData = (users: UserProps[]): Return[] => {
  return users.map((user) => ({
    avatar: user.avatar,
    name: user.name,
    memberId: user.memberId
  }))
}

export default formatUserData
