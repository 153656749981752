import React, { useEffect, useState } from 'react'
import { UserProps } from '../../interfaces/user'
import { returnAvatar } from '../../services/getAvatarApi'

import { Container } from './StyledComponents'

interface IProps {
  data: UserProps
}

const AvatarBox: React.FC<IProps> = ({ data }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const getAvatar = async () => {
      // @ts-ignore
      await returnAvatar(data).then((res) => setUrl(res))
    }
    getAvatar()
    return () => {
      setUrl('')
    }
  }, [data])

  return <Container style={{ backgroundImage: `url(${url})` }} />
}

export default AvatarBox
