import set from '../../../utils/dateInterval'
import { currentMontLastFirstDay } from '../../../utils/setFistAndLastDay'

const setStates = (from: string, to: string, setPast: any, setNow: any) => {
  setPast(from)
  setNow(to)
}

const setDate = (value: string, setPast: any, setNow: any) => {
  let day = 0
  switch (value) {
    case 'Últimos 7 dias':
      day = 7
      break
    case 'Últimos 15 dias':
      day = 15
      break
    case 'Esse mês':
      day = 30
      break
    case 'Mês anterior':
      day = 60
      break
  }

  if (day === 7 || day === 15) {
    const { now, past } = set(day)
    setStates(now, past, setPast, setNow)
  }

  if (day >= 30) {
    const { first, last } = currentMontLastFirstDay(day)
    setStates(first, last, setPast, setNow)
  }
}

export default setDate
