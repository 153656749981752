const formatTerms = (data: string[]) => {
  const arr = [...data]
  const group = ['contentTerm', 'imageAndVoice', 'confidentiality']
  let equal = 0
  data.forEach((item) => {
    group.forEach((item2) => {
      if (item === item2) {
        equal += 1
      }
    })
  })
  if (equal >= 3) {
    group.forEach((item) => arr.splice(arr.indexOf(item), 1))
    arr.push('all_terms')
  }
  return arr
}

export default formatTerms
