import React from 'react'

import { ButtonContainer, ButtonContainer2 } from './StyledComponents'
import { ButtonProps } from './types'

import Icons from './icons'

const FilterHeader: React.FC<ButtonProps> = ({
  label,
  clickable = false,
  clickTop,
  clickBotttom,
  sortBy,
  value
}) => {
  return clickable ? (
    <ButtonContainer>
      {label}{' '}
      <Icons
        clickBottom={clickBotttom}
        clickTop={clickTop}
        sortBy={sortBy}
        value={value}
      />
    </ButtonContainer>
  ) : (
    <ButtonContainer2>{label}</ButtonContainer2>
  )
}

export default FilterHeader
