import React, { useState, memo } from 'react'
import { MdAdd } from 'react-icons/md'
import SearchInput from '../../components/SearchInput'
import Button from '../../components/Button'
import { TopSection, Buttons } from './StyledComponents'
import Drawer from '../../components/Drawer'
import AddFolder from './components/AddFolder'
import FoldersList from './components/FoldersList'
import useApi from '../../hooks/useApi'
import Folder from './components/Folder'
import Pagination from '../../components/Pagination'
import AddFile from './components/AddFile'
// import { Container } from './styles';

const Files: React.FC = memo(() => {
  const [search, setSearch] = useState('')
  const [canAddFolder, setCanAddFolder] = useState(false)
  const [change, setChange] = useState(false)
  const [openItem, setOpenItem] = useState(false)
  const [openData, setOpenData] = useState({})
  const [page, setPage] = useState(1)
  const [addFileIsOpen, setAddFileIsOpen] = useState(false)
  const openDrawer = () => {
    setCanAddFolder(true)
  }

  const [result, extra] = useApi(
    `/folder?filter=${search}&fields=name&page=${page}&limit=15&sort=name&order=1`,
    'get',
    change,
    setChange
  )

  const actionReturn = () => {
    setOpenItem(false)
    setOpenData({})
  }

  return (
    <>
      <Drawer
        visible={addFileIsOpen}
        width={920}
        onClose={() => setAddFileIsOpen(false)}
      >
        <AddFile actionClose={() => setAddFileIsOpen(false)} />
      </Drawer>
      <Drawer
        visible={canAddFolder}
        onClose={() => setCanAddFolder(false)}
        width={952}
      >
        <AddFolder
          actionClose={() => setCanAddFolder(false)}
          setChange={setChange}
        />
      </Drawer>
      <TopSection>
        <Buttons>
          <Button
            className="primary"
            width="206px"
            onClick={() => setAddFileIsOpen(true)}
            iconStart={<MdAdd />}
            label="Adicionar arquivo"
            style={{ marginTop: '2rem', marginBottom: '2rem', float: 'right' }}
          />
          <Button
            className="secondary"
            width="206px"
            onClick={openDrawer}
            iconStart={<MdAdd />}
            label="Criar pasta"
            style={{ marginTop: '2rem', marginBottom: '2rem', float: 'right' }}
          />
        </Buttons>
        <SearchInput
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </TopSection>

      {!openItem ? (
        <FoldersList
          data={result}
          setOpenData={setOpenData}
          setOpenItem={setOpenItem}
          setChange={setChange}
        />
      ) : (
        <Folder data={openData} actionReturn={actionReturn} />
      )}
      {extra.totalPages > 1 && !openItem && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
})
export default Files
