const arrayTerms = [
  {
    id: 0,
    value: 'contentTerm',
    label: 'Termo de adesão de conteúdo produzido.'
  },
  { id: 1, value: 'imageAndVoice', label: 'Termo de imagem, voz e nome.' },
  { id: 2, value: 'confidentiality', label: 'Termo de confidencialidade.' },
  {
    id: 3,
    value: 'homeOffice',
    label: 'Home office provisório.'
  },
  {
    id: 4,
    value: 'workInHome',
    label: 'Aditivo de contrato de trabalho - home office.'
  }
]

export default arrayTerms
