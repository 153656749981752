import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        data-name="Subtra\xE7\xE3o 6"
        d="M12 14H2a2 2 0 01-2-2V2a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2zM3.678 4.345a.443.443 0 00-.443.442v4.425a.443.443 0 00.443.442h5.309a.443.443 0 00.443-.442V7.664l1.77 1.769V4.567L9.43 6.336V4.787a.443.443 0 00-.443-.442z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgComponent
