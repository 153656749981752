import { menuPrincipal } from './Menu'
import { menuBranding } from './MenuBranding'
import { menuManager } from './MenuManager'

type MenuProps = {
  darkmode: boolean
  role: string
}

const reuturnMenu = ({ darkmode, role }: MenuProps) => {
  const menu_1 = menuPrincipal({ darkmode })
  const menu_2 = menuBranding({ darkmode })
  const menu_3 = menuManager({ darkmode })

  const menus: any = {
    admin: menu_1,
    branding: menu_2,
    manager: menu_3
  }

  return menus[role]
}

export { reuturnMenu }
