import styled from 'styled-components'

interface IProps {
  marginLeft?: string
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;

  margin-left: ${(props) => props.marginLeft};
`
