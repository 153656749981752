import React, { memo, useEffect, useState } from 'react'
import validateArray from '../../utils/validateArray'
import { MdSearch } from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'
import CheckItem from './CheckItem'
import { Container, Inputcontainer, List } from './StyledComponents'
import { IProps } from './types'

const SearchUser: React.FC<IProps> = memo(
  ({
    data,
    arraySelected,
    setData,
    label = 'Buscar colaboradores',
    placeholder = 'Selecione',
    oneUser = false,
    setOneName,
    ...rest
  }) => {
    const checkOne = (user: any) => {
      const arr = [...arraySelected]
      arr.splice(0, 1)
      arr.push(user)
      setOneName(user.name)
      return arr
    }

    const [result, setResult] = useState<any[]>([])
    useEffect(() => {
      setResult([...data])

      return () => {
        setResult([])
      }
    }, [data])

    const handleSelect = (user: any) => {
      const arr = oneUser ? checkOne(user) : validateArray(arraySelected, user)
      setData([...arr])
    }

    const handleClickAway = () => {
      setResult([])
    }

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Container>
          <label>
            {label}
            <Inputcontainer>
              <input type="text" placeholder={placeholder} {...rest} />
              <MdSearch size={21} />
            </Inputcontainer>
          </label>
          {result.length > 0 && (
            <List>
              {result.map((item) => (
                <CheckItem
                  item={item}
                  handleClick={() => handleSelect(item)}
                  array={arraySelected}
                />
              ))}
            </List>
          )}
        </Container>
      </ClickAwayListener>
    )
  }
)

export default SearchUser
