import { useEffect, useState } from 'react'

const usePercent = (loaded: number, total: number): number[] => {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    const x = Math.round((loaded * 100) / total)
    setPercent(x)
  }, [loaded, total])

  return [percent]
}

export default usePercent
