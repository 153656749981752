import styled from 'styled-components'

export const ButtonContainer = styled.div`
  font-size: 15px;
  color: #757575;
  word-break: keep-all;
  text-align: center;
  display: flex;
  align-items: center;
`

export const ButtonContainer2 = styled.div`
  font-size: 15px;
  color: #757575;
  word-break: keep-all;
  text-align: start;
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;

  svg.clicked {
    color: ${(props) => props.theme.primary_blue};
  }
`
