import formatBytes from '../../../utils/formatBytes'

const setData = (data: any): any => {
  const {
    key,
    title,
    notifyUsers,
    requiredViews,
    tag,
    size,
    public: asPublic,
    folder,
    filetype
  } = data
  console.log(data)
  return {
    name: key,
    title,
    path: key,
    size: formatBytes(size),
    area: [...tag],
    keys: ['teste'],
    requiredViews,
    notifyUsers,
    type: filetype,
    public: asPublic,
    folder
  }
}

export default setData
