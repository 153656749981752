import React from 'react'
import { BtnGroup, ContainerDrawer, Title } from '../../GlobalStyledComponents'
import Button from '../Button'
import { Container, Infos } from './StyledComponents'
import List from './components/ListActions'
import formatData from '../../utils/formatData'

interface IProps {
  actionClose: any
  containResult?: boolean
}

const AcceptVocation: React.FC<IProps> = ({
  actionClose,
  containResult = false
}) => {
  const headers = [
    { label: 'Tipo' },
    { label: 'Período' },
    { label: 'Data' },
    { label: 'Saldo de horas atual' },
    { label: 'Validação do gestor' }
  ]

  const mock = [
    {
      type: 'Folga',
      period: '2 dias',
      date: 'teste',
      balance: '-3h 12min',
      managerApproved: false,
      status: 'Reprovado'
    }
  ]

  const buttons = [
    {
      label: 'Cancelar',
      className: 'secondary',
      width: '164px',
      onclick: () => actionClose()
    },
    {
      label: 'Salvar',
      className: 'primary',
      width: '164px',
      onclick: () => {}
    }
  ]

  return (
    <ContainerDrawer>
      <Title>Solicitação de folga/férias</Title>
      <Container>
        <Infos>
          <div>
            <span>Solicitante:</span>
            <span>Paulo</span>
          </div>
          <div>
            <span>Solicitado em:</span>
            <span>{formatData(new Date())}</span>
          </div>
          {!containResult && (
            <div>
              <span>Data a ser ajustada</span>
              <span>{formatData(new Date())}</span>
            </div>
          )}
          {containResult && (
            <>
              <div>
                <span>Correção referente ao dia:</span>
                <span>{formatData(new Date())}</span>
              </div>
              <div>
                <span>Corrigido em:</span>
                <span>{formatData(new Date())}</span>
              </div>
            </>
          )}
        </Infos>
        <List titles={headers} data={mock} containResult={containResult} />
      </Container>
      {!containResult && (
        <BtnGroup>
          {buttons.map((i) => (
            <Button
              label={i.label}
              className={i.className === 'primary' ? 'primary' : 'secondary'}
              onClick={() => i.onclick()}
            />
          ))}
        </BtnGroup>
      )}
    </ContainerDrawer>
  )
}

export default AcceptVocation
