import styled from 'styled-components'

export const CheckItem = styled.button`
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border: 1px solid #cecece;

  &.marked {
    background-color: ${(props) => props.theme.primary_blue};
    border: 1px solid ${(props) => props.theme.primary_blue};
  }

  svg {
    stroke-width: 3px;
  }
`
