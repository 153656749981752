import React, { useCallback, useState } from 'react'

import SearchInput from '../../components/SearchInput'
import Pagination from '../../components/Pagination'
import { TopRigth } from './StyledComponents'
import InativeList from './components/InativeList'
import useApi from '../../hooks/useApi'
import debounce from 'lodash.debounce'

const Colabs: React.FC = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [change, setChange] = useState(false)
  const [endSearch, setEndSearch] = useState('')

  const [result, extra] = useApi(
    `/inative?filter=${endSearch}&fields=name,cpf,accessCode&page=${page}&limit=15&sort=createdAt&order=-1`,
    'get',
    change,
    setChange
  )

  const actionClose = () => {
    setChange(true)
  }

  // eslint-disable-next-line
  const verify = useCallback(
    debounce((res) => {
      setEndSearch(res)
    }, 500),
    []
  )

  const handleSearch = (value: string) => {
    setSearch(value)
    verify(value)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <>
      <TopRigth>
        <SearchInput
          placeholder="Pesquisar por nome, CPF ou matrícula"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </TopRigth>
      <InativeList result={result} actionClose={actionClose} />
      {extra.totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Colabs
