import styled from 'styled-components'

export const List = styled.ul`
  svg.folder {
    color: ${(props) => props.theme.primary_blue};
  }
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 25px 15px;

  &.header {
    background: #f5f7f9;
    span {
      color: #757575;
    }
  }
`

export const Infos = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 200px);
  cursor: pointer;
`
