import cepPromise from 'cep-promise'

type ReturnValidator = {
  city: string
  state: string
  country: string
}

const cepValidator = async (data: {
  cep: string
}): Promise<ReturnValidator> => {
  return new Promise((resolve, reject) => {
    const { cep } = data
    if (cep !== '' && cep !== '_____-___') {
      cepPromise(cep).then(
        (response: { city: any; state: any }) => {
          const { city, state } = response
          resolve({ city, state, country: 'Brasil' })
        },
        (error: any) => {
          reject(error)
        }
      )
    }
  })
}

export default cepValidator
