import React, { memo } from 'react'
import { reuturnMenu } from './components/Menus'
import {
  Menu,
  /*MoreContainer,*/ MenuItem,
  AnimatedBar,
  TextBtn
} from './styles'

import Context from '../../context/Global'
import { Link } from 'react-router-dom'

interface OptionsProps {
  minimized: boolean
  darkmode: boolean
  active: string
  animated: boolean
}

const OptionsSide: React.FC<OptionsProps> = memo(
  ({ minimized, darkmode, active, animated }) => {
    const { state } = React.useContext(Context)
    const { profile } = state
    const { role: nRole } = profile

    const menu: any[] = React.useMemo(() => {
      return reuturnMenu({ role: nRole, darkmode })
    }, [nRole, darkmode])

    return (
      <Menu>
        {menu.map((item, index) => (
          <MenuItem
            key={index}
            className={
              active === item.id
                ? `active ${minimized ? 'minimized' : ''}`
                : `${minimized ? 'minimized' : ''}`
            }
          >
            <AnimatedBar className={active === item.id ? 'active' : ''} />
            <Link type="button" to={item.path}>
              {<item.Icon />}
              {!minimized && (
                <TextBtn
                  className={
                    active === item.id
                      ? `active ${minimized ? 'minimized' : ''} ${
                          animated ? 'animated' : ''
                        }`
                      : `${minimized ? 'minimized' : ''} ${
                          animated ? 'animated' : ''
                        }`
                  }
                >
                  {item.label}
                </TextBtn>
              )}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    )
  }
)

export default OptionsSide
