import React from 'react'
import SearchInput from '../../components/SearchInput'
import { TopRigthSearch } from '../../GlobalStyledComponents'

import { Section } from './StyledComponents'

import List from './components/List'

import { StatusFilter, StatusCorrection } from './constants/filters'

const Executed: React.FC = () => {
  const mock = [
    { name: 'Paulo Oliveira', createdAt: new Date(), status: 'Aprovado' }
  ]
  const mock3 = [
    {
      name: 'Paulo Oliveira',
      createdAt: new Date(),
      type: 'Férias',
      status: 'Reprovado'
    },
    {
      name: 'Paulo Oliveira',
      createdAt: new Date(),
      type: 'Folga',
      status: 'Aprovado'
    }
  ]

  const mock4 = [
    {
      name: 'Paulo Oliveira',
      createdAt: new Date(),
      type: 'Falta',
      status: 'Aprovado'
    },
    {
      name: 'Paulo Oliveira',
      createdAt: new Date(),
      type: 'Abono',
      status: 'Aprovado'
    }
  ]

  return (
    <>
      <TopRigthSearch>
        <SearchInput placeholder="Pesquisar" />
      </TopRigthSearch>
      <Section>
        <List
          title="Ajuste de ponto"
          titles={StatusCorrection}
          data={mock}
          containResult
        />
        <div>
          <List
            title="Férias/folga"
            titles={StatusFilter}
            data={mock3}
            className="trio"
            containResult
          />
          <List
            title="Faltas justificadas/abonos"
            titles={StatusFilter}
            data={mock4}
            className="trio"
            containResult
          />
        </div>
      </Section>
    </>
  )
}

export default Executed
