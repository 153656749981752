const format = (i: any) => {
  return {
    name: i.name,
    memberId: i.memberId,
    avatar: i.avatar
  }
}

const returnIds = (arr: any[]) => {
  return arr.map((i) => i.memberId)
}

const returnData = (name: string, admins: any[], members: any[]) => {
  const arrAdm = admins.map((i) => format(i))
  const adminIds = returnIds(admins)
  const arrMembers = members.map((i) => format(i))
  const authorizedIds = returnIds(members)

  return {
    name,
    members: arrMembers,
    admins: arrAdm,
    adminIds: [...adminIds],
    authorizedIds
  }
}

export default returnData
