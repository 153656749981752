import api from './api'

export const returnAvatar = async (
  data: any,
  background: string,
  color: string
): Promise<string> => {
  const { name, avatar } = data
  const firstName = name.split(' ')[0]
  const lastName = name.split(' ').pop()

  const urlDefault = `https://avatar.oxro.io/avatar.svg?name=${firstName}+${lastName}&background=${background}&length=2&color=${color}`
  return new Promise((resolve, reject) => {
    if (!avatar) {
      resolve(urlDefault)
    } else {
      api
        .get(`/file/${avatar}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    }
  })
}
