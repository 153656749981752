import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const ListStyled = styled.ul`
  width: 100%;
  overflow: auto;
`

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  padding: 20px 10px;
  cursor: pointer;

  &.trio {
    grid-template-columns: repeat(3, 150px);
  }

  &.list {
    border-bottom: 1px solid #cecece;
  }

  &.list-header {
    background: #f5f7f9;
    padding: 10px;
  }

  span.approved {
    color: ${(props) => props.theme.green};
  }

  span.reproved {
    color: ${(props) => props.theme.red};
  }
`

export const Title = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    padding: 0;
    margin: 0;
    font-size: 15px;
    color: #757575;
  }
`
