import styled from 'styled-components'

export const ContainerDrawer = styled.div`
  padding: 0 4rem;
`

export const ColabData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem 0;
  border-bottom: 1px solid #cecece;
`

export const Data = styled.div``

export const DataSpan = styled.span`
  font-weight: 700;

  &:last-child {
    font-weight: initial;
    margin-left: 5px;
  }
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 190px;
  margin-top: 1.5rem;

  label:last-child {
    margin-top: 1.5rem;
  }
`
