import styled from 'styled-components'

export const Section = styled.div``

export const InputAndCheck = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  div.check {
    display: flex;
    align-items: center;
  }

  div.check span {
    margin-left: 10px;
    color: #757575;
  }
`
