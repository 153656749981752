import React, { useState } from 'react'
import EmptyWindow from '../../../../components/EmptyWindow'
import DocView from '../../../../assets/icons/DocView'
import Download from '../../../../assets/icons/Download'
import {
  List as ListItem,
  Item,
  Infos,
  DownloadAndView
} from '../../StyledComponents'

import PDFIcon from '../../../../assets/icons/PDF'
import ArticleIcon from '../../../../assets/icons/Article'
import formatData from '../../../../utils/formatData'
import { Tooltip } from 'antd'
import formatBytes from '../../../../utils/formatBytes'
import dowloadFile from '../../../../helpers/download'

interface IProps {
  result: any[]
}

interface IconProps {
  format: string
}

const Icon: React.FC<IconProps> = ({ format }) => {
  switch (format) {
    case 'pdf':
      return <PDFIcon />
    default:
      return <ArticleIcon />
  }
}

const List: React.FC<IProps> = ({ result }) => {
  const [progress, setProgress] = useState(0)

  const downloadItem = async (file: string) => {
    await dowloadFile(file, setProgress)
    console.log(progress)
  }

  return result.length > 0 ? (
    <ListItem>
      {result.map((i) => (
        <Item key={i.name}>
          <Infos>
            <Icon format={i.format} />
            <div>
              <span>{i.type}</span>
              <span>{i.format}</span>
              <span>{formatBytes(i.size)}</span>
              <span> Gerado em {formatData(i.createdAt)}</span>
            </div>
          </Infos>
          <DownloadAndView>
            <Tooltip title="Visualizar" placement="bottom">
              <button>
                <DocView />
              </button>
            </Tooltip>
            <Tooltip title="Download" placement="bottom">
              <button onClick={() => downloadItem(i.file)}>
                <Download />
              </button>
            </Tooltip>
          </DownloadAndView>
        </Item>
      ))}
    </ListItem>
  ) : (
    <EmptyWindow text="relatório pronto. Gere para visualiza-los aqui." />
  )
}

export default List
