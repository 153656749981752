import styled from 'styled-components'

export const Container = styled.div`
  height: 82px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 5rem;
  justify-content: space-between;
  z-index: 99;
`

export const Logo = styled.img``

export const UserHi = styled.span`
  font-weight: 700;
  color: ${(props) => props.theme.white};
`

export const Items = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-width: 140px;
  width: 100%;
`
