import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px 60px;
`

export const FlexSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PageTitle = styled.span`
  font-size: 20px;
`
export const Section = styled.section`
  margin-top: 2rem;
`
export const TopRigthSearch = styled.div`
  max-width: 600px;
  width: 100%;
  float: right;
`

export const FiltersSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`

export const InfoSearch = styled.div`
  height: 50px;
  width: 100%;
`

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 2rem;
  float: right;
  button:nth-child(1n + 1) {
    margin-right: 10px;
  }
`
export const Title = styled.span`
  font-size: 20px;
  line-height: 6rem;
`

export const ContainerDrawer = styled.div`
  padding: 0 4rem;

  div.div-padding {
    padding: 15px 0;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
`
export const ButtonAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(1n + 1) {
    margin-right: 10px;
  }

  svg.delete {
    color: ${(props) => props.theme.red};
  }
`

export const DataUser = styled.span`
  width: 150px;
  font-size: 14px;

  &.negative {
    color: ${(props) => props.theme.red};
  }

  &.positive {
    color: ${(props) => props.theme.green};
  }
`

export const DataSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  gap: 10%;
  padding: 20px 0;
  width: 100%;
  align-items: center;
`
