import styled from 'styled-components'

export const CheckUl = styled.ul`
  position: absolute;
  background: rgb(255, 255, 255);
  border-color: #cecece;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: auto;
  overflow: auto;
  position: absolute;
  width: 290px;
  z-index: 2;
`

export const CheckList = styled.li`
  align-items: center;
  display: flex;
  padding: 10px;
`

export const Label = styled.span`
  margin-left: 0.8rem;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

export const ButtonTop = styled.button`
  color: ${(props) => props.theme.primary_blue};
  padding: 5px;
`
