import React, { useContext } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import Context from '../../context/Global'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { MenuDrop } from '../../interfaces/menu_drop'

import DropDown from '../DropDown'
import LogoutIcon from '../../assets/icons/Logout'
import { Container } from './styles'

const Menu: React.FC<MenuDrop> = ({ observedOpen, open, handleOpen }) => {
  const { Logout } = useContext(Context)

  React.useEffect(() => {
    if (observedOpen) {
      handleOpen(false)
    }
  }, [observedOpen, handleOpen])

  const handleOpenMenu = (): void => handleOpen(!open)

  return (
    <ClickAwayListener onClickAway={() => handleOpen(false)}>
      <Container role="button" tabIndex={0} onClick={handleOpenMenu}>
        {open ? (
          <MdArrowDropUp size={30} color="#fff" />
        ) : (
          <MdArrowDropDown size={30} color="#fff" />
        )}
        <DropDown open={open}>
          <ul className="notifications">
            <li>
              <button onClick={() => Logout()}>
                <LogoutIcon />
                Logout
              </button>
            </li>
          </ul>
        </DropDown>
      </Container>
    </ClickAwayListener>
  )
}

export default Menu
