import React from 'react'

import { Container } from './styles'

interface DropDownProps {
  open: boolean
}

const DropDown: React.FC<DropDownProps> = ({ children, open }) => {
  return <Container className={open ? 'open' : ''}>{children}</Container>
}

export default DropDown
