import styled from 'styled-components'

export const Container = styled.div`
  width: 470px;
`

export const Label = styled.span`
  margin-left: 0.5em;
`

export const ContentSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &.double {
    grid-template-columns: repeat(2, 1fr);
  }

  &.four {
    grid-template-columns: repeat(4, 185px);
  }
`

export const Item = styled.div`
  display: flex;
  margin-top: 0.2em;
  width: 100%;
`
