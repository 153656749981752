import create from '../../../../../helpers/create'
import { registerUser } from '../../../../../services/api_hours'

const createUser = async (dt: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    registerUser({
      name: dt.name,
      email: dt.email,
      startDate: dt.admissionDate,
      role: dt.office,
      hours: Number(dt.hoursPerDay)
    })
      .then(async (data) => {
        const user = {
          ...dt,
          memberId: data.memberId,
          acessCode: data.code
        }
        await create(
          user,
          '/register',
          'Usuário registrado com sucesso'
        ).then((_) => resolve({ message: 'success' }))
      })
      .catch((err) => reject(err))
  })
}

export default createUser
