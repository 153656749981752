import React, { useCallback, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import SearchInput from '../../components/SearchInput'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import AddGroup from './components/AddGroup'
import Pagination from '../../components/Pagination'

import { TopRigthSearch } from './StyledComponents'
import List from './components/GroupsList'
import useApi from '../../hooks/useApi'
import debounce from 'lodash.debounce'

const Groups: React.FC = () => {
  const [canAdd, setCanAdd] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [change, setChange] = useState(false)
  const [endSearch, setEndSearch] = useState('')

  const [data, extra] = useApi(
    `/group/?filter=${endSearch}&fields=name&page=${page}&limit=15&sort=name&order=1`,
    'get',
    change,
    setChange
  )

  // eslint-disable-next-line
  const verify = useCallback(
    debounce((res) => {
      setEndSearch(res)
    }, 500),
    []
  )

  const handleSearch = (value: string) => {
    setSearch(value)
    verify(value)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <>
      <Drawer width={952} visible={canAdd} onClose={() => setCanAdd(false)}>
        <AddGroup actionClose={() => setCanAdd(false)} setChange={setChange} />
      </Drawer>
      <TopRigthSearch>
        <Button
          width="243px"
          className="primary"
          label="Cadastrar grupo"
          onClick={() => setCanAdd(true)}
          iconStart={<MdAdd size={20} />}
        />
        <SearchInput
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </TopRigthSearch>
      <List result={data} setChange={setChange} />
      {extra.totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Groups
