import React, { useState } from 'react'
import { MdDelete, MdEdit, MdFolder } from 'react-icons/md'
import { Tooltip } from 'antd'
import EditFolder from '../EditFolder'

import formatData from '../../../../utils/formatData'
import AvatarWGroup from '../../../../components/AvatarWithGroup'
import { List, ListItem, Infos } from './SyledComponents'
import deleteItem from '../../../../helpers/delete'
import Drawer from '../../../../components/Drawer'

interface IProps {
  data: any[]
  setOpenItem: any
  setOpenData: any
  setChange: any
}

const FoldersList: React.FC<IProps> = ({
  data,
  setOpenItem,
  setOpenData,
  setChange
}) => {
  const [canEditFolder, setCanEditFolder] = useState(false)
  const [folderToEdit, setFolderToEdit] = useState({})

  const hanleClickEditFolder = (item: any) => {
    setFolderToEdit(item)
    setCanEditFolder(true)
  }

  const handleOpenClick = (item: any) => {
    setOpenData(item)
    setOpenItem(true)
  }

  const handleDelete = async (id: string) =>
    await deleteItem(id, '/folder', 'Pasta excluída com sucesso.')

  return (
    <>
      <Drawer
        visible={canEditFolder}
        onClose={() => setCanEditFolder(false)}
        width={952}
      >
        <EditFolder
          actionClose={() => setCanEditFolder(false)}
          folder={folderToEdit}
          setChange={setChange}
        />
      </Drawer>
      <List>
        <ListItem className="header">
          <Infos>
            <span>Nome</span>
            <span>Administradores</span>
            <span>Membros com acesso permitido</span>
            <span>Ultima modificação</span>
          </Infos>
          <div>
            <span>Ações</span>
          </div>
        </ListItem>
        {data.map((i) => (
          <ListItem key={i.name}>
            <Infos
              role="button"
              tabIndex={0}
              onClick={() => handleOpenClick(i)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdFolder size={21} className="folder" />
                <span style={{ marginLeft: '10px' }}>{i.name}</span>
              </div>
              <AvatarWGroup users={i.admins} />
              <span>{i.members.length + i.admins.length}</span>
              <span>{formatData(i.updatedAt)}</span>
            </Infos>
            <div>
              <Tooltip title="Editar" placement="bottom">
                <button onClick={() => hanleClickEditFolder(i)}>
                  <MdEdit size={24} className="tools" />
                </button>
              </Tooltip>
              <Tooltip title="Deletar" placement="bottom">
                <button onClick={() => handleDelete(i.id)}>
                  <MdDelete size={24} className="delete" />
                </button>
              </Tooltip>
            </div>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default FoldersList
