import styled from 'styled-components'

export const Container = styled.div`
  max-width: 395px;
  width: 100%;
  padding: 10px 0 20px 0;
`

export const Title = styled.span`
  font-size: 16px;
`

export const UserList = styled.ul`
  border-bottom: 1px solid #cecece;
  overflow: auto;
  max-height: 250px;
  height: 100%;
  @media (max-width: 1650px) {
    max-height: 100px;
  }
`

export const User = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
`

export const Infos = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
`

export const RemoveButton = styled.button`
  color: ${(props) => props.theme.primary_blue};
  display: flex;
  align-items: center;
`

export const FooterText = styled.span`
  color: #757575;
`
