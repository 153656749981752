import styled from 'styled-components'

export const Label = styled.label`
  align-items: center;
  border: 2px dashed #cecece;
  background-size: cover;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 210px;
  justify-content: center;
  padding: 10px;
  width: 200px;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;

  &.has-thumbnail {
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  }
`

export const Input = styled.input`
  display: none;
`
