import React, { ButtonHTMLAttributes } from 'react'
import CheckBox from '../StyledCheckBox'
import {
  CheckList,
  CheckUl,
  ButtonTop,
  Buttons,
  Label
} from './StyledComponents'

import { handleSelect } from './helpers/handleSelect'
import handleSelectAll from './utils/handleSelectAll'
import { handleClear } from './utils/handleClearItem'
import verifyMarked from '../../utils/verifyMarked'

interface OprtionsProps {
  value: string
  label?: string
  name?: string
}

interface CheckProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  array: any[]
  setHook: any
  options: OprtionsProps[]
  changeAction?: any
  onClick?: any
  filters?: any[]
  setFilters?: any
}

const CheckItem: React.FC<CheckProps> = ({
  array,
  setHook,
  options,
  changeAction,
  onClick,
  filters = [],
  setFilters,
  ...rest
}) => {
  const handleClick = (value: string) => {
    handleSelect(array, setHook, value)
    if (filters && setFilters) {
      handleSelect(filters, setFilters, value)
    }
    if (changeAction) {
      changeAction()
    }
    if (onClick) {
      onClick(value)
    }
  }

  return (
    <CheckUl>
      <Buttons>
        <ButtonTop
          onClick={() =>
            handleSelectAll(options, setHook, array, filters, setFilters)
          }
          {...rest}
        >
          Selecionar tudo
        </ButtonTop>
        /
        <ButtonTop
          onClick={() => handleClear(setHook, array, filters, setFilters)}
        >
          Limpar seleção
        </ButtonTop>
      </Buttons>
      {options.map((i) => (
        <CheckList key={i.value}>
          <CheckBox
            checked={verifyMarked(i.value, array)}
            handleClick={() => handleClick(i.value)}
          />
          <Label>{i.label || i.name}</Label>
        </CheckList>
      ))}
    </CheckUl>
  )
}

export default CheckItem
