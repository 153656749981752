import aws from 'aws-sdk'
import { getEnvironment } from './getEnvironment'

import uploadConfig from '../configs/uploadConfig'

const s3 = new aws.S3(uploadConfig)
//@ts-ignore
s3.config.setPromisesDependency()

export const uploadFile = async (
  file: any,
  key: string,
  setProgress: any,
  setSize: any,
  setCompleted: any
) => {
  const bucket = getEnvironment({
    devReturn: process.env.REACT_APP_DEV_BUCKET,
    prodReturn: process.env.REACT_APP_PROD_BUCKET
  })

  const options = {
    Bucket: bucket,
    Body: file,
    ACL: 'private',
    Key: key
  }

  return s3 //@ts-ignore
    .upload(options)
    .on('httpUploadProgress', (event: { loaded: number; total: number }) => {
      let percentCompleted = Math.round((event.loaded * 100) / event.total)
      setSize(event.total)
      setCompleted(event.loaded)
      setProgress(percentCompleted)
    })
    .promise()
}
