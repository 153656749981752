import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import SearchInput from '../../components/SearchInput'
import Button from '../../components/Button'
import FileList from './components/FilesList'

import Drawer from '../../components/Drawer'

import { TopRigthSearch } from '../../GlobalStyledComponents'

import AddFile from './components/AddFile'
import useApi from '../../hooks/useApi'
import Pagination from '../../components/Pagination'

const Public: React.FC = () => {
  const [addFileIsOpen, setAddFileIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [change, setChange] = useState(false)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState('')
  const [order, setOrder] = useState('')

  const [result, extra] = useApi(
    `/docs/docs-dash?filter=${search}&fields=title&page=${page}&limit=15&sort=${sort}&order=${order}`,
    'get',
    change,
    setChange
  )

  const handleSetOrder = (value: string, order: string) => {
    setSort(value)
    setOrder(order)
  }

  const openDrawer = () => {
    setAddFileIsOpen(true)
  }

  return (
    <>
      <Drawer
        visible={addFileIsOpen}
        width={920}
        onClose={() => setAddFileIsOpen(false)}
      >
        <AddFile
          actionClose={() => setAddFileIsOpen(false)}
          setChange={setChange}
        />
      </Drawer>
      <TopRigthSearch>
        <SearchInput
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          className="primary"
          width="206px"
          onClick={openDrawer}
          iconStart={<MdAdd />}
          label="Adicionar arquivo"
          style={{ marginTop: '2rem', marginBottom: '2rem', float: 'right' }}
        />
      </TopRigthSearch>
      <FileList data={result} handleSetOrder={handleSetOrder} sort={sort} />
      {extra.totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Public
