import React from 'react'

import EmptyImage from './EmptyImage'
import { Container, Text } from './StyledComponents'

interface IProps {
  text?: string
}

const EmptyWindow: React.FC<IProps> = ({ text = 'arquivo adicionado.' }) => {
  return (
    <Container>
      <EmptyImage />
      <Text>
        Ops... Ainda não temos nenhum
        <br />
        {text}
      </Text>
    </Container>
  )
}

export default EmptyWindow
