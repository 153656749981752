import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { MdError } from 'react-icons/md'
import {
  Label,
  IconEnd,
  InputContainer,
  Container,
  Span,
  ErrorContainer
} from './StyledComponents'
import { IProps } from './types'

const Input: React.FC<IProps> = ({
  label,
  iconStart,
  iconEnd,
  mask,
  width,
  className,
  list,
  options,
  containList,
  listId,
  styles,
  error = false,
  errorMessage,
  ...rest
}) => {
  const [onFocus, setOnFocus] = useState(false)
  return (
    <Label style={styles}>
      {label && <Span className={onFocus ? 'label-focus' : ''}>{label}</Span>}
      <Container>
        {iconStart && <div className="icon-start">{iconStart}</div>}
        <InputContainer
          width={width}
          className={iconStart ? `icon-start-input ${className}` : className}
        >
          <InputMask
            mask={mask ? mask : ''}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
            list={containList ? listId : ''}
            className={
              iconStart
                ? `icon-start-input ${error ? 'error' : ''}`
                : `${error ? 'error' : ''}`
            }
            {...rest}
          />
          {containList && (
            <datalist id={listId}>
              {options?.map((i) => (
                <option
                  key={i.name || i.value || i.label}
                  value={i.name || i.label || i.value}
                />
              ))}
            </datalist>
          )}
        </InputContainer>
        {iconEnd && <IconEnd>{iconEnd}</IconEnd>}
      </Container>
      {error && errorMessage && (
        <ErrorContainer>
          <Span className="error">
            <MdError size={15} />
            {errorMessage}
          </Span>
        </ErrorContainer>
      )}
    </Label>
  )
}

export default Input
