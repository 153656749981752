import { saveCorrection } from '../../../services/api_hours'
import { toast } from 'react-toastify'

const renderMessage = () => toast.success('Correção salva com sucesso!')
const renderErrorMessage = () =>
  toast.error('Ops... Tivemos um problema, tente novamente mais tarde.')

const saveCorrections = async (data: {
  date: string
  member: string
  reason: string
  hours: any[]
}) => {
  const { date, member, reason, hours } = data
  await saveCorrection({ date, member, reason, hours })
    .then((_) => renderMessage())
    .catch((_) => renderErrorMessage())
}

export default saveCorrections
