import {
  MdHistory,
  MdPerson,
  MdFolder
  // MdMoreHoriz,
} from 'react-icons/md'

import Doc from '../../../../assets/icons/Doc'
// import DocActive from '../../assets/icons/DocActive'
import DocDark from '../../../../assets/icons/DocDark'
// import DocDarkActive from '../../assets/icons/DocDarkActive'
import Apartment from '../../../../assets/icons/Apartment'

type MenuProps = {
  darkmode: boolean
}

const menuPrincipal = ({ darkmode }: MenuProps) => {
  // const MoreIcon = (): any => {
  //   return (
  //     <MoreContainer>
  //       <MdMoreHoriz size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
  //     </MoreContainer>
  //   )
  // }

  const DocIcon = (): any => {
    let Icon = darkmode ? DocDark : Doc
    return (
      <div style={{ padding: '1px' }}>
        <Icon />
      </div>
    )
  }

  const ApIcon = (): any => {
    return (
      <div style={{ padding: '3px' }}>
        <Apartment />
      </div>
    )
  }

  return [
    /*{
          label: 'Painel',
          Icon: () => (
            <MdTrendingUp size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
          ),
          path: '/',
          id:'panel'
        },*/
    {
      label: 'Banco de horas',
      Icon: () => <MdHistory size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/',
      id: 'balance'
    },
    {
      label: 'Colaboradores',
      Icon: () => <MdPerson size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/colabs',
      id: 'colabs'
    },
    /* {
          label: 'Relatórios',
          Icon: () => (
            <MdAssessment size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
          ),
          path: '/reports',
          id:'reports'
        },
        { label: 'Solicitações', Icon: () => <MoreIcon />, path: '/requests', id:'requests' } 
    */
    {
      label: 'Arquivos',
      Icon: () => <MdFolder size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/files',
      id: 'files'
    },
    {
      label: 'Documentos',
      Icon: () => <DocIcon />,
      path: '/forms',
      id: 'docs'
    },
    { label: 'Empresa', Icon: () => <ApIcon />, path: '/about', id: 'about' }
  ]
}

export { menuPrincipal }
