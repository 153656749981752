import styled from 'styled-components'

export const ContainerTop = styled.div`
  display: grid;
  grid-template-columns: 499px 232px;
  gap: 10px;
`

export const ContainerBottom = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 365px);
  gap: 10px;
  margin-bottom: 1em;
`
