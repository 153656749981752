import React from 'react'

import { Container } from './StyledComponents'
import Infos from './components/Infos'
import { UserProps } from '../../../../interfaces/user'

interface IProps {
  data: UserProps
}

const ViewMore: React.FC<IProps> = ({ data }) => {
  const bankdata = [
    { key: 'Banco', value: data.bank },
    { key: 'Agência', value: data.agency },
    { key: 'Conta (com dígito)', value: data.account }
  ]
  return (
    <Container>
      <Infos data={bankdata} />
    </Container>
  )
}

export default ViewMore
