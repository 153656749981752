import axios from 'axios'
import { getEnvironment } from '../utils'

const _ApplicationId = process.env.REACT_APP_APLICATION_ID

const API_URL = getEnvironment({
  devReturn: process.env.REACT_APP_API_HOURS_DEV,
  prodReturn: process.env.REACT_APP_API2_URL
})

const api_hours = axios.create({
  baseURL: API_URL
})

export const listUsersWorkingHours = async (
  users: string[],
  sort: string
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    api_hours
      .post('/listUsersWorkingHours', {
        _ApplicationId,
        users,
        sort
      })
      .then((res) => {
        resolve(res.data.result)
      })
      .catch((err) => reject(err))
  })
}

export const listWorkingHoursByPeriod = async (
  users: string[],
  startDate: string,
  endDate: string,
  sort: string
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    api_hours
      .post('/listUsersWorkingHoursByPeriod', {
        _ApplicationId,
        users,
        startDate,
        endDate,
        sort
      })
      .then((res) => resolve(res.data.result))
      .catch((err) => reject(err))
  })
}

type DataList = {
  user: string
  startDate?: string
  endDate?: string
  month?: string
}

export const listWorkHoursByUser = async ({
  user,
  startDate,
  endDate,
  month
}: DataList): Promise<any> => {
  return new Promise((resolve, reject) => {
    const obj = month ? { month } : { startDate, endDate }

    api_hours
      .post('/listWorkingHoursByUser', {
        _ApplicationId,
        user,
        ...obj
      })
      .then((res) => resolve(res.data.result))
      .catch((err) => reject(err))
  })
}

export const acceptCorrections = async (date: string, member: string) => {
  return new Promise((resolve, reject) => {
    api_hours
      .post('/acceptCorrection', {
        _ApplicationId,
        date,
        offset: -180,
        member
      })
      .then((res) => resolve(res.data.result))
      .catch((err) => reject(err))
  })
}

export const denyCorrections = async (
  date: string,
  member: string,
  reason: string
) => {
  return new Promise((resolve, reject) => {
    api_hours
      .post('/denyCorrection', {
        _ApplicationId,
        date,
        reason,
        offset: -180,
        member
      })
      .then((res) => resolve(res.data.result))
      .catch((err) => reject(err))
  })
}

type Register = {
  name: string
  role: string
  email: string
  startDate: string
  hours: number
}

export const registerUser = async ({
  name,
  role,
  email,
  startDate,
  hours
}: Register): Promise<any> => {
  return new Promise((resolve, reject) => {
    api_hours
      .post('/registerMember', {
        _ApplicationId,
        name,
        role,
        email,
        startDate,
        hours
      })
      .then((res) => resolve(res.data.result))
      .catch((err) => reject(err))
  })
}

type SaveCorretionType = {
  date: string
  member: string
  reason: string
  hours: any[]
}

export const saveCorrection = async ({
  date,
  member,
  reason,
  hours
}: SaveCorretionType): Promise<any> => {
  return new Promise((resolve, reject) => {
    api_hours
      .post('/saveCorrection', {
        _ApplicationId,
        offset: -180,
        member,
        date,
        reason,
        hours
      })
      .then((res) => {
        resolve(res.data.result)
      })
      .catch((err) => reject(err))
  })
}

export default api_hours
