import React from 'react'

import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import Template from '../../components/Template'

import SendDocs from './SendDocs'

import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

const Docs: React.FC = () => {
  return (
    <Template active="docs">
      <Container>
        <PageTitle>Documentos</PageTitle>
        <Section>
          <Tabs>
            <Tab title="Novos">
              <SendDocs />
            </Tab>
            <div />
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default Docs
