import styled from 'styled-components'

export const Container = styled.div`
  width: 240px;
  height: auto;
  background: ${(props) => props.theme.dropDowns};
  position: absolute;
  top: 90%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  display: none;

  &&.open {
    display: block;
  }

  ul.notifications li {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }

  ul.notifications li button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 0 4em;
  }
`
