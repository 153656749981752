import React, { useEffect, useState } from 'react'
import { MdEdit, MdDelete } from 'react-icons/md'
import { Tooltip } from 'antd'
import FilterHeader from '../../../../components/FilterHeader'
import { List, ItemList } from './StyledComponents'
import AvatarWGroup from '../../../../components/AvatarWithGroup'
import Drawer from '../../../../components/Drawer'
import Popconfirm from '../../../../components/PopConfirm'
import EditDepartment from '../EditDepartment'
import deleteFn from '../../../../helpers/delete'

interface IProps {
  data: any[]
  setChange: any
}

const filters = [
  { label: 'Nome', value: 'name' },
  { label: 'Gestor(a)' },
  { label: 'Nº de colaboradores', value: 'numberMembers' },
  { label: 'Ações' }
]

const DepartmentList: React.FC<IProps> = ({ data, setChange }) => {
  const [dps, setDps] = useState<any[]>([])
  const [canEdit, setCanEdit] = useState(false)
  const [department, setDepartment] = useState({})

  useEffect(() => {
    setDps([...data])
  }, [data])

  const onEdit = (dp: any) => {
    setDepartment(dp)
    setCanEdit(true)
  }

  const handleDelete = async (index: number, id: string) => {
    await deleteFn(id, '/department', 'Departamento deletado')
      .then((_) => {
        const arr = [...dps]
        arr.splice(index, 1)
        setDps([...arr])
      })
      .catch((err) => console.log(err))
  }

  return (
    <List>
      <Drawer visible={canEdit} onClose={() => setCanEdit(false)} width={746}>
        <EditDepartment
          department={department}
          actionClose={() => setCanEdit(false)}
          setChange={setChange}
        />
      </Drawer>

      <ItemList className="header">
        {filters.map((i) => (
          <FilterHeader label={i.label} clickable={!!i.value} key={i.label} />
        ))}
      </ItemList>
      {dps.map((i, index) => (
        <ItemList className="list">
          <span>{i.name}</span>
          <div>
            {i.managers ? (
              <AvatarWGroup users={i.managers} key={i.name} />
            ) : (
              <div key={i.name} />
            )}
          </div>
          <span>{i.membersNumber}</span>
          <div className="actions">
            <Tooltip placement="bottom" title="Editar">
              <button type="button" onClick={() => onEdit(i)}>
                <MdEdit className="tools" size={21} />
              </button>
            </Tooltip>
            <Tooltip placement="bottom" title="Deletar">
              <Popconfirm
                placement="top"
                title="Deseja mesmo excluir este item?"
                onConfirm={() => handleDelete(index, i._id)}
                okText="Sim"
                cancelText="Não"
              >
                <button type="button">
                  <MdDelete className="delete" size={21} />
                </button>
              </Popconfirm>
            </Tooltip>
          </div>
        </ItemList>
      ))}
    </List>
  )
}

export default DepartmentList
