import React from 'react'
import { Popconfirm, PopconfirmProps } from 'antd'
import { OkButtonStyle, CancelButtonStyle } from './styles'

import './colors.css'

interface IProps extends PopconfirmProps {
  onConfirm: () => {}
}

const PopFC: React.FC<IProps> = ({ children, onConfirm, ...rest }) => {
  return (
    <Popconfirm
      onConfirm={onConfirm}
      okButtonProps={OkButtonStyle}
      cancelButtonProps={CancelButtonStyle}
      overlayClassName="popover"
      {...rest}
    >
      {children}
    </Popconfirm>
  )
}

export default PopFC
