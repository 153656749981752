import React, { useState } from 'react'
import formatData from '../../../../utils/formatData'
import Filter from '../../../../components/FilterHeader'
import Drawer from '../../../../components/Drawer'
// import AcceptCorrection from '../../../../components/AcceptCorrection'
import AcceptVocation from '../../../../components/AcceptedVocations'
import AcceptAllowance from '../../../../components/AcceptAllowances'

import { Container, ListStyled, ListItem, Title } from './StyledComponents'

interface IProps {
  data: any[]
  titles: any[]
  title?: string
  className?: 'trio' | 'double'
  containResult?: boolean
}

const List: React.FC<IProps> = ({
  data,
  titles,
  title,
  className,
  containResult = false
}) => {
  const [visible, setVisible] = useState(false)
  const [typeVisible, setTypeVisible] = useState('')

  const handleOpen = (type: string) => {
    type !== undefined ? setTypeVisible(type) : setTypeVisible('')
    setVisible(true)
  }

  const actionClose = () => setVisible(false)

  return (
    <Container>
      <Drawer visible={visible} width={1071} onClose={() => setVisible(false)}>
        {/*typeVisible === '' && (
          <AcceptCorrection
            actionClose={actionClose}
            containResult={containResult}
          />
        )*/}
        {(typeVisible === 'Férias' || typeVisible === 'Folga') && (
          <AcceptVocation
            actionClose={actionClose}
            containResult={containResult}
          />
        )}
        {(typeVisible === 'Falta' || typeVisible === 'Abono') && (
          <AcceptAllowance
            actionClose={actionClose}
            containResult={containResult}
          />
        )}
      </Drawer>
      {title && (
        <Title>
          <span>{title}</span>
          <p>{`${data.length} resultados`}</p>
        </Title>
      )}
      <ListItem className={`list-header ${className}`}>
        {titles.map((i) => (
          <Filter label={i.label} clickable={!!i.value} onClick={() => {}} />
        ))}
      </ListItem>
      <ListStyled>
        {data.map((i) => (
          <ListItem
            className={`list ${className}`}
            key={i.name}
            role="button"
            tabIndex={0}
            onClick={() => handleOpen(i.type)}
          >
            <span>{i.name}</span>
            {containResult ? (
              <span
                className={i.status === 'Aprovado' ? 'approved' : 'reproved'}
              >
                {i.status}
              </span>
            ) : (
              <span>{formatData(i.createdAt)}</span>
            )}
            {i.type && <span>{i.type}</span>}
          </ListItem>
        ))}
      </ListStyled>
    </Container>
  )
}

export default List
