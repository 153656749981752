const filtersHeader = [
  { label: 'Nº', value: 'acessCode', orderTop: '-1', orderBotton: '1' },
  { label: 'Nome', value: 'name', orderTop: '-1', orderBotton: '1' },
  {
    label: 'Modelo de trabalho',
    value: 'workingModel',
    orderTop: '-1',
    orderBotton: '1'
  },
  { label: 'Cargo', value: 'office', orderTop: '-1', orderBotton: '1' },
  {
    label: 'Departamento',
    value: 'department',
    orderTop: '-1',
    orderBotton: '1'
  },
  { label: 'Ações', value: '' }
]

const filtersHeaderInative = [
  { label: 'Nº', value: '', orderTop: '-1', orderBotton: '1' },
  { label: 'Nome', value: '', orderTop: '-1', orderBotton: '1' },
  { label: 'Antigo Cargo', value: '', orderTop: '-1', orderBotton: '1' },
  { label: 'Ações', value: '', orderTop: '-1', orderBotton: '1' }
]

export { filtersHeader, filtersHeaderInative }
