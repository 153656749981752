import styled from 'styled-components'

interface ProgressProps {
  width: string
}

export const AddFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  place-items: center;
`

export const ItemContainer = styled.div`
  width: 100%;
`

export const ProgressDownload = styled.div`
  padding: 1rem 0;
  width: 100%;
`

export const ProgressTop = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: #707070;
`
export const Progress = styled.div<ProgressProps>`
  background: ${(props) => props.theme.primary_blue};
  height: 100%;
  width: ${(props) => props.width};
`

export const OptionsSection = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 2.5rem 0;
  width: 100%;

  label:not(:first-child) {
    margin-left: 1.5em;
  }
`

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  button:nth-child(1n + 1) {
    margin-right: 10px;
  }
`
