import React, { useEffect, useState } from 'react'
import { ReasonReproved } from '../../../AcceptCorrection/components/ListActions/StyledComponents'
import ListItemFC from './ListItem'
import { Container, List, ListItem, Data, Actions } from './StyledComponents'

interface IProps {
  data: any[]
  titles: any[]
  containResult?: boolean
}

const ListActions: React.FC<IProps> = ({
  data,
  titles,
  containResult = false
}) => {
  const [containReproved, setContainReproved] = useState(false)
  useEffect(() => {
    for (let i = 0; i <= data.length; i += 1) {
      if (data[i].status === 'Reprovado') {
        setContainReproved(true)
        return
      }
    }
  }, [data])
  return (
    <Container>
      <List>
        <ListItem className="header-list">
          <Data>
            {titles.map((i) => (
              <span>{i.label}</span>
            ))}
          </Data>

          {!containResult ? (
            <Actions>
              <div>
                <span>Anexo</span>
              </div>
              <div>
                <span>Ações</span>
              </div>
            </Actions>
          ) : (
            <div>
              <span>Status</span>
            </div>
          )}
        </ListItem>
        {data.map((i) => (
          <ListItemFC data={i} containResult={containResult} />
        ))}
      </List>
      {containReproved && containResult && (
        <ReasonReproved>
          <span>Justificativa para a desaprovação:</span>
          <p>
            Justificativa digitada pelo RH da empresa justificativa digitada
            pelo RH da empresa.
          </p>
        </ReasonReproved>
      )}
    </Container>
  )
}

export default ListActions
