import styled from 'styled-components'

import arrowDown from './assets/arrowDown.svg'
import arrowUp from './assets/arrowUp.svg'

export const Label = styled.label``

export const Container = styled.div`
  display: flex;
  position: relative;
`

export const SelectContainer = styled.div`
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(${arrowDown}) no-repeat;
  background-position: 95% center;

  &.open {
    background: url(${arrowUp}) no-repeat;
    background-position: 95% center;
    border-bottom: 2px solid #00b7ef;
  }

  &.disabled {
    background: #cecece;
  }

  option.button-option:hover {
    background: transparent;
  }
`

export const Options = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 250px;
  background: #fff;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #cecece;
  cursor: pointer;
  top: 100%;
  overflow: auto;
  z-index: 9999;
  li {
    padding: 5px 10px;
  }

  li.options:hover {
    background: #f5f7f9;
    font-weight: 600;
  }

  li.li-button {
    color: ${(props) => props.theme.primary_blue};
    display: flex;
    font-weight: 600;
    padding: 10px;
  }
`
