import React, { useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { BtnGroup, Title, ContainerDrawer } from '../../StyledComponents'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import SearchUser from '../../../../components/SearchUser'
import UsersSelected from '../../../../components/UsersSelected'
import useDisabled from '../../../../hooks/useDisabled'
import create from '../../../../helpers/create'

import { ContainerBottom } from './StyledComponents'
import searchHelper from '../../../../helpers/searchUser'
import getData from './utils/getData'
import { IProps } from './types'

const AddGroup: React.FC<IProps> = ({ actionClose, setChange }) => {
  const [name, setName] = useState('')
  const [userName, setUserName] = useState('')
  const [result, setResult] = useState([])
  const [colabs, setColabs] = useState([])
  const [loading, setLoading] = useState(false)

  const [disabled] = useDisabled([name])

  const handleAdd = async () => {
    const data = getData({ name, colabs })
    await create(data, '/group', 'Grupo cadastrado com sucesso!', setLoading)
      .then((_) => setChange(true))
      .finally(() => actionClose())
  }

  const searchUser = async (value: string) => {
    setUserName(value)
    await searchHelper(value, setResult)
  }

  return (
    <ContainerDrawer>
      <Title>Cadastrar novo grupo</Title>

      <Input
        label="Nome do grupo"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <ContainerBottom>
        <SearchUser
          data={result}
          arraySelected={colabs}
          setData={setColabs}
          value={userName}
          onChange={(e) => searchUser(e.target.value)}
        />
        <UsersSelected users={colabs} setState={setColabs} />
      </ContainerBottom>

      <BtnGroup style={{ marginTop: '4rem' }}>
        <Button className="secondary" label="Cancelar" onClick={actionClose} />
        <Button
          className="primary"
          label="Adicionar"
          onClick={handleAdd}
          iconEnd={<MdChevronRight size={21} />}
          disabled={disabled}
          loading={loading}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default AddGroup
