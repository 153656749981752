import { uploadFile } from '../../../utils/uploadAws'

const types = {
  'application/pdf': {
    type: 'pdf',
    ext: 'pdf'
  },
  'image/png': {
    type: 'image',
    ext: 'png'
  },
  'image/jpg': {
    type: 'image',
    ext: 'jpg'
  },
  'image/jpeg': {
    type: 'image',
    ext: 'jpeg'
  },
  'video/mp4': {
    type: 'video',
    ext: 'mp4'
  }
}

const setType = (type: string): string => {
  // @ts-ignore
  const filetype = types[type.toString()].type
  return filetype
}

const setExt = (type: string): string => {
  // @ts-ignore
  const ext = types[type.toString()].ext
  return ext
}

const upload = async (
  file: any,
  setProgress: any,
  setSize: any,
  setCompleted: any
) => {
  const doc = file.files[0]
  const { name, type } = doc
  const filetype = setType(type)
  const ext = setExt(type)
  const key = `memorian_${Date.now()}_${name}.${ext}`
  const res = await uploadFile(doc, key, setProgress, setSize, setCompleted)
  return { filetype, ...res }
}

export { upload }
