import React, { useCallback, useState } from 'react'
import debounce from 'lodash.debounce'

import ButtonHeader from '../../components/FilterHeader'
import SelectFilter from '../../components/SelectFilter'
import CheckItem from '../../components/CheckItem'

import { Data, PageProps } from './types'

import {
  ToRigthSearch,
  FiltersSection,
  Filter,
  DataList,
  ListItem
} from './StyledComponents'

import { InfoSearch } from '../../GlobalStyledComponents'

import SearchInput from '../../components/SearchInput'
import SearchInfos from '../../components/SearchInfo'
import ViewMore from './components/ViewMore'

import { itens2 } from './constants'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'

import { MdArrowDropDown } from 'react-icons/md'

import Hours from './components/Hours'
import Pagination from '../../components/Pagination'
import useApi from '../../hooks/useApi'

const CheckPoint: React.FC<PageProps> = ({ isManager }) => {
  const defaultData = {
    name: '',
    negativeBalanceTotal: false,
    balanceTotal: '',
    hours: [],
    hoursPerDay: '',
    pendingAdjustmentsCount: 0,
    memberId: '',
    code: ''
  }

  const [openViewMore, setOpenViewMore] = useState(false)
  const [dataMore, setDataMore] = useState<Data>({ ...defaultData })
  const [contract, setContract] = useState([])
  const [department, setDepartment] = useState([])
  const [office, setOffice] = useState([])
  const [journey, setJourney] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<any[]>([])
  const [endSearch, setEndSearch] = useState('')
  const [change, setChange] = useState(false)

  const [filterItems] = useApi('/departmentAndOffice', 'get')

  const [result, extraData] = useApi(
    `/getUserHours/listUsers?search=${endSearch}&contractingModel=${contract}&department=${department}&hoursPerDay=${journey}&office=${office}&finalized=${true}&page=${page}&limit=15&sort=name&order=1`,
    'get',
    change,
    setChange
  )

  // eslint-disable-next-line
  const verify = useCallback(
    debounce((username) => setEndSearch(username), 500),
    []
  )

  const filtersHooks = [
    {
      title: 'Contratação',
      options: [
        { value: 'CLT', label: 'CLT' },
        { value: 'Estágio', label: 'Estágio' },
        { value: 'PJ', label: 'PJ' }
      ],
      array: contract,
      setHook: setContract
    },
    {
      title: 'Departamento',
      options: filterItems.departments,
      array: department,
      setHook: setDepartment
    },
    {
      title: 'Cargo',
      options: filterItems.offices,
      array: office,
      setHook: setOffice
    },
    {
      title: 'Jornada',
      options: [
        { value: '8', label: '40' },
        { value: '6', label: '30' },
        { value: '4', label: '20' }
      ],
      array: journey,
      setHook: setJourney
    }
  ]

  const handleOpenData = (item: Data) => {
    setDataMore(item)
    setOpenViewMore(true)
  }

  const handleClose = () => {
    setDataMore({ ...defaultData })
    setOpenViewMore(false)
  }

  const clear = () => {
    setSearch('')
    setEndSearch('')
  }

  const handleSearch = (value: string) => {
    setSearch(value)
    verify(value)
    if (page > 1) {
      setPage(1)
    }
  }
  return (
    <>
      {!openViewMore ? (
        <>
          <ToRigthSearch>
            <div className="paddingDiv">
              <SearchInput
                placeholder="Pesquisar por nome, CPF ou matrícula"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </ToRigthSearch>
          <FiltersSection>
            <span>Filtar:</span>
            {filtersHooks.map((i) => (
              <Filter key={i.title}>
                <SelectFilter label={i.title}>
                  <CheckItem
                    array={i.array}
                    setHook={i.setHook}
                    options={i.options}
                    setFilters={setFilters}
                    filters={filters}
                  />
                </SelectFilter>
              </Filter>
            ))}
          </FiltersSection>
          <InfoSearch>
            <SearchInfos
              data={filters}
              text={endSearch}
              result={result.length}
              objectHooks={[
                { hook: department, setHook: setDepartment },
                { hook: contract, setHook: setContract },
                { hook: office, setHook: setOffice },
                { hook: journey, setHook: setJourney }
              ]}
              clearSearch={clear}
              setFilters={setFilters}
            />
          </InfoSearch>
          <ListItem className="header-list contain-icon">
            {itens2.map((i) => (
              <ButtonHeader label={i.label} key={i.label} />
            ))}
            <div
              style={{ width: '48px', height: '48px', marginRight: '-12px' }}
            />
          </ListItem>
          {result.map((i: Data) => (
            <Accordion key={i.name} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                style={{ height: '65px' }}
                expandIcon={<MdArrowDropDown />}
              >
                <DataList>
                  <ListItem>
                    <span>{i.name}</span>
                    <span
                      className={
                        i.negativeBalanceTotal ? 'negative' : 'positive'
                      }
                    >
                      {i.balanceTotal}
                    </span>
                    <span>{i.pendingAdjustmentsCount}</span>
                    <span>{i.hoursPerDay}</span>
                  </ListItem>
                </DataList>
              </AccordionSummary>
              <AccordionDetails>
                <Hours
                  data={i}
                  handleClick={() => handleOpenData(i)}
                  setChange={setChange}
                  isManager={isManager}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : (
        <ViewMore
          infos={dataMore}
          handleClose={handleClose}
          isManager={isManager}
        />
      )}
      {extraData.totalPages > 1 && !openViewMore && (
        <Pagination
          page={page}
          totalPages={extraData.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default CheckPoint
