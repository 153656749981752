import React, { useContext } from 'react'
import Context from '../context/Global'

import SignRoutes from './SignRoutes'
import OtherRoutes from './OtherRoutes'

const Routes: React.FC = () => {
  const { state } = useContext(Context)
  const { signed } = state
  return signed ? <OtherRoutes /> : <SignRoutes />
}
export default Routes
