const checkEmptyKeys = (key: string, user: any) => {
  if (key === 'manager' || key === 'technicalReference') {
    if (!user[key]) {
      return []
    } else {
      return user[key]
    }
  } else {
    return user[key]
  }
}

const checkKey = (key: string, user: any) => {
  return key === 'hoursPerDay'
    ? (user[key] * 5).toString()
    : checkEmptyKeys(key, user)
}

const setDatas = (data: any[], user: any) => {
  data.map((i) => {
    return i.setHook(checkKey(i.key, user))
  })
}

export default setDatas
