import React from 'react'
import fisrtAndLastName from '../../../../utils/firstAndLastName'
import formatData from '../../../../utils/formatData'

import {
  ViewsWrapper,
  ViewsItem,
  ViewsList,
  ViewTitle,
  ViewSpan,
  TitlesBox
} from './StyledComponents'

type RequiredViews = {
  name: string
  memberId: string
  acessCode: string
  confirmedView: boolean
  lastNotification: Date
  viewedIn: Date
}
interface IProps {
  requiredData: RequiredViews[]
}

const StyledComponents: React.FC<IProps> = ({ requiredData }) => {
  return (
    <ViewsWrapper>
      <ViewsList>
        <ViewTitle>Visto por</ViewTitle>
        {requiredData.map(
          (i) =>
            i.confirmedView && (
              <ViewsItem>
                <ViewSpan>{fisrtAndLastName(i.name)}</ViewSpan>
                <ViewSpan>{formatData(i.viewedIn)}</ViewSpan>
              </ViewsItem>
            )
        )}
      </ViewsList>

      <ViewsList>
        <TitlesBox>
          <ViewTitle>Restam</ViewTitle>
          <ViewTitle>Ultima notificação</ViewTitle>
        </TitlesBox>
        {requiredData.map(
          (i) =>
            !i.confirmedView && (
              <ViewsItem>
                <ViewSpan>{fisrtAndLastName(i.name)}</ViewSpan>
                <ViewSpan>{formatData(i.lastNotification)}</ViewSpan>
              </ViewsItem>
            )
        )}
      </ViewsList>
    </ViewsWrapper>
  )
}

export default StyledComponents
