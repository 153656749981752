import {
  MdHistory,
  MdPerson
  // MdTrendingUp,
  // MdAssessment,
  // MdMoreHoriz,
  // MdFolder
} from 'react-icons/md'

// import { MoreContainer } from '../../styles'

type MenuProps = {
  darkmode: boolean
}

const menuManager = ({ darkmode }: MenuProps) => {
  // const MoreIcon = (): any => {
  //   return (
  //     <MoreContainer>
  //       <MdMoreHoriz size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
  //     </MoreContainer>
  //   )
  // }
  return [
    /* {
      label: 'Painel',
      Icon: () => (
        <MdTrendingUp size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
      ),
      path: '/',
      id:'panel'
    }, */
    {
      label: 'Banco de horas',
      Icon: () => <MdHistory size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/',
      id: 'balance'
    },
    {
      label: 'Colaboradores',
      Icon: () => <MdPerson size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/colabs',
      id: 'colabs'
    }
    /* 
    {
      label: 'Relatórios',
      Icon: () => (
        <MdAssessment size={20} color={darkmode ? '#fff' : '#1a1a1a'} />
      ),
      path: '/reports',
      id:'reports'
    },
    { label: 'Solicitações', Icon: () => <MoreIcon />, path: '/requests',id:'reports' },
    {
      label: 'Arquivos',
      Icon: () => <MdFolder size={20} color={darkmode ? '#fff' : '#1a1a1a'} />,
      path: '/files',
      id:'files'
    } */
  ]
}

export { menuManager }
