import React, { CSSProperties } from 'react'
import { List, Item } from './StyledComponents'

type InfosProps = {
  key: string
  value: string
}

interface IProps {
  data: InfosProps[]
  style?: CSSProperties
}

const Infos: React.FC<IProps> = ({ data, style }) => {
  return (
    <List style={{ ...style }}>
      {data.map(({ key, value }) => (
        <Item key={key}>
          <span>{key}</span>
          <div className="value">
            <span>{value || ''}</span>
          </div>
        </Item>
      ))}
    </List>
  )
}

export default Infos
