import React, { useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import {
  Title,
  ContainerDrawer,
  BtnGroup
} from '../../../../GlobalStyledComponents'
import cepValidator from '../../../../utils/cepValidator'
import { Main, SectionInputs } from './StyledComponents'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { IProps } from './types'
import update from './helpers/update'

const EditData: React.FC<IProps> = ({ data, setChange, actionClose }) => {
  const [name, setName] = useState(data.name)
  const [socialReason, setSocialReason] = useState(data.socialReason)
  const [cnpj, setCnpj] = useState(data.cnpj)
  const [cep, setCep] = useState(data.cep)
  const [uf, setUf] = useState(data.uf)
  const [city, setCity] = useState(data.city)
  const [district, setDistrict] = useState(data.district)
  const [address, setAddress] = useState(data.address)

  const InputsTop = [
    { label: 'Noma da empresa', value: name, setValue: setName },
    { label: 'Razão social', value: socialReason, setValue: setSocialReason }
  ]

  const handleSetCep = async () => {
    await cepValidator({ cep }).then((res) => {
      const { city: nCity, state } = res
      setCity(nCity)
      setUf(state)
    })
  }

  const InputsGroup = [
    {
      label: 'CNPJ',
      value: cnpj,
      setValue: setCnpj,
      mask: '99.999.999/9999-99'
    },
    {
      label: 'CEP',
      value: cep,
      setValue: setCep,
      onBlur: handleSetCep,
      mask: '99999-999'
    },
    {
      label: 'Estado',
      disable: true,
      value: uf,
      setValue: setUf
    },
    { label: 'Cidade', disable: true, value: city, setValue: setCity },
    { label: 'Bairro', value: district, setValue: setDistrict },
    { label: 'Endereço', value: address, setValue: setAddress }
  ]

  const handleSave = async () => {
    const dataReq = {
      name,
      socialReason,
      cnpj,
      cep,
      uf,
      city,
      district,
      address,
      id: data._id
    }
    await update('/about/update', dataReq, 'Dados atualizados com sucesso.')
      .then((_) => {
        if (setChange) {
          setChange(true)
        }
      })
      .finally(() => actionClose())
  }

  const buttons = [
    {
      label: 'Cancelar',
      primary: false,
      onClick: () => actionClose()
    },
    {
      label: 'Salvar',
      primary: true,
      onClick: () => handleSave(),
      iconEnd: <MdChevronRight size={24} />
    }
  ]

  return (
    <ContainerDrawer>
      <Title>Atualizar dados da empresa</Title>
      <Main>
        {InputsTop.map((i) => (
          <div style={{ marginTop: '10px', width: '100%' }}>
            <Input
              label={i.label}
              value={i.value}
              onChange={(e) => i.setValue(e.target.value)}
              key={i.label}
            />
          </div>
        ))}
        <SectionInputs>
          {InputsGroup.map((i) => (
            <Input
              label={i.label}
              disabled={i.disable}
              value={i.value}
              onChange={(e) => i.setValue(e.target.value)}
              key={i.label}
              onBlur={i.onBlur}
              mask={i.mask}
            />
          ))}
        </SectionInputs>
      </Main>
      <BtnGroup>
        {buttons.map((i) => (
          <Button
            label={i.label}
            onClick={() => i.onClick()}
            className={i.primary ? 'primary' : 'secondary'}
            iconEnd={i.iconEnd}
            key={i.label}
          />
        ))}
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default EditData
