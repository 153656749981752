import React, { useCallback, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import SearchInput from '../../components/SearchInput'
import Button from '../../components/Button'
import { TopSearch } from './StyledComponents'
import DepartmentList from './components/DepartmentList'
import Drawer from '../../components/Drawer'
import AddDepartment from './components/AddDepartment'
import useApi from '../../hooks/useApi'
import Pagination from '../../components/Pagination'
import debounce from 'lodash.debounce'

// import { Container } from './styles';

const AboutCompany: React.FC = () => {
  const [canAdd, setCanAdd] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [change, setChange] = useState(false)
  const [endSearch, setEndSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [data, extra] = useApi(
    `/department/?filter=${endSearch}&fields=name&page=${page}&limit=15&sort=createdAt&order=-1`,
    'get',
    change,
    setChange
  )

  // eslint-disable-next-line
  const verify = useCallback(
    debounce((res) => {
      setEndSearch(res)
      setLoading(false)
    }, 500),
    []
  )

  const handleSearch = (value: string) => {
    setLoading(true)
    setSearch(value)
    verify(value)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <>
      <Drawer visible={canAdd} onClose={() => setCanAdd(false)} width={746}>
        <AddDepartment
          setChange={setChange}
          actionClose={() => setCanAdd(false)}
        />
      </Drawer>
      <TopSearch>
        <Button
          className="primary"
          width="243px"
          label="Cadastrar departamento"
          onClick={() => setCanAdd(true)}
          iconStart={<MdAdd size={24} />}
        />
        <SearchInput
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </TopSearch>

      {data.length > 0 && !loading && (
        <DepartmentList data={data} setChange={setChange} />
      )}

      {extra.totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default AboutCompany
