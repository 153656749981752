import React, { useEffect, useState } from 'react'

import { GridLoader } from 'react-spinners'

import { AccordionDetails } from '@material-ui/core'
import Hours from '../Hours'
import { listWorkHoursByUser } from '../../../../services/api_hours'
import { Loader } from './StyledComponents'

interface IProps {
  startData: string
  endData: string
  code: string
  memberId: string
  name: string
  isManager: boolean
}

type Object = {
  startDate?: string
  endDate?: string
}

const Expanded: React.FC<IProps> = ({
  startData,
  endData,
  code,
  memberId,
  name,
  isManager
}) => {
  const [result, setResult] = useState<any>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      let object: Object = {} as Object
      if (startData) {
        object.startDate = startData
        object.endDate = startData
      }

      if (endData) {
        object.endDate = endData
      }

      await listWorkHoursByUser({
        user: code,
        ...object
      }).then((res) => {
        setResult({ ...res, memberId, name })
        setLoading(false)
      })
    }

    getData()
  }, [startData, endData, code, memberId, name])

  return (
    <AccordionDetails>
      {loading ? (
        <Loader>
          <GridLoader color="#00B7EF" />
        </Loader>
      ) : (
        <Hours data={result} isManager={isManager} />
      )}
    </AccordionDetails>
  )
}

export default Expanded
