import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdDelete, MdMoreHoriz } from 'react-icons/md'
import { Actions, ButtonAction } from '../../../../GlobalStyledComponents'
import { filtersHeaderInative } from '../../constants/filtersHeader'
import { Item, List } from '../../StyledComponents'
import ButtonHeader from '../../../../components/FilterHeader'
import deleteFn from '../../../../helpers/delete'
import fisrtAndLastName from '../../../../utils/firstAndLastName'
import Popconfirm from '../../../../components/PopConfirm'
import { IProps } from './types'

const InativeList: React.FC<IProps> = ({ result, actionClose }) => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    setData(result)
  }, [result])

  const handleDelete = async (id: string, index: number) => {
    await deleteFn(id, '/inative', 'Registro excluído com sucesso.')
      .then((_) => {
        const arr = [...data]
        arr.splice(index, 1)
        setData([...arr])
      })
      .finally(() => actionClose())
  }

  return (
    <List>
      <Item>
        {filtersHeaderInative.map((i) => (
          <ButtonHeader label={i.label} clickable={!!i.value} />
        ))}
      </Item>
      {data.map((i, index) => (
        <Item>
          <span>{i.accessCode}</span>
          <span>{fisrtAndLastName(i.name)}</span>
          <span>{i.office}</span>
          <Actions>
            <Tooltip placement="bottom" title="Ver mais">
              <ButtonAction>
                <MdMoreHoriz size={21} />
              </ButtonAction>
            </Tooltip>

            <Tooltip placement="bottom" title="Apagar">
              <Popconfirm
                placement="top"
                title="Deseja mesmo excluir este item?"
                onConfirm={() => handleDelete(i._id, index)}
                okText="Sim"
                cancelText="Não"
              >
                <ButtonAction type="button">
                  <MdDelete size={21} className="delete" />
                </ButtonAction>
              </Popconfirm>
            </Tooltip>
          </Actions>
        </Item>
      ))}
    </List>
  )
}

export default InativeList
