import React from 'react'

import { Container } from './StyledComponents'
import Infos from './components/Infos'
import { UserProps } from '../../../../interfaces/user'

interface IProps {
  data: UserProps
}

const ViewMore: React.FC<IProps> = ({ data }) => {
  const infosData = [
    { key: 'Tamanho de calçado', value: data.sizeShoes },
    { key: 'Tamanho de camiseta', value: data.sizeShirt },
    { key: 'Tipo de camiseta', value: data.typeShirt },
    { key: 'Restrição alimentar', value: data.foodRestriction },
    { key: 'Mão dominante', value: data.dominantHand },
    { key: 'Instagram', value: data.usernameInsta }
  ]
  return (
    <Container>
      <Infos data={infosData} />
    </Container>
  )
}

export default ViewMore
