import { MdEvent } from 'react-icons/md'
const getGroup = (
  name: string,
  setName: any,
  registration: string,
  setRegistration: any,
  email: string,
  setEmail: any,
  admissionDate: string,
  setAdmissionDate: any
) => {
  return [
    {
      label: 'Nome completo',
      name: 'name',
      value: name,
      setValue: setName
    },
    {
      label: 'Matricula',
      name: 'registration',
      value: registration,
      setValue: setRegistration,
      mask: '9999'
    },
    {
      label: 'Email',
      name: 'email',
      value: email,
      setValue: setEmail
    },
    {
      label: 'Data de admissão',
      iconEnd: <MdEvent size={20} />,
      name: 'admissionDate',
      value: admissionDate,
      setValue: setAdmissionDate,
      mask: '99/99/9999'
    }
  ]
}

export default getGroup
