import styled from 'styled-components'

export const TabContent = styled.ul`
  display: flex;
  list-style: none;
`

export const TabLi = styled.li`
  &.active {
    border-bottom: 2px solid ${(props) => props.theme.primary_blue};
  }

  &:not(:first-child) {
    margin-left: 15px;
  }
`
export const Tabbutton = styled.button`
  font-size: 1.1em;
  color: #757575;
  &.active {
    font-weight: 600;
    color: initial;
  }
`
