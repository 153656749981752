import React from 'react'

import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import Template from '../../components/Template'

import Public from './Public'
import Folders from './SharedFolders'

import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

const Files: React.FC = () => {
  return (
    <Template active="files">
      <Container>
        <PageTitle>Colaboradores</PageTitle>
        <Section>
          <Tabs>
            <Tab title="Arquivos Públicos">
              <Public />
            </Tab>
            <Tab title="Pastas compartilhadas">
              <Folders />
            </Tab>
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default Files
