type IParams = {
  devReturn: string | undefined
  prodReturn: string | undefined
}

const getEnvironment = ({ devReturn, prodReturn }: IParams): string => {
  let url = window.location.href.split('/')
  url.length = 3

  const newUrl = `https://${url[2]}/`
  const newUrl2 = `http://${url[2]}/`

  const result =
    newUrl !== process.env.REACT_APP_URL_PROD_HTTPS ||
    newUrl2 !== process.env.REACT_APP_URL_PROD_HTTP
      ? devReturn
      : prodReturn

  return result ? result : ''
}

export { getEnvironment }
