import React, { useEffect, useState } from 'react'
import { MdChevronLeft, MdArrowDropDown } from 'react-icons/md'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import {
  InfosTop,
  TitleTop,
  Container,
  List,
  Section,
  Titles
} from './StyledComponents'
import { Data } from '../../types/index'
import Button from '../../../../components/Button'
import AcceptCorretions from '../../../../components/AcceptCorrection'
import Drawer from '../../../../components/Drawer'
import Select from '../../../../components/Select'

import { returnAtualMonth } from './utils/returnAtualMonth'
import { dates } from '../../utils/getDates'
import { listWorkHoursByUser } from '../../../../services/api_hours'

interface IProps {
  infos: Data | any
  handleClose: any
  isManager: boolean
}

type DataCorrect = {
  name: string
  date: string
  hours: any[]
  memberId: string
  reasonRequestFix: string
  olderHours: any[]
  code: string
}

const ViewMore: React.FC<IProps> = ({ infos, handleClose, isManager }) => {
  const [iCanCorrect, setICanCorrect] = useState(false)
  const [correctData, setCorrectData] = useState({} as DataCorrect)
  const [data, setData] = useState<any>({})

  const month = returnAtualMonth()
  const [date, setDate] = useState(month)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const atual = returnAtualMonth()
    listWorkHoursByUser({ user: infos.code, month: date || atual })
      .then((res) => {
        setData({ ...infos, hours: res.hours })
      })
      .finally(() => setLoading(false))
  }, [date, infos.memberId, infos])

  const hanleClickInCorrect = (item: DataCorrect) => {
    setICanCorrect(true)
    setCorrectData(item)
  }

  return (
    <Container>
      <Drawer
        visible={iCanCorrect}
        width={952}
        onClose={() => setICanCorrect(false)}
      >
        <AcceptCorretions
          actionClose={() => setICanCorrect(false)}
          data={correctData}
        />
      </Drawer>
      <Button
        className="only-text"
        label="Voltar"
        iconStart={<MdChevronLeft size={21} />}
        onClick={handleClose}
      />
      <TitleTop>{data.name}</TitleTop>
      <InfosTop>
        <span>
          Saldo de horas atual{' '}
          <span className={data.negativeBalanceTotal ? 'negative' : 'positive'}>
            {data.balanceTotal ? data.balanceTotal : ''}
          </span>
        </span>
        <span>{`Ajustes pendentes: ${
          data.pendingAdjustmentsCount ? data.pendingAdjustmentsCount : ''
        }`}</span>
      </InfosTop>

      <div style={{ marginTop: '10px', marginBottom: '15px' }}>
        <Select
          options={dates}
          value={date}
          defaultValue={date}
          label="Mes/Ano"
          setChange={setDate}
          width="190px"
        />
      </div>
      {!loading && (
        <>
          {data.hours.map(
            (
              i: {
                title: string
                date: string
                pendingFix: boolean
                negative: boolean
                hours: any[]
                reasonRequestFix: string
                olderHours: any[]
                code: string
              },
              index: string | number | null | undefined
            ) => (
              <Accordion key={index}>
                <AccordionSummary
                  className={
                    i.pendingFix ? 'accordion-fix pedingFix' : 'accordion-item'
                  }
                  expandIcon={<MdArrowDropDown />}
                >
                  <Titles>
                    <span>{i.title.split('-')[0]}</span>
                    <span className={i.negative ? 'negative' : 'positive'}>
                      {i.title.split('-')[1]}
                    </span>
                  </Titles>
                  {i.pendingFix && !isManager && (
                    <Button
                      className="primary"
                      label="Corrigir"
                      styles={{ alignSelf: 'flex-end' }}
                      heigth="40px"
                      onClick={() =>
                        hanleClickInCorrect({
                          name: data.name,
                          date: i.date,
                          hours: i.hours,
                          memberId: data.memberId,
                          reasonRequestFix: i.reasonRequestFix,
                          olderHours: i.olderHours,
                          code: i.code
                        })
                      }
                    />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Section>
                    <List>
                      <li className="titles">
                        <span>Entrada</span>
                        <span>Saída</span>
                      </li>
                      {i.hours.map(
                        (res: {
                          in: string
                          out: string
                          changedIn: boolean
                          changedOut: boolean
                        }) => (
                          <li className="hours">
                            <span className={res.changedIn ? 'fix' : ''}>
                              {res.in}
                            </span>
                            <span className={res.changedOut ? 'fix' : ''}>
                              {res.out}
                            </span>
                          </li>
                        )
                      )}
                    </List>
                  </Section>
                </AccordionDetails>
              </Accordion>
            )
          )}
        </>
      )}
    </Container>
  )
}

export default ViewMore
