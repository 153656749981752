import { Data } from '../interfaces/data'
import { returnCache } from '../interfaces/returnCache'
import { diff, decryptKey } from '../../../utils'

const getInCache = (data: Data): Promise<returnCache> => {
  return new Promise((resolve, reject) => {
    const name = `${data.name.split(' ')[0]}_${data.name.split(' ').pop()}`
    const JsonItem =
      localStorage.getItem(name) || JSON.stringify({ teste: 'no cache' })
    const item = JSON.parse(JsonItem)
    if (item) {
      const { avatar, url, createdAt } = item
      const decryptAvatar = decryptKey(avatar)
      const decryptUrl = decryptKey(url)
      if (decryptAvatar === data.avatar) {
        diff(createdAt, 'days') >= 6
          ? reject(null)
          : resolve({
              avatar: decryptAvatar,
              url: decryptUrl
            })
      } else {
        reject(null)
      }
    } else {
      reject(null)
    }
  })
}

export { getInCache }
