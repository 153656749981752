import { Moment } from 'moment'
import { fixUserObject } from './fixUserObject'
import { format } from './format'

interface ObjectProps {
  endDate: string
  startDate: string
  department?: string
  group?: string
  users?: string[]
}

type Params = {
  option: string
  usersSelect: any[]
  searchOption: string
  startDate?: Moment
  endDate?: Moment
  moreDate: boolean
}

export const returnObject = ({
  option,
  usersSelect,
  searchOption,
  startDate,
  endDate,
  moreDate
}: Params): ObjectProps => {
  let object: ObjectProps = {} as ObjectProps
  if (option === 'Colaboradores') {
    object.users = fixUserObject(usersSelect)
  }
  if (option === 'Departamentos') {
    object.department = searchOption
  }
  if (option === 'Grupos') {
    object.group = searchOption
  }
  if (startDate) {
    object.startDate = format(startDate)
    object.endDate = format(startDate)
  }
  if (endDate && moreDate) {
    object.endDate = format(endDate)
  }
  return object
}
