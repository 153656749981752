import api from './api'
import { UserProps } from '../interfaces/user'
import { encryptKey } from '../utils/encrypt-key'

interface ReturnLogin {
  user: UserProps
  token: string
}

export const login = (
  acessCode: string,
  password: string
): Promise<ReturnLogin> => {
  return new Promise((resolve, reject) => {
    api
      .post('/login', {
        acessCode: encryptKey(acessCode),
        password: encryptKey(password)
      })
      .then((res) => {
        const { token, profile } = res.data
        api.defaults.headers.Authorization = `Bearer ${token}`

        resolve({
          user: profile,
          token
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}
