import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap');
  * {
    box-sizing: border-box;
    margin: 0;
    outline: 0 !important;
    padding: 0;
  }

  html {
    font-size: 62,5%;
  }

  body {
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  }

  button {
    border: 0;
    font-family: inherit;
    font-size: 15px;
    background: none;
    cursor: pointer;
  }

  ul,li {
    list-style:none;
    margin: 0;
  }

  input {
    border: none;
  }

  input:disabled, select:disabled
  {
      background: #cecece;
    }

  figure {
    margin: 0;
  }

  svg.tools {
    color: #757575;
  }

  svg.delete {
    color: #E74C3C;
  }

  span,p {
    color: #383838;
  }

  p {
    margin: 0;
  }

  .toast-custom-container .Toastify__toast--default {
  background: #fff;
  color: #00b7ef;
}
.toast-custom-container .Toastify__toast--info {
  background: #00b7ef;
}
.toast-custom-container .Toastify__toast--success {
  background: #2ecc71;
}
.toast-custom-container .Toastify__toast--warning {
  background: #ffbf00;
}
.toast-custom-container .Toastify__toast--error {
  background: #e74c3c;
}

.toast-custom-container .Toastify__toast--info,
.toast-custom-container .Toastify__toast--success,
.toast-custom-container .Toastify__toast--warning,
.toast-custom-container .Toastify__toast--error {
  color: #fff;
}
 
  ::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(165, 165, 165, 0.8);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

::-webkit-scrollbar-corner {
  background: transparent;
}
`

export default GlobalStyles
