import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px 60px;
`

export const AccordeonContainer = styled.div``

export const PageTitle = styled.span`
  font-size: 20px;
`

export const Section = styled.section`
  margin-top: 2rem;
`

export const ToRigthSearch = styled.div`
  max-width: 600px;
  width: 100%;
  float: right;

  div.paddingDiv {
    padding: 1.5em 0;
  }
`

export const FiltersSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`

export const ConsulationsSection = styled.div`
  display: grid;
  grid-template-columns: 190px 396px auto;
  gap: 1em;
  padding: 2rem 0;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;

  button:first-child {
    margin-right: 1em;
  }
`

export const FlexOne = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1em 13.5em;
`

export const Filter = styled.div`
  padding: 0.8rem;
`

export const DataList = styled.ul`
  width: 100%;
`

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  align-items: center;
  span.negative {
    color: ${(props) => props.theme.red};
  }

  span.positive {
    color: ${(props) => props.theme.green};
  }

  &.header-list {
    background: #f5f7f9;
    padding: 16px;
  }

  &.header-list.contain-icon {
    grid-template-columns: repeat(4, 1fr) 48px;
  }
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 3.2em 0;
`

export const Check = styled.div`
  display: flex;
  flex-direction: column;
  height: 75px;
  justify-content: space-between;
  padding: 0 0 1.2em 0;
  margin-left: 1.5em;
`
