import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
`

export const Select = styled.select`
  height: 56px;
  width: 78px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 0 0 0 1.5em;

  margin-left: 10px;
  margin-right: 10px;
  font-weight: 20px;
  font-weight: 600;
`
