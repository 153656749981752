import React from 'react'

import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import Template from '../../components/Template'

import Generate from './Generate'
import Historic from './Historic'
import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

const Reports: React.FC = () => {
  return (
    <Template active="reports">
      <Container>
        <PageTitle>Relatórios</PageTitle>
        <Section>
          <Tabs>
            <Tab title="Gerar relatórios">
              <Generate />
            </Tab>
            <Tab title="Histórico">
              <Historic />
            </Tab>
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default Reports
