const returnData = (name: string, users: any[]): any => {
  const managers = users.map((i) => {
    return { name: i.name, avatar: i.avatar }
  })

  return {
    name,
    value: name,
    managers
  }
}

export default returnData
