import styled from 'styled-components'

export const Table = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(4, 100px);
`

export const List = styled.li`
  display: flex;
  flex-direction: column;
  span:first-child {
    font-weight: 600;
  }
`

export const Top = styled.div`
  padding: 1.8em 0 4em 0;
`

export const TopSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 0;
`
