import styled from 'styled-components'

export const List = styled.ul`
  li {
    display: flex;
    height: 100px;
    padding: 10px 0;
    align-items: center;
    border-bottom: 1px solid #bfbfbf;
    justify-content: space-between;
  }
`
export const ItemInfos = styled.div`
  display: flex;
  align-items: center;
`

export const Infos = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-left: 10px;
  }

  span:not(:first-child) {
    color: #828282;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  width: 70px;
  justify-content: space-between;
`
