import moment from 'moment'
const date = new Date()

const currentMontLastFirstDay = (days: number) => {
  const monthFirst = days <= 30 ? date.getMonth() : date.getMonth() - 1
  const monthLast = days <= 30 ? date.getMonth() + 1 : date.getMonth()

  const primeiroDia = new Date(date.getFullYear(), monthFirst, 1)
  const ultimoDia = new Date(date.getFullYear(), monthLast, 0)

  const first = moment(primeiroDia).format('L')
  const last = moment(ultimoDia).format('L')

  return {
    first,
    last
  }
}

export { currentMontLastFirstDay }
