import React, { useState } from 'react'
import { Moment } from 'moment'

import ButtonHeader from '../../components/FilterHeader'
import UsersSelected from '../../components/UsersSelected'
import Button from '../../components/Button'
import SearchUsers from '../../components/SearchUser'
import RadioInputs from '../../components/RadioInputs'
import Select from '../../components/Select'
import DatePicker from '../../components/DatePicker'
import CheckBox from '../../components/StyledCheckBox'

import {
  ConsulationsSection,
  ButtonContainer,
  ListItem,
  DataList,
  FlexOne,
  DateContainer,
  Check
} from './StyledComponents'

import { itens } from './constants'
import searchHelper from '../../helpers/searchUser'
import { Accordion, AccordionSummary } from '@material-ui/core'
import Expanded from './components/Expanded'
import api from '../../services/api'
import useApi from '../../hooks/useApi'

import { returnObject } from './utils/returnConsulationObject'
import { format } from './utils/format'

import { PageProps } from './types'

type Data = {
  balancePeriod: string
  balanceTotal: string
  code: string
  hours: any[]
  name: string
  negativeBalancePeriod: boolean
  negativeBalanceTotal: boolean
  totalHoursInPeriod: string
  memberId: string
}

type Options =
  | ['Colaboradores', 'Departamentos', 'Grupos']
  | ['Colaboradores', 'Departamentos']

const Consulations: React.FC<PageProps> = ({ isManager }) => {
  const [startDate, setStartDate] = useState<Moment | undefined>()
  const [endDate, setEndDate] = useState<Moment | undefined>()

  const [usersSelect, setUserSelect] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [result, setResult] = useState([])
  const [expanded, setExpanded] = useState<string | boolean>(false)
  const [option, setOption] = useState('Colaboradores')
  const [searchOption, setSearchOption] = useState('')
  const [moreDate, setMoreDate] = useState(false)

  const [filterItems] = useApi('/departmentAndOffice', 'get')

  const options: Options = !isManager
    ? ['Colaboradores', 'Departamentos', 'Grupos']
    : ['Colaboradores', 'Departamentos']

  const searchUser = async (value: string) => {
    setSearch(value)
    await searchHelper(value, setResult)
  }

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleSearch = async () => {
    const object = returnObject({
      option,
      usersSelect,
      searchOption,
      startDate,
      endDate,
      moreDate
    })
    api
      .post('/getUserHours/listByPeriod', {
        ...object
      })
      .then((res) => {
        setData(res.data.docs)
      })
  }

  const clearUsers = () => {
    setUserSelect([])
    setResult([])
    setSearch('')
  }

  const changeOption = (value: string) => {
    if (value === 'Departamentos' || value === 'Grupos') {
      clearUsers()
    }
    setSearchOption('')
    setOption(value)
  }

  const handleClear = () => {
    setData([])
    setSearchOption('')
    clearUsers()
  }

  return (
    <>
      <ConsulationsSection>
        <RadioInputs
          value={option}
          setValue={(e: string) => changeOption(e)}
          options={options}
          display="column"
        />
        {option === 'Colaboradores' && (
          <SearchUsers
            data={result}
            arraySelected={usersSelect}
            setData={setUserSelect}
            value={search}
            onChange={(e) => searchUser(e.target.value)}
          />
        )}
        {option === 'Departamentos' && (
          <Select
            label="Departamentos"
            options={filterItems.departments}
            value={searchOption}
            setChange={setSearchOption}
          />
        )}

        {option === 'Grupos' && (
          <Select
            label="Grupos"
            options={filterItems.groups}
            value={searchOption}
            setChange={setSearchOption}
          />
        )}
        <DateContainer>
          <DatePicker
            value={startDate}
            onChange={setStartDate}
            label={moreDate ? 'Data inicial' : undefined}
          />
          {moreDate && (
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              label="Data final"
              marginLeft="1em"
            />
          )}
          <Check>
            <span>Mais de uma data</span>
            <CheckBox
              checked={moreDate}
              handleClick={() => setMoreDate(!moreDate)}
            />
          </Check>
        </DateContainer>
      </ConsulationsSection>
      <FlexOne>
        {usersSelect.length > 0 ? (
          <UsersSelected users={usersSelect} setState={setUserSelect} />
        ) : (
          <div />
        )}

        <ButtonContainer>
          {(usersSelect.length > 0 || searchOption !== '') && (
            <Button
              className="secondary"
              label="Limpar"
              onClick={handleClear}
              styles={{ float: 'right' }}
              width="190px"
            />
          )}
          <Button
            className="primary"
            label="Buscar"
            onClick={handleSearch}
            styles={{ float: 'right' }}
            width="190px"
          />
        </ButtonContainer>
      </FlexOne>
      <ListItem className="header-list">
        {itens.map((i) => (
          <ButtonHeader label={i.label} key={i.label} />
        ))}
      </ListItem>
      {data.map((i: Data) => (
        <Accordion
          key={i.name}
          expanded={expanded === `${i.code}`}
          onChange={handleChange(`${i.code}`)}
        >
          <AccordionSummary style={{ height: '65px' }}>
            <DataList>
              <ListItem>
                <span>{i.name}</span>
                <span>{i.totalHoursInPeriod}</span>
                <span
                  className={i.negativeBalancePeriod ? 'negative' : 'positive'}
                >
                  {i.balancePeriod}
                </span>
                <span
                  className={i.negativeBalanceTotal ? 'negative' : 'positive'}
                >
                  {i.balanceTotal}
                </span>
              </ListItem>
            </DataList>
          </AccordionSummary>
          {expanded === i.code && (
            <Expanded
              startData={startDate ? format(startDate) : ''}
              endData={endDate ? format(endDate) : ''}
              code={i.code}
              memberId={i.memberId}
              name={i.name}
              isManager={isManager}
            />
          )}
        </Accordion>
      ))}
    </>
  )
}

export default Consulations
