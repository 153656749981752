import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={16}
      viewBox="0 0 21 16"
      {...props}
    >
      <path
        data-name="Caminho 805"
        d="M12 4a4 4 0 10-4 4 4 4 0 004-4zm3 2v2h6V6zM0 14v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4z"
        fill="#e74c3c"
      />
    </svg>
  )
}

export default SvgComponent
