import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={223.227}
      height={222.735}
      viewBox="0 0 223.227 222.735"
      {...props}
    >
      <g
        data-name="Grupo 109"
        fill="none"
        stroke="#cecece"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      >
        <path
          data-name="Caminho 371"
          d="M75.727 108.386c-.755 4.6-2.3 8.982-3.541 13.45q-3.178 11.472-6.372 22.938-2.605 9.348-5.238 18.69-2.685 9.558-5.381 19.114c-1.267 4.486-2.458 9-3.856 13.44a8.519 8.519 0 00.746 6.806c1.726 3.334 4.574 5.6 7.663 7.473 7.022 4.265 14.76 6.63 22.768 8.279 3.9.8 7.834 1.377 11.767 1.888 2.521.327 5.088.505 7.639.6 3.489.136 6.986.211 10.476.14q7.027-.144 14.023-.924a113.742 113.742 0 0016.132-2.894c7.278-1.906 14.322-4.392 20.393-9.043a15.3 15.3 0 005.95-8.211 8.551 8.551 0 00-.439-4.671c-2.908-10.078-5.686-20.193-8.518-30.294-2.435-8.689-4.926-17.362-7.337-26.059-2.514-9.071-5.144-18.111-7.61-27.2-.936-3.451-1.173-3.844 3.1-4.614 6.637-1.2 13.269-2.367 19.817-4 12.615-3.155 24.756-7.529 35.788-14.5 5.886-3.72 11.186-8.234 14.746-14.4 4.5-7.8 4.29-15.637-.454-23.189-4.107-6.539-10.1-11.129-16.663-15.042a117.773 117.773 0 00-25.917-11.032 184.388 184.388 0 00-18.134-4.475 69.785 69.785 0 00-7.489-1.233 8.481 8.481 0 00-3.682.333 1.73 1.73 0 01-1.577-.263c-1.981-1.1-3.191-2.956-4.654-4.562-6.078-6.671-13.4-11.2-22.376-12.725Q94.689-1.673 79.412 15.782a16.1 16.1 0 01-4.107 3.821c-.351.2-.754.325-.708.852.2 2.242-.91 4.119-1.7 6.089a35.668 35.668 0 00-2.63 17.973A20.052 20.052 0 0080.112 59.4c3.611 2.249 7.609 2.864 11.755 3.128 7.641.487 15.286.756 22.939.666a141.731 141.731 0 0017.263-.765 20 20 0 0014.474-9.111 22.107 22.107 0 003.376-14.6 42.124 42.124 0 00-3.961-14.868 7.218 7.218 0 01-.569-3.68"
        />
        <path
          data-name="Caminho 372"
          d="M74.453 20.176a8.613 8.613 0 00-5.666-.154c-6.348 1.415-12.722 2.7-18.977 4.521A140.937 140.937 0 0032.261 30.8a77.844 77.844 0 00-17.7 10.328c-4.84 3.9-9.077 8.239-11.508 14.145a20.894 20.894 0 00-1.475 9.783c.474 5.915 3.365 10.746 7.322 15.012 5.836 6.293 12.993 10.687 20.677 14.289a135.253 135.253 0 0023.77 8.414 178.39 178.39 0 0018.56 3.92c.1.014.191.134.283.131 1.523-.061 2.581 1.609 4.1 1.24a12.816 12.816 0 014.114-.223c2.7.216 5.379.584 8.073.819 2.261.2 4.53.3 6.8.434 4.153.242 8.3.5 12.46.7 6.384.311 12.748-.235 19.113-.582 4.914-.269 9.818-.733 14.726-1.112a5.009 5.009 0 012.832.567"
        />
        <path
          data-name="Caminho 373"
          d="M117.779 92.954a6.243 6.243 0 00-12.293-1.693 6.244 6.244 0 008.448 7.446c2.318-1.018 3.921-2.813 3.845-5.612"
        />
        <path
          data-name="Caminho 374"
          d="M202.449 58.971a6.048 6.048 0 01-5.384 6.345 6.357 6.357 0 01-6.814-4.214 6.319 6.319 0 1112.077-3.7 8.546 8.546 0 01.121 1.423"
        />
        <path
          data-name="Caminho 375"
          d="M20.649 58.971c-.3 3.245 2.666 6.539 6.655 6.389a6.154 6.154 0 005.805-6.53 5.914 5.914 0 00-5.807-6.061 6.245 6.245 0 00-6.531 4.638 8.436 8.436 0 00-.122 1.423"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
