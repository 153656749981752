import React, { useContext } from 'react'

import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

import Context from '../../context/Global'
import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import CheckPoint from './CheckPoint'
import Consulations from './Consulations'

import Template from '../../components/Template'

const BalanceUsers: React.FC = () => {
  const { state } = useContext(Context)
  const { profile } = state
  const isManager = profile.role === 'manager'

  return (
    <Template active="balance">
      <Container>
        <PageTitle>Banco de horas</PageTitle>
        <Section>
          <Tabs>
            <Tab title="Controle de ponto">
              <CheckPoint isManager={isManager} />
            </Tab>
            <Tab title="Consultas">
              <Consulations isManager={isManager} />
            </Tab>
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default BalanceUsers
