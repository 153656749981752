import React, { useEffect, useState } from 'react'
import { MdChevronRight, MdEvent } from 'react-icons/md'

import Input from '../../../../components/Input'
import RadioInput from '../../../../components/RadioInputs'
import Button from '../../../../components/Button'
import ColabDataComponent from './ColabData'
import { BtnGroup, Title, ContainerDrawer } from '../../StyledComponents'
import { Inputs } from './DisableUserStyles'
import { PropsUser } from '../../types/index'
import useDisabled from '../../../../hooks/useDisabled'
import create from '../../../../helpers/create'

const options = ['Pedido de demissão', 'Demissão', 'Acordo', 'Outro']

interface DrawerProps extends PropsUser {
  actionClose: any
  drawerOpen: boolean
  finalizeDelete(id: string): void
}

const DisableUser: React.FC<DrawerProps> = ({
  user,
  actionClose,
  drawerOpen,
  finalizeDelete
}) => {
  const [reason, setReason] = useState('')
  const [date, setDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled] = useDisabled([reason, date])

  useEffect(() => {
    if (!drawerOpen) {
      setReason('')
      setDate('')
    }
  }, [drawerOpen])

  const handleClickDisable = async () => {
    const data = { id: user._id, reason, date }
    await create(
      data,
      '/inative',
      'Colaborador desligado com sucesso!',
      setLoading
    )
      .then((_) => {
        finalizeDelete(data.id)
      })
      .finally(() => actionClose())
  }
  return (
    <ContainerDrawer>
      <Title>Desligar colaborador</Title>
      <ColabDataComponent user={user} />
      <Inputs>
        <Input
          label="Data do desligamento"
          iconEnd={<MdEvent size={20} />}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          mask="99/99/9999"
        />
        <RadioInput
          label="Motivo do desligamento"
          display="column"
          options={options}
          value={reason}
          setValue={setReason}
        />
      </Inputs>
      {reason === 'Outro' && <Input label="" />}
      <BtnGroup>
        <Button className="secondary" label="Cancelar" onClick={actionClose} />
        <Button
          className="primary"
          label="Avançar"
          onClick={handleClickDisable}
          iconEnd={<MdChevronRight size={24} />}
          disabled={disabled}
          loading={loading}
        />
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default DisableUser
