import React, { useEffect, useState } from 'react'
import { IProps } from './types/index'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import { MdArrowDropDown } from 'react-icons/md'

import { Data, List, Title, Titles } from './StyledComponents'
import Button from '../../../../components/Button'
import Drawer from '../../../../components/Drawer'
import AcceptCorretions from '../../../../components/AcceptCorrection'
import { GridLoader } from 'react-spinners'

type DataCorrect = {
  name: string
  date: string
  hours: any[]
  memberId: string
  reasonRequestFix: string
  olderHours: any[]
}

const Hours: React.FC<IProps> = ({
  data,
  handleClick,
  setChange,
  isManager
}) => {
  const [limitedData, setLimitedData] = useState<any[]>([])
  const [iCanCorrect, setICanCorrect] = useState(false)
  const [correctData, setCorrectData] = useState({} as DataCorrect)

  useEffect(() => {
    let arr = [...data.hours]
    if (handleClick) {
      arr.length = 5
    }
    setLimitedData(arr)
  }, [data, handleClick])

  const hanleClickInCorrect = (item: DataCorrect) => {
    setICanCorrect(true)
    setCorrectData(item)
  }

  const actionClose = () => {
    setICanCorrect(false)
  }

  return (
    <Data>
      <Drawer
        visible={iCanCorrect}
        width={952}
        onClose={() => setICanCorrect(false)}
      >
        <AcceptCorretions
          actionClose={actionClose}
          data={correctData}
          setChange={setChange}
        />
      </Drawer>

      {data ? (
        <>
          {limitedData.map((i, index) => (
            <Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                className={
                  i.pendingFix ? 'accordion-fix pedingFix' : 'accordion-item'
                }
                expandIcon={<MdArrowDropDown />}
              >
                <div className="data-infos">
                  <Titles>
                    <Title>{i.title.split('-')[0]}</Title>
                    <Title className={i.negative ? 'negative' : 'positive'}>
                      {i.title.split('-')[1]}
                    </Title>
                  </Titles>
                  {i.pendingFix && !isManager && (
                    <Button
                      className="primary"
                      label="Corrigir"
                      styles={{ alignSelf: 'flex-end' }}
                      heigth="40px"
                      onClick={() =>
                        hanleClickInCorrect({
                          name: data.name,
                          date: i.date,
                          hours: i.hours,
                          memberId: data.memberId,
                          reasonRequestFix: i.reasonRequestFix,
                          olderHours: i.olderHours
                        })
                      }
                    />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <li className="titles">
                    <span>Entrada</span>
                    <span>Saída</span>
                  </li>
                  {i.hours.map(
                    (res: {
                      in: string
                      out: string
                      changedIn: boolean
                      changedOut: boolean
                    }) => (
                      <li
                        className="hours"
                        key={`${res.in}${res.out}_${Date.now()}`}
                      >
                        <span className={res.changedIn ? 'fix' : ''}>
                          {res.in}
                        </span>
                        <span className={res.changedOut ? 'fix' : ''}>
                          {res.out}
                        </span>
                      </li>
                    )
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
          {data.hours.length > 1 && handleClick && (
            <Button
              className="only-text"
              label="Ver histórico completo"
              onClick={handleClick}
              styles={{ alignSelf: 'flex-start' }}
            />
          )}
        </>
      ) : (
        <GridLoader color="#00B7EF" size={25} />
      )}
    </Data>
  )
}

export default Hours
