import React from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Container, Box, Select } from './StyledComponents'
import Button from '../Button'
import { IProps } from './types'

const Pagination: React.FC<IProps> = ({
  page,
  totalPages,
  handleNext,
  handlePrev,
  setPage
}) => {
  let pages = []
  for (let i = 1; i <= totalPages; i += 1) {
    pages.push(i)
  }

  return (
    <Container>
      <Button
        label="Anterior"
        className="only-text"
        onClick={handlePrev}
        iconStart={<MdChevronLeft size={24} />}
        disabled={page === 1}
      />
      <Box>
        <span>Página</span>
        <Select value={page} onChange={(e) => setPage(e.target.value)}>
          {pages.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </Select>
        <span>de {totalPages}</span>
      </Box>
      <Button
        label="Próximo"
        className="only-text"
        onClick={handleNext}
        iconEnd={<MdChevronRight size={24} />}
        disabled={page === totalPages}
      />
    </Container>
  )
}

export default Pagination
