import React, { useState } from 'react'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import useApi from '../../hooks/useApi'

import EditAbout from './components/EditData'

import { List, Table, Top } from './StyledComponents'

const AboutCompany: React.FC = () => {
  const [canedit, setCanEdit] = useState(false)
  const [change, setChange] = useState(false)

  const [res] = useApi('/about', 'get', change, setChange)

  const result = {
    name: 'Usemobile',
    socialReason: 'Usemobile Soluções em Tecnologia LTDA.',
    cnpj: '25.153.072/0001-08',
    cep: '35400-000',
    uf: 'Minas Gerais',
    city: 'Ouro preto',
    district: 'Bauxita',
    address: 'Av JK900'
  }

  const aboutData = res.length === 0 ? result : res[0]

  const data = [
    { label: 'Nome da empresa', value: aboutData.name },
    { label: 'Razão social', value: aboutData.socialReason },
    { label: 'CNPJ', value: aboutData.cnpj },
    { label: 'Cep', value: aboutData.cep },
    { label: 'Estado', value: aboutData.uf },
    { label: 'Cidade', value: aboutData.city },
    { label: 'Bairro', value: aboutData.district },
    { label: 'Endereço', value: aboutData.address }
  ]

  return (
    <>
      <Drawer visible={canedit} onClose={() => setCanEdit(false)} width={849}>
        <EditAbout
          data={aboutData}
          setChange={setChange}
          actionClose={() => setCanEdit(false)}
        />
      </Drawer>
      <Top>
        <Button
          label="Atualizar dados"
          className="primary"
          onClick={() => setCanEdit(true)}
          width="243px"
        />
      </Top>
      <Table>
        {data.map((i) => (
          <List key={i.value}>
            <span>{i.label}</span>
            <span>{i.value}</span>
          </List>
        ))}
      </Table>
    </>
  )
}

export default AboutCompany
