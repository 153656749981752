import styled from 'styled-components'

export const SelectContainer = styled.div`
  margin-top: 1rem;
`

export const Check = styled.div`
  display: flex;
  margin-top: 1rem;
  span {
    margin-left: 10px;
  }
`
