import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SectionTop = styled.div`
  display: grid;
  grid-template-columns: 350px 293px repeat(2, 190px);
  grid-auto-flow: column;
  gap: 0.8em;
  padding: 1.5em 0;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const SectionBottom = styled.div`
  display: grid;
  grid-template-columns: 350px 350px;
  gap: 0.8em;
  margin-top: 1.5em;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const ButtonContainer = styled.div`
  float: right;
  margin: 0 0 auto;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

export const TopRigthSearch = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5em 0;
`

export const List = styled.ul`
  width: 100%;
`
export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #bfbfbf;
`

export const Infos = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
  }

  span:first-child {
    font-size: 15px;
    font-weight: 600;
    color: #212121;
  }

  span {
    color: #828282;
  }
`

export const DownloadAndView = styled.div`
  display: flex;

  button:nth-child(n + 1) {
    margin-left: 1em;
  }
`
