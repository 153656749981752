import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import ButtonHeader from '../../../../components/FilterHeader'
import { Actions, ButtonAction } from '../../../../GlobalStyledComponents'
import orderArray from '../../../../utils/orderArray'
import deleteFn from '../../../../helpers/delete'
import { Item, List } from '../../StyledComponents'
import Popconfirm from '../../../../components/PopConfirm'
import { IProps } from './types'

import Drawer from '../../../../components/Drawer'
import Edit from '../AddOffice/edit'

const officesFilters = [
  { label: 'Nome', value: '' },
  { label: 'Nº de colaboradores', value: '' },
  { label: 'Ações', value: '' }
]

const OfficesList: React.FC<IProps> = ({ result, setChange }) => {
  const [data, setData] = useState<any[]>([])
  const [orderBy, setOrderBy] = useState<string | number>()
  const [canEdit, setCanEdit] = useState(false)
  const [dataEdit, setDataEdit] = useState({})

  useEffect(() => {
    const getData = () => {
      if (orderBy !== undefined) {
        const n = [...result]
        const arr = orderArray(orderBy, n)
        setData(arr)
      } else {
        setData(result)
      }
    }
    getData()
  }, [result, orderBy])

  const handleSetOrder = (value: string | number) => {
    orderBy === value ? setOrderBy('') : setOrderBy(value)
  }

  const handleDelete = async (id: string, index: number) =>
    await deleteFn(id, '/office', 'Cargo excuído com succeso.').then((_) => {
      const arr = [...data]
      arr.splice(index, 1)
      setData([...arr])
    })

  const handleEdit = (item: any) => {
    setDataEdit(item)
    setCanEdit(true)
  }

  return (
    <List>
      <Drawer width={952} visible={canEdit} onClose={() => setCanEdit(false)}>
        <Edit
          data={dataEdit}
          actionClose={() => setCanEdit(false)}
          setChange={setChange}
        />
      </Drawer>
      <Item>
        {officesFilters.map((i) => (
          <ButtonHeader
            label={i.label}
            clickable={!!i.value}
            onClick={() => handleSetOrder(i.value)}
          />
        ))}
      </Item>
      {data.map((i, index) => (
        <Item key={i.value}>
          <span>{i.label}</span>
          <span>{i.colabs}</span>
          <Actions>
            <Tooltip placement="bottom" title="Editar">
              <ButtonAction onClick={() => handleEdit(i)}>
                <MdEdit size={21} className="tools" />
              </ButtonAction>
            </Tooltip>

            <Tooltip placement="bottom" title="Apagar">
              <Popconfirm
                placement="top"
                title="Deseja mesmo excluir este item?"
                onConfirm={() => handleDelete(i._id, index)}
                okText="Sim"
                cancelText="Não"
              >
                <ButtonAction type="button">
                  <MdDelete size={21} className="delete" />
                </ButtonAction>
              </Popconfirm>
            </Tooltip>
          </Actions>
        </Item>
      ))}
    </List>
  )
}

export default OfficesList
