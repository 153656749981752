import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27}
      height={20}
      viewBox="0 0 27 20"
      {...props}
    >
      <g data-name="Grupo 21736">
        <path
          data-name="Caminho 805"
          d="M12 7a4 4 0 10-4 4 4 4 0 004-4zM0 17v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4z"
          fill="#757575"
        />
        <path data-name="Ret\xE2ngulo 2140" fill="none" d="M7 0h20v20H7z" />
        <path
          data-name="Caminho 843"
          d="M13.603 16.506zm2.476-9.912v6.607h1.651V6.594h2.473l-3.3-3.293-3.3 3.293z"
          fill="#757575"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
