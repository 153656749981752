import React from 'react'

import { GridLoader } from 'react-spinners'
import { ButtonContainer } from './StyledComponents'
import { ButtonProps } from './types'

const Button: React.FC<ButtonProps> = ({
  className,
  label,
  onClick,
  iconStart,
  iconEnd,
  loading,
  styles,
  width = '150px',
  heigth = '47px',
  ...rest
}) => {
  return (
    <ButtonContainer
      width={width}
      heigth={heigth}
      className={className}
      onClick={onClick}
      style={styles}
      {...rest}
    >
      {iconStart && !loading && iconStart}
      {loading ? <GridLoader size={5} color="#fff" /> : label}
      {iconEnd && !loading && iconEnd}
    </ButtonContainer>
  )
}

export default Button
