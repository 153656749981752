import React, { useCallback, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import SearchInput from '../../components/SearchInput'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import Pagination from '../../components/Pagination'
import useApi from '../../hooks/useApi'

import { TopRigthSearch } from './StyledComponents'
import List from './components/OfficesList'
import AddOfiice from './components/AddOffice'
import debounce from 'lodash.debounce'

const Colabs: React.FC = () => {
  const [canAdd, setCanAdd] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [change, setChange] = useState(false)
  const [endSearch, setEndSearch] = useState('')

  const [result, extra] = useApi(
    `/office/?filter=${endSearch}&fields=label&page=${page}&limit=10&sort=name&order=1`,
    'get',
    change,
    setChange
  )

  // eslint-disable-next-line
  const verify = useCallback(
    debounce((res) => {
      setEndSearch(res)
    }, 500),
    []
  )

  const handleSearch = (value: string) => {
    setSearch(value)
    verify(value)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <>
      <Drawer width={746} visible={canAdd} onClose={() => setCanAdd(false)}>
        <AddOfiice actionClose={() => setCanAdd(false)} setChange={setChange} />
      </Drawer>
      <TopRigthSearch>
        <Button
          width="243px"
          className="primary"
          label="Cadastrar cargo"
          onClick={() => setCanAdd(true)}
          iconStart={<MdAdd size={20} />}
        />
        <SearchInput
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </TopRigthSearch>
      <List result={result} setChange={setChange} />
      {extra.totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Colabs
