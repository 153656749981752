import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdEdit, MdMoreHoriz } from 'react-icons/md'
import ToManager from '../../icons/ToManager'
import { Actions, ButtonAction } from '../../../../GlobalStyledComponents'
import firstAndLastName from '../../../../utils/firstAndLastName'
import { Item, List } from '../../StyledComponents'
import { filtersHeader } from '../../constants/filtersHeader'
import ButtonHeader from '../../../../components/FilterHeader'
import PersonRemove from '../../icons/personremove'
import { UserProps } from '../../../../interfaces/user'
import userPropObj from '../../../../constants/userProps'
import Drawer from '../../../../components/Drawer'
import Button from '../../../../components/Button'
import Promote from '../Promote'
import DisableUser from '../DisableUser'
import EditUser from '../EditUser'
import { getUser } from '../../controllers/getUser'
import { IProps } from './types'
import { Dispatch } from 'react'
import { SetStateAction } from 'react'

const config = {
  role: 'button',
  tabIndex: 0
}

const ActiveList: React.FC<IProps> = ({
  result,
  setViewMore,
  setUserViewMore,
  setChange,
  setOrder,
  setSort,
  sortBy,
  filterItems,
  realoadOptions,
  isManager
}) => {
  const [userDisable, setUserDisable] = useState<UserProps>({ ...userPropObj })
  const [disableDrawerIsVisible, setDisableDraweIsVisible] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [canPromote, setCanPromote] = useState(false)
  const [userPromoted, setUserPromoted] = useState<UserProps>({
    ...userPropObj
  })
  const [canEdit, setCanEdit] = useState(false)
  const [user, setUser] = useState<UserProps>({
    ...userPropObj
  })

  const getUserFN = async (
    id: string,
    setUserHook: Dispatch<SetStateAction<UserProps>>,
    setOpen: Dispatch<SetStateAction<boolean>>
  ) => {
    await getUser(id).then((res) => {
      setUserHook(res)
      setOpen(true)
    })
  }

  useEffect(() => {
    setData(result.filter((i) => !i.disabled))
  }, [result])

  const handleSetOrder = (value: string, order: string) => {
    setSort(value)
    setOrder(order)
  }

  const handleViewMore = async (id: string) => {
    await getUserFN(id, setUserViewMore, setViewMore)
  }

  const handleClickPromote = async (id: string) => {
    await getUserFN(id, setUserPromoted, setCanPromote)
  }

  const handleDisable = async (id: string) => {
    await getUserFN(id, setUserDisable, setDisableDraweIsVisible)
  }

  const handleEdit = async (id: string) => {
    await getUserFN(id, setUser, setCanEdit)
  }

  const handleFinalizeDelete = (id: string) => {
    const arr = [...data]
    const index = arr.indexOf(id)
    if (index > -1) {
      arr.splice(index, 1)
      setData([...arr])
    }
  }

  const actionCloseDisable = () => {
    setDisableDraweIsVisible(false)
    setChange(true)
  }

  const onCloseEdit = () => {
    setCanEdit(false)
    setUser({ ...userPropObj })
  }

  return (
    <>
      <Drawer
        width={920}
        visible={disableDrawerIsVisible}
        onClose={() => setDisableDraweIsVisible(false)}
      >
        <DisableUser
          user={userDisable}
          actionClose={actionCloseDisable}
          drawerOpen={disableDrawerIsVisible}
          finalizeDelete={handleFinalizeDelete}
        />
      </Drawer>

      <Drawer
        width={752}
        visible={canPromote}
        onClose={() => setCanPromote(false)}
      >
        <Promote
          user={userPromoted}
          actionClose={() => setCanPromote(false)}
          filterItems={filterItems}
        />
      </Drawer>
      <Drawer width={920} visible={canEdit} onClose={onCloseEdit}>
        <EditUser
          actionClose={onCloseEdit}
          user={user}
          filterItems={filterItems}
          setChange={setChange}
          reloadOptions={realoadOptions}
        />
      </Drawer>
      <List>
        <Item>
          {filtersHeader.map((i) => (
            <div className="list-data">
              <ButtonHeader
                label={i.label}
                clickable={!!i.value}
                clickTop={() => handleSetOrder(i.value, '-1')}
                clickBotttom={() => handleSetOrder(i.value, '1')}
                key={i.value}
                sortBy={sortBy}
                value={i.value}
              />
            </div>
          ))}
        </Item>
        {data.map((i) => (
          <Item key={i.acessCode}>
            <div
              className="list-data"
              onClick={() => handleViewMore(i._id)}
              {...config}
            >
              <span>{i.acessCode}</span>
            </div>
            <div
              className="list-data"
              onClick={() => handleViewMore(i._id)}
              {...config}
            >
              <span>{firstAndLastName(i.name)}</span>
            </div>

            <div
              className="list-data"
              onClick={() => handleViewMore(i._id)}
              {...config}
            >
              <span>
                {i.workingModel === 'Remoto'
                  ? 'Home office'
                  : 'Sede em Ouro Preto'}
              </span>
            </div>

            <div
              className="list-data"
              onClick={() => handleViewMore(i._id)}
              {...config}
            >
              <span>{i.office}</span>
            </div>

            <div
              className="list-data"
              onClick={() => handleViewMore(i._id)}
              {...config}
            >
              <span>{i.department}</span>
            </div>

            {!isManager ? (
              <Actions>
                <Tooltip placement="bottom" title="Ver mais">
                  <ButtonAction onClick={() => handleViewMore(i._id)}>
                    <MdMoreHoriz size={21} className="tools" />
                  </ButtonAction>
                </Tooltip>

                {i.hierarchy !== 'Gestor' && (
                  <Tooltip placement="bottom" title="Tornar gestor">
                    <ButtonAction onClick={() => handleClickPromote(i._id)}>
                      <ToManager />
                    </ButtonAction>
                  </Tooltip>
                )}

                <Tooltip placement="bottom" title="Editar">
                  <ButtonAction onClick={() => handleEdit(i._id)}>
                    <MdEdit size={21} className="tools" />
                  </ButtonAction>
                </Tooltip>

                <Tooltip placement="bottom" title="Desligar">
                  <ButtonAction onClick={() => handleDisable(i._id)}>
                    <PersonRemove />
                  </ButtonAction>
                </Tooltip>
              </Actions>
            ) : (
              <Button
                label="Ver mais"
                onClick={() => handleViewMore(i._id)}
                className="only-text"
              />
            )}
          </Item>
        ))}
      </List>
    </>
  )
}

export default ActiveList
