import React from 'react'
import { MdCheck } from 'react-icons/md'
import { CheckItem } from './StyledComponents'

interface CheckBoxProps {
  checked: boolean
  handleClick: any
}

const StyledCheckBox: React.FC<CheckBoxProps> = ({ checked, handleClick }) => {
  return (
    <CheckItem
      onClick={handleClick}
      type="button"
      className={checked ? 'marked' : ''}
    >
      {checked && <MdCheck color="#fff" />}
    </CheckItem>
  )
}

export default StyledCheckBox
