import React, { useContext } from 'react'

import Context from '../../context/Global'

import Tabs from '../../components/Tabs'
import Tab from '../../components/Tabs/Tab'
import Template from '../../components/Template'

import Active from './Active'
import Inatives from './Inatives'
import Offices from './Offices'
import Groups from './Groups'
import Departments from './Departments'

import { Container, Section, PageTitle } from '../../GlobalStyledComponents'

const Colabs: React.FC = () => {
  const { state } = useContext(Context)
  const { profile } = state
  const isManager = profile.role === 'manager'

  return (
    <Template active="colabs">
      <Container>
        <PageTitle>Colaboradores</PageTitle>
        <Section>
          <Tabs>
            <Tab
              title={
                !isManager ? 'Colaboradores ativos' : 'Colaboradores geridos'
              }
            >
              <Active isManager={isManager} />
            </Tab>

            {!isManager ? (
              <Tab title="Cargos">
                <Offices />
              </Tab>
            ) : (
              <div style={{ display: 'none' }} />
            )}

            {!isManager ? (
              <Tab title="Grupos">
                <Groups />
              </Tab>
            ) : (
              <div style={{ display: 'none' }} />
            )}

            {!isManager ? (
              <Tab title="Departamentos">
                <Departments />
              </Tab>
            ) : (
              <div style={{ display: 'none' }} />
            )}

            {!isManager ? (
              <Tab title="Desligados">
                <Inatives />
              </Tab>
            ) : (
              <div style={{ display: 'none' }} />
            )}

            <div />
          </Tabs>
        </Section>
      </Container>
    </Template>
  )
}

export default Colabs
