const handleSelectAll = (
  arrRef: any[],
  setHook: any,
  arrRef2: any[],
  filters: any[],
  setFilters: any
) => {
  if (arrRef.length !== arrRef2.length) {
    let arr = arrRef.map((item) => {
      return item.value
    })
    const newarr = [...arr, ...filters]
    const newfilters = newarr.filter((este, i) => newarr.indexOf(este) === i)
    setFilters(newfilters)
    setHook(arr)
  }
}

export default handleSelectAll
