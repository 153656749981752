import React, { useContext } from 'react'
import { MdChevronLeft } from 'react-icons/md'

import Context from '../../../../context/Global'

import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'

import { UserProps } from '../../../../interfaces/user'

import { MainContainer } from './StyledComponents'
import MainData from './MainData'
import BankData from './BankData'
import Infos from './Infos'
import Button from '../../../../components/Button'
import fisrtAndLastName from '../../../../utils/firstAndLastName'

interface IProps {
  data: UserProps
  actionClose: any
}

const ViewMore: React.FC<IProps> = ({ data, actionClose }) => {
  const { state } = useContext(Context)
  const { profile } = state
  const isManager = profile.role === 'manager'

  return (
    <MainContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          width: '100%',
          paddingLeft: '4.5em'
        }}
      >
        <div>
          <Button
            label="Voltar"
            className="only-text"
            onClick={actionClose}
            iconStart={<MdChevronLeft size={24} />}
          />
          <h2>{fisrtAndLastName(data.name)}</h2>
        </div>

        <Tabs>
          <Tab title="Dados principais">
            <MainData data={data} isManager={isManager} />
          </Tab>
          {!isManager ? (
            <Tab title="Dados bancários">
              <BankData data={data} />
            </Tab>
          ) : (
            <div />
          )}
          <Tab title="Informações adicionais">
            <Infos data={data} />
          </Tab>
        </Tabs>
      </div>
    </MainContainer>
  )
}

export default ViewMore
