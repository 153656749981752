import React from 'react'
import { MenuDrop } from '../../interfaces/menu_drop'
import { MdNotifications } from 'react-icons/md'

import DropDown from '../DropDown'

import { Container } from './styles'

const Notifications: React.FC<MenuDrop> = ({
  observedOpen,
  open,
  handleOpen
}) => {
  React.useEffect(() => {
    if (observedOpen) {
      handleOpen(false)
    }
  }, [observedOpen, handleOpen])
  const handleOpenNotifications = (): void => handleOpen(!open)

  return (
    <Container role="button" tabIndex={0} onClick={handleOpenNotifications}>
      <MdNotifications size={30} color="#fff" />
      <DropDown open={open} />
    </Container>
  )
}

export default Notifications
