import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import List from './components/List'
import Button from '../../components/Button'
import InputSearch from '../../components/SearchInput'
import Paginate from '../../components/Pagination'
import SearchInfo from '../../components/SearchInfo'

import { MainContainer, Content, TopRigthSearch } from './StyledComponents'
import useApi from '../../hooks/useApi'

const Reports: React.FC = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [result, extra] = useApi(
    `/reports?filter=${search}&fields=type&page=${page}&limit=15&sort=createdAt&order=-1`,
    'get'
  )

  const clear = () => {
    setSearch('')
  }
  return (
    <MainContainer>
      <TopRigthSearch>
        <Button
          className="primary"
          label="Gerar relatório"
          iconStart={<MdAdd size={24} />}
          onClick={() => {}}
          width="243px"
        />
        <InputSearch
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </TopRigthSearch>
      {search !== '' && (
        <SearchInfo text={search} result={result.length} clearSearch={clear} />
      )}
      <Content>
        <List result={result} />
      </Content>
      {extra.totalPages > 1 && (
        <Paginate
          page={page}
          totalPages={extra.totalPages}
          handleNext={() => setPage(page + 1)}
          handlePrev={() => setPage(page - 1)}
          setPage={setPage}
        />
      )}
    </MainContainer>
  )
}

export default Reports
