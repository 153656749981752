import styled from 'styled-components'

export const DivTitle = styled.div`
  display: flex;
  border-bottom: 1px solid #bfbfbf;
`

export const Title = styled.span`
  font-size: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 1650px) {
    font-size: 16px;
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  div.btns {
    margin-top: 4rem;
  }
`
export const SearchSection = styled.div`
  display: grid;
  grid-template-columns: 396px auto;
  gap: 12rem;
`
