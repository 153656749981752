import React from 'react'
import { DatePicker } from 'antd'
import ptBr from 'antd/es/date-picker/locale/pt_BR'
import 'moment/locale/pt-br'

import { IProps } from './types'

import { Container } from './StyledComponents'
import { Moment } from 'moment'

const DatePickerFC: React.FC<IProps> = ({
  value,
  onChange,
  label = 'Data',
  marginLeft
}) => {
  const dateFormat = 'DD/MM/YYYY'
  const styles = {
    height: '56px',
    borderRadius: '4px',
    maxWidth: '210px',
    width: '100%'
  }

  const handleChange = (date: Moment | null) => {
    onChange(date)
  }

  return (
    <Container marginLeft={marginLeft}>
      {label}
      <DatePicker
        locale={ptBr}
        value={value}
        onChange={handleChange}
        style={styles}
        format={dateFormat}
      />
    </Container>
  )
}

export default DatePickerFC
