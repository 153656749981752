import styled from 'styled-components'

export const List = styled.ul`
  width: 100%;

  label.title-list {
    font-size: 20px;
  }
  max-width: 800px;
`

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  padding: 15px 10px;
  border-bottom: 1px solid #cecece;
  align-items: center;
  span.fixed {
    background: ${(props) => props.theme.yellow};
    width: max-content;
    padding: 2px;
  }

  &.header {
    background: #f5f7f9;
    color: #383838;
  }

  span.title {
    font-weight: 600;
  }

  span.reason {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 10px 0 0;
  }
`
