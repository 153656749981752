import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.background};
  overflow: hidden;
  display: flex;
`
export const ContentSection = styled.div`
  width: 100%;
  background: ${(props) => props.theme.backgroundSecondary};
  margin: 6.5rem 1rem 1rem 1rem;
  overflow: scroll;
`
