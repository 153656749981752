import React from 'react'

import Avatar from '../Avatar'

import { IProps } from './types'
import { MdClose } from 'react-icons/md'

import { removeUser } from './utils/removeUser'

import {
  Container,
  Title,
  UserList,
  User,
  RemoveButton,
  Infos,
  FooterText
} from './StyledComponents'

const UsersSelected: React.FC<IProps> = ({
  users,
  setState,
  label = 'Colaboradores selecionados',
  emptyMessage = 'Ainda não há colaboradores selecionados.'
}) => {
  const handleClickOnRemove = (index: number) => {
    const arr = removeUser(users, index)
    setState([...arr])
  }

  return (
    <Container>
      <Title>{label}</Title>
      <UserList>
        {users.map((i, index) => (
          <User key={i.name}>
            <Infos>
              <Avatar data={i} styles={{ w: 32, h: 32 }} />
              <span>{i.name}</span>
            </Infos>
            <RemoveButton
              type="button"
              onClick={() => handleClickOnRemove(index)}
            >
              Remover
              <MdClose size={25} />
            </RemoveButton>
          </User>
        ))}
      </UserList>
      {users.length > 0 ? (
        <FooterText>{`${users.length} ${
          users.length > 1 ? 'colaboradores' : 'colaborador'
        }`}</FooterText>
      ) : (
        <FooterText>{emptyMessage}</FooterText>
      )}
    </Container>
  )
}

export default UsersSelected
