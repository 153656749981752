import React, { useState } from 'react'
import RadioInputs from '../../../../RadioInputs'

import { ListItem, Data } from '../StyledComponents'

interface IProps {
  data: any
  containResult?: boolean
}

const ListItemFC: React.FC<IProps> = ({ data, containResult }) => {
  const [approved, setApproved] = useState('')
  return (
    <ListItem>
      <Data>
        <span>{data.type}</span>
        <span>{data.period}</span>
        <span>{data.date}</span>
        <span>{data.balance}</span>
        <span>{data.managerAproved}</span>
      </Data>
      {!containResult ? (
        <RadioInputs
          options={['Aprovar', 'Reprovar']}
          value={approved}
          setValue={setApproved}
        />
      ) : (
        <div>
          <span
            className={data.status === 'Aprovado' ? 'approved' : 'reproved'}
          >
            {data.status}
          </span>
        </div>
      )}
    </ListItem>
  )
}

export default ListItemFC
