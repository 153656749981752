import React, { useEffect, useMemo, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import {
  BtnGroup,
  ContainerDrawer,
  Title
} from '../../../../GlobalStyledComponents'

import Input from '../../../../components/Input'
import SearchUser from '../../../../components/SearchUser'
import Button from '../../../../components/Button'
import UsersSelected from '../../../../components/UsersSelected'
import CheckBox from '../../../../components/StyledCheckBox'
import returnData from './utils/returnData'
import setDatas from '../EditUser/utils/setDatas'
import create from '../../../../helpers/create'
import { Section, InputAndCheck } from './StyledComponents'
import searchHelper from '../../../../helpers/searchUser'
import { IProps } from './types'

const AddDepartment: React.FC<IProps> = ({
  setChange,
  actionClose,
  department
}) => {
  const [usersSelected, setUsersSelected] = useState([])
  const [userName, setUserName] = useState('')
  const [result, setResult] = useState([])
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [notManager, setNotManager] = useState(false)

  const datas = useMemo(() => {
    return [
      { setHook: setName, key: 'name' },
      { setHook: setUsersSelected, key: 'managers' }
    ]
  }, [])

  useEffect(() => setDatas(datas, department), [department, datas])

  const searchUser = async (value: string) => {
    setUserName(value)
    await searchHelper(value, setResult)
  }
  const handleSubmit = async () => {
    const data = !notManager
      ? await returnData(name, usersSelected)
      : { name, value: name }
    await create(
      data,
      `/department/update/${department._id}`,
      'Departamento editado com sucesso.',
      setLoading
    )
      .then((_) => {
        if (setChange) {
          setChange(true)
        }
      })
      .finally(() => actionClose())
  }

  const buttons = [
    { label: 'Cancelar', primary: false, onClick: () => actionClose() },
    {
      label: 'Avançar',
      primary: true,
      onClick: () => handleSubmit(),
      iconEnd: <MdChevronRight size={24} />,
      loading
    }
  ]

  const clearArray = () => {
    setNotManager(true)
    setUsersSelected([])
  }

  const handleCheck = () => {
    notManager ? setNotManager(false) : clearArray()
  }

  return (
    <ContainerDrawer>
      <Title>Cadastrar novo departamento</Title>
      <Section>
        <Input
          label="Nome do departamento"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputAndCheck>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <SearchUser
              data={result}
              arraySelected={usersSelected}
              setData={setUsersSelected}
              value={userName}
              onChange={(e) => searchUser(e.target.value)}
              disabled={notManager}
              label="Gestor(a)"
              placeholder="Buscar"
            />
          </div>
          <div className="check">
            <CheckBox
              checked={notManager ? true : false}
              handleClick={handleCheck}
            />
            <span>Não vincular a nenhum gestor(a).</span>
          </div>
        </InputAndCheck>
        {usersSelected.length > 0 && (
          <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <UsersSelected users={usersSelected} setState={setUsersSelected} />
          </div>
        )}
      </Section>

      <BtnGroup>
        {buttons.map((i) => (
          <Button
            className={i.primary ? 'primary' : 'secondary'}
            label={i.label}
            onClick={() => i.onClick()}
            iconEnd={i.iconEnd}
            loading={i.loading}
            key={i.label}
          />
        ))}
      </BtnGroup>
    </ContainerDrawer>
  )
}

export default AddDepartment
