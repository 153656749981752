import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        data-name="Caminho 401"
        d="M19 12v7H5v-7H3v7a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2v-7zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"
        fill="#757575"
        stroke="#757575"
        strokeWidth={0.5}
      />
      <path data-name="Caminho 402" d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export default SvgComponent
