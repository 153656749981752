import styled from 'styled-components'

export const Container = styled.div`
  span.negative {
    color: ${(props) => props.theme.red};
  }

  span.positive {
    color: ${(props) => props.theme.green};
  }

  div.MuiAccordion-rounded {
    width: 100%;
  }

  div.MuiButtonBase-root.MuiAccordionSummary-root.accordion-item
    > div.MuiAccordionSummary-content {
    height: 40px;
  }

  div.MuiButtonBase-root.MuiAccordionSummary-root.accordion-fix.pedingFix
    > div.MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  div.MuiButtonBase-root.MuiAccordionSummary-root.accordion-fix.pedingFix {
    background: ${(props) => props.theme.yellow};
  }
`

export const InfosTop = styled.div`
  display: flex;
  flex-direction: column;
  span {
    padding: 5px 0;
  }
`

export const TitleTop = styled.h2`
  font-weight: 600;
`

export const List = styled.ul`
  li {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    padding: 5px 0;
  }
  li.titles {
    text-transform: uppercase;
    font-weight: 600;
  }
  li.hours {
    padding: 10px 0;
  }

  li span.fix {
    background: ${(props) => props.theme.yellow};
  }
`

export const Titles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
`

export const Section = styled.section`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
