import React, { useMemo } from 'react'
import ListItem from './ListItem'

import { List } from './StyledComponents'

interface IProps {
  rgFront?: string
  rgBack?: string
  docCollege?: string
  cnpjDoc?: string
}

const DocList: React.FC<IProps> = ({
  rgFront,
  rgBack,
  docCollege,
  cnpjDoc
}) => {
  const data = useMemo(() => {
    const files = [
      { file: rgFront, label: 'RG (Frente)' },
      { file: rgBack, label: 'RG (Verso)' },
      { file: docCollege, label: 'Comprovante de matrícula' },
      { file: cnpjDoc, label: 'CNPJ' }
    ]
    const item: any[] = []
    files.forEach((i) => {
      if (i.file) {
        item.push(i)
      }
    })

    return item
  }, [rgBack, rgFront, docCollege, cnpjDoc])

  return (
    <List>
      {data.map((i) => (
        <ListItem item={i.file} key={i.file} label={i.label} />
      ))}
    </List>
  )
}

export default DocList
