import styled from 'styled-components'
import { ButtonProps } from '../types'

export const Label = styled.label`
  display: block;
`

export const InputContainer = styled.div<ButtonProps>`
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.icon-start-input input {
    padding: 0 3em;
  }

  input {
    background: transparent;
    transition: 0.2s all;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: 0.2s all;
    padding: 10px;

    &:focus {
      border-bottom: 2px solid ${(props) => props.theme.primary_blue};
    }

    &.error {
      border-bottom: 2px solid ${(props) => props.theme.red};
    }
  }
  &.max-width {
    width: ${(props) => props.width};
  }
`

export const Span = styled.span`
  color: #383838;

  &.label-focus {
    color: ${(props) => props.theme.primary_blue};
    font-weight: 700;
  }

  &.error {
    color: ${(props) => props.theme.red};
    font-weight: 700;
  }
`

export const Container = styled.div`
  display: flex;
  position: relative;

  .icon-start {
    position: absolute;
    left: 10px;
    top: 1em;
    z-index: 10;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconEnd = styled.div`
  display: flex;
  align-items: center;
  margin-left: -25px;
`
