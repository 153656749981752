import api from '../../../services/api_max'
import { onlyNumbers } from '../../../utils/onlyNumbers'
import formatTerms from '../utils/formatTerms'

const sendTerms = async (usersSelected: any[], terms: string[]) => {
  return new Promise((resolve, reject) => {
    usersSelected.forEach(async (item) => {
      const userEnv = {
        name: item.name,
        email: item.email,
        ctps: item.ctps,
        ctpsSerie: item.ctpsSerie,
        cpf: item.cpf,
        rg: onlyNumbers(item.idRg),
        address: item.address,
        district: item.district,
        city: item.city,
        uf: item.uf,
        admissionDate: item.admissionDate,
        terms: formatTerms(terms)
      }
      await api
        .post('/terms', { ...userEnv })
        .then((res) => {
          resolve(res.status)
        })
        .catch((err) => {
          reject(err.response.status)
        })
    })
  })
}

export default sendTerms
