import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.972"
      height="14.972"
      viewBox="0 0 14.972 14.972"
      {...props}
    >
      <path
        fill="#383838"
        d="M14.645 9.654V3H6.327v3.327H3v11.645h6.654v-3.327h1.664v3.327h6.654V9.654zm-8.318 6.655H4.664v-1.664h1.663zm0-3.327H4.664v-1.664h1.663zm0-3.327H4.664V7.991h1.663zm3.327 3.327H7.991v-1.664h1.663zm0-3.327H7.991V7.991h1.663zm0-3.327H7.991V4.664h1.663zm3.327 6.654h-1.663v-1.664h1.664zm0-3.327h-1.663V7.991h1.664zm0-3.327h-1.663V4.664h1.664zm3.327 9.982h-1.663v-1.665h1.664zm0-3.327h-1.663v-1.665h1.664z"
        data-name="Caminho 838"
        transform="translate(-3 -3)"
      ></path>
    </svg>
  )
}

export default SvgComponent
