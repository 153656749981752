import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30.001}
      viewBox="0 0 30 30.001"
      {...props}
    >
      <path
        data-name="Caminho 840"
        d="M26.25 0H3.75A3.761 3.761 0 000 3.75v22.5A3.761 3.761 0 003.75 30h22.5A3.761 3.761 0 0030 26.25V3.75A3.761 3.761 0 0026.25 0zM10.313 14.063A2.809 2.809 0 017.5 16.875H5.625v3.75H2.813V9.375H7.5a2.809 2.809 0 012.813 2.813zm9.375 3.75a2.809 2.809 0 01-2.812 2.813h-4.688V9.375h4.688a2.809 2.809 0 012.812 2.813zm7.5-5.625h-2.813v1.875h2.812v2.813h-2.812v3.75h-2.812V9.375h5.625zM5.625 14.063H7.5v-1.875H5.625zM15 17.813h1.875v-5.625H15z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgComponent
