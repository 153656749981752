import styled from 'styled-components'

interface BarProps {
  width: string
}

export const Progress = styled.div`
  width: 166px;
  height: 11px;
  background: ${(props) => props.theme.red};
`

export const Bar = styled.div<BarProps>`
  height: 100%;
  width: ${(props) => props.width};
  background: ${(props) => props.theme.green};
`

export const ViewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: auto;
  gap: 10em;
  color: #383838;
`

export const ViewsList = styled.ul`
  width: 450px;
`

export const ViewsItem = styled.li`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 225px);
`

export const ViewTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`

export const ViewSpan = styled.span`
  font-size: 14px;
`
export const TitlesBox = styled.div`
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, 225px);
  width: 100%;
`
