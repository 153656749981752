import React, { useEffect, useState } from 'react'
import {
  BtnGroup,
  ContainerDrawer,
  Title,
  FlexSection
} from '../../GlobalStyledComponents'
import Button from '../Button'
import { Container, Infos } from './StyledComponents'
import List from './components/List'
import formatData from '../../utils/formatData'
import Input from '../Input'
import saveCorrections from './helpers/saveCorrection'

type DataCorrect = {
  name: string
  date: string
  hours: any[]
  memberId: string
  reasonRequestFix: string
  olderHours: any[]
}

interface IProps {
  actionClose: any
  containResult?: boolean
  data: DataCorrect
  setChange?: any
}

const AcceptCorrection: React.FC<IProps> = ({
  actionClose,
  containResult = false,
  data,
  setChange
}) => {
  // const [action, setAction] = useState('Aprovar')
  const [containDontAccept, setContainDontAccept] = useState(false)
  const [reason, setReason] = useState('')
  const [editableData, setEditableData] = useState<any[]>([])

  useEffect(() => {
    setEditableData([...data.hours])
  }, [data.hours])
  // retornar valores

  const handleSave = async () => {
    await saveCorrections({
      date: data.date,
      reason,
      member: data.memberId,
      hours: editableData
    }).then((_) => {
      actionClose()
      if (setChange) {
        setChange(true)
      }
    })
  }

  const buttons = [
    {
      label: 'Cancelar',
      className: 'secondary',
      width: '164px',
      onclick: () => actionClose()
    },
    {
      label: 'Salvar',
      className: 'primary',
      width: '164px',
      onclick: () => handleSave()
    }
  ]

  return (
    <ContainerDrawer>
      <Title>Solicitação de ajuste de ponto</Title>
      <Container>
        <Infos>
          <div>
            <span>Solicitante:</span>
            <span>{data.name}</span>
          </div>
          <div>
            <span>Data:</span>
            <span>{data.date.replaceAll('-', '/')}</span>
          </div>
          {containResult && (
            <>
              <div>
                <span>Correção referente ao dia:</span>
                <span>{formatData(new Date())}</span>
              </div>
              <div>
                <span>Corrigido em:</span>
                <span>{formatData(new Date())}</span>
              </div>
            </>
          )}
        </Infos>
        <FlexSection>
          <List
            editableData={editableData}
            setEditableData={setEditableData}
            old={data.olderHours}
            title="Correção"
            setContainDontAccept={setContainDontAccept}
          />
        </FlexSection>

        {containDontAccept && (
          <Input
            label="Justificativa para desaprovação"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        )}
      </Container>
      {!containResult && (
        <BtnGroup>
          {buttons.map((i) => (
            <Button
              label={i.label}
              className={i.className === 'primary' ? 'primary' : 'secondary'}
              onClick={() => i.onclick()}
            />
          ))}
        </BtnGroup>
      )}
    </ContainerDrawer>
  )
}

export default AcceptCorrection
