import React from 'react'
import { Avatar } from 'antd'
import Context from '../../context/Global'

import Notifications from '../Notifications'
import Menu from '../Menu'
import Logo from '../LogoMemorian'

import { Darkmode } from '../../interfaces/main_interfaces'

import { Container, UserHi, Items } from './styles'
import setToken from '../../utils/setToken'

const Header: React.FC<Darkmode> = ({ darkmode }) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const [openNotification, setOpenNotification] = React.useState(false)
  const dark = {
    backgroundColor: '#383838',
    borderBottom: '3px solid #40C9F3',
    boxShadow: 'none'
  }

  const ligth = {
    backgroundColor: '#00B7EF',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)'
  }

  const theme = darkmode ? dark : ligth

  const { state, setState: setGlobalState } = React.useContext(Context)
  const { profile, token, avatar_url } = state

  React.useEffect(() => {
    setToken(token)
  }, [profile, setGlobalState, token])

  return (
    <Container style={theme}>
      <Logo />
      <UserHi>Olá, {profile.name.split(' ')[0]}!</UserHi>
      <Items>
        <Notifications
          observedOpen={openMenu}
          open={openNotification}
          handleOpen={setOpenNotification}
        />
        <Avatar src={avatar_url} style={{ width: '50px', height: '50px' }} />
        <Menu
          observedOpen={openNotification}
          open={openMenu}
          handleOpen={setOpenMenu}
        />
      </Items>
    </Container>
  )
}

export default Header
