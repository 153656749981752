import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px 60px;
`
export const PageTitle = styled.span`
  font-size: 20px;
`

export const Section = styled.section`
  margin-top: 2rem;
`
export const TopRigthSearch = styled.div`
  align-items: center;
  padding: 1.5em 0;
  width: 100%;
  float: right;
  display: flex;
  justify-content: space-between;
  &.margin-bottom-5-1rem {
    margin-bottom: 5.1rem;
  }
`

export const TopRigth = styled.div`
  padding: 1.5em 0;
  float: right;
  max-width: 600px;
  width: 100%;
  &.margin-bottom-5-1rem {
    margin-bottom: 5.1rem;
  }
`

export const FiltersSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
export const Filter = styled.div`
  position: relative;
  padding: 0.8rem;
`
export const List = styled.ul`
  width: 100%;
`
export const Item = styled.li`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 0 10px;
  height: 62px;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:first-child {
    height: 55px;
    background: #f5f7f9;
  }

  @media (max-width: 1650px) {
    gap: 20px;
  }
`
export const ButtonContainer = styled.div`
  float: right;
`

export const BtnGroup = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 2rem;
  float: right;
  button:nth-child(1n + 1) {
    margin-right: 10px;
  }
`
export const Title = styled.span`
  font-size: 20px;
  line-height: 6rem;
`

export const ContainerDrawer = styled.div`
  padding: 0 4rem;
`
export const FilterAndButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const TopSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 0;
`
