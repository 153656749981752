import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalContext from './context/index'
import { ToastContainer } from 'react-toastify'
import dotenv from 'dotenv'

import GlobalStyles from './Global'
import Routes from './routes'
import 'react-toastify/dist/ReactToastify.css'

import './App.css'

dotenv.config()

function App() {
  const darkmode = false

  const ligth = {
    primary_blue: '#00B7EF',
    white: '#fff',
    background: '#F5F7F9',
    backgroundSecondary: '#fff',
    basicText: '#383838',
    borderColor_1: '#bfbfbf',
    grey2: '#ebebeb',
    dropDowns: '#fff',
    green: '#2ECC71',
    red: '#E74C3C',
    hoverButton: '#158BBF',
    activeButton: '#005276',
    box: '#e1e1e2',
    yellow: '#FFDF61'
  }
  const dark = {
    primary_blue: '#40C9F3',
    white: '#fff',
    background: '#121212',
    backgroundSecondary: '#1a1a1a',
    basicText: '#bfbfbf',
    borderColor_1: '#383838',
    grey2: '#1F1F1F',
    dropDowns: '#222222',
    green: '#2ECC71',
    red: '#E74C3C',
    hoverButton: '#40C9F3',
    activeButton: '#3BA3C3',
    box: '#383838',
    yellow: '#FFDF61'
  }

  const colors = darkmode ? dark : ligth

  return (
    <>
      <GlobalContext>
        <GlobalStyles />
        <ThemeProvider theme={colors}>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            className="toast-custom-container"
          />
          <Routes />
        </ThemeProvider>
      </GlobalContext>
    </>
  )
}

export default App
